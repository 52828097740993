import { lazy } from "react";
import { TAB } from "./enums";

// Reducers...
import cpKkmListReducer from "./reducers/cpKkmListReducer";
import cpKkmPreviewReducer from "./reducers/cpKkmPreviewReducer";
import cpKkmAddReducer from "./reducers/cpKkmAddReducer";

// Sagas...
import cpKkmListSaga from "./sagas/cpKkmListSaga";
import cpKkmPreviewSaga from "./sagas/cpKkmPreviewSaga";
import cpKkmAddSaga from "./sagas/cpKkmAddSaga";

export const getReducers = createReducer => ({
	kkmList: cpKkmListReducer(createReducer),
	kkmPreview: cpKkmPreviewReducer(createReducer),
	kkmAdd: cpKkmAddReducer(createReducer)
});
export const getSagas = () => [cpKkmListSaga(), cpKkmPreviewSaga(), cpKkmAddSaga()];
export const getRoutes = () => ({
	cpKkmList: {
		exact: true,
		path: `/control-panel/kkm/:tab(${Object.values(TAB).join("|")})?`,
		component: lazy(() => import("./containers/CpKkmListContainer"))
	},
	cpKkmAdd: {
		exact: true,
		path: `/control-panel/kkm/add`,
		component: lazy(() => import("./containers/CpKkmAddContainer"))
	},
	cpKkmPreview: {
		exact: true,
		path: `/control-panel/kkm/:kkmId`,
		component: lazy(() => import("./containers/CpKkmPreviewContainer"))
	}
});
