import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NoItems, WrapperLayer, TableListComponent } from "components/ui";

import ItemComponent from "../components/registriesPreview/ItemComponent";
import FilterComponent from "../components/registriesPreview/FilterComponent";

import * as action from "../actions/registriesPreviewAction";
import * as selector from "../selectors/registriesPreviewSelector";

@withRouter
@connect(
	(state, ownProps) => ({
		registryId: ownProps.match.params.registryId,
		isLoading: selector.isLoadingSelector(state),
		isNoItems: selector.isNoItemsSelector(state),
		scroll: selector.scrollSelector(state),
		search: selector.searchSelector(state),
		filter: selector.filterSelector(state),
		registries: selector.registriesSelector(state),
		items: selector.itemsSelector(state),
		action: selector.actionSelector(state)
	}),
	{
		initRegistriesPreview: action.initRegistriesPreview.request,
		scrollRegistriesPreview: action.scrollRegistriesPreview.request,
		searchRegistriesPreview: action.searchRegistriesPreview.request,
		filterRegistriesPreview: action.filterRegistriesPreview.request,
		showDetailRegistriesPreview: action.showDetailRegistriesPreview.request
	}
)
export default class RegistriesPreviewContainer extends Component {
	static propTypes = {
		registryId: PropTypes.string,

		isLoading: PropTypes.bool,
		isNoItems: PropTypes.bool,
		scroll: PropTypes.shape({
			isLoading: PropTypes.bool,
			totalElements: PropTypes.number
		}),
		search: PropTypes.shape({
			isLoading: PropTypes.bool,
			isNoItems: PropTypes.bool,
			value: PropTypes.string
		}),
		filter: PropTypes.shape({
			isLoading: PropTypes.bool
		}),
		items: PropTypes.array,
		action: PropTypes.object,

		initRegistriesPreview: PropTypes.func,
		scrollRegistriesPreview: PropTypes.func,
		searchRegistriesPreview: PropTypes.func,
		filterRegistriesPreview: PropTypes.func,
		showDetailRegistriesPreview: PropTypes.func
	};

	constructor(props) {
		super(props);
		props.initRegistriesPreview({ registryId: props.registryId });
	}

	get headerTable() {
		return [
			{ key: "1", name: "Request ID" },
			{ key: "2", name: "Лицевой счет" },
			{ key: "2_1", name: "БИК" },
			{ key: "3", name: "ФИО" },
			{ key: "4", name: "Тип чека", style: { width: "100px" } },
			{ key: "5", name: "Общая сумма" },
			{ key: "6", name: "Статус", style: { width: "100px" } },
			{ key: "7", name: "Номер ФД", style: { width: "80px" } },
			{ key: "8", name: "Чек в ОФД", style: { width: "100px" } },
			{ key: "9", name: "Дата оплаты", style: { width: "140px" } },
			{
				key: "0",
				name: (
					<>
						Транзакция
						<br />
						создана
					</>
				),
				title: "Транзакция создана",
				style: { width: "180px" }
			},
			{
				key: "11",
				name: (
					<>
						Транзакция
						<br />
						выполнена
					</>
				),
				title: "Транзакция выполнена",
				style: { width: "180px" }
			}
		];
	}

	get searchTable() {
		const { search, items } = this.props;

		return {
			value: search.value,
			isNoItems: !items?.length,
			isLoading: search.isLoading,
			onChange: this.handleSearch
		};
	}

	handleSearch = value => {
		const { registryId, searchRegistriesPreview } = this.props;
		searchRegistriesPreview({ registryId, value });
	};

	handleScroll = () => {
		const { registryId, scroll, items, scrollRegistriesPreview } = this.props;

		if (scroll.totalElements > items.length) {
			scrollRegistriesPreview({ registryId });
		}
	};

	handleFilter = props => {
		const { registryId, filterRegistriesPreview } = this.props;
		filterRegistriesPreview({ registryId, ...props });
	};

	handleClickItem = (item, action) => {
		const { showDetailRegistriesPreview } = this.props;
		showDetailRegistriesPreview({
			fiscalDocumentId: item.fiscalDocumentId,
			kkmId: item.kkmId,
			isOpen: !action.isOpen
		});
	};

	render() {
		const { registryId, isLoading, isNoItems, scroll, filter, items, action } = this.props;

		return (
			<WrapperLayer
				title="Реестры"
				breadcrumb={[
					{ to: "/registries", label: "Все реестры" },
					{ to: `/registries/${registryId}`, label: "Реестры" }
				]}
				isLoading={isLoading}
			>
				<NoItems isShow={isNoItems} description="Недостаточно данных для отображения" />
				<FilterComponent isShow={false} {...filter} onFilter={this.handleFilter} />

				<TableListComponent
					isShow={!isNoItems}
					isLoading={scroll.isLoading}
					header={this.headerTable}
					search={this.searchTable}
					onScroll={this.handleScroll}
				>
					{items.map(item => (
						<ItemComponent
							key={item.fiscalDocumentId}
							action={action[item.fiscalDocumentId] || {}}
							item={item}
							onClickItem={this.handleClickItem}
						/>
					))}
				</TableListComponent>
			</WrapperLayer>
		);
	}
}
