import { all, takeEvery, call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { message, notification } from "components/ui";
import * as controlDataContext from "common/dataProvider/control";

import * as action from "../actions/cpKkmAddAction";

export function* initCpKkmAddSaga() {
	try {
		const formState = {
			kkmPassword: 30
		};
		yield put(action.initCpKkmAdd.success({ formState }));
	} catch (e) {
		yield put(action.initCpKkmAdd.error());
	}
}

export function* sendCpKkmAddSaga({ payload }) {
	try {
		yield call(controlDataContext.postControlKkm, { query: payload });
		yield put(action.sendCpKkmAdd.success());

		message.success("Касса успешно добавлена");
		yield put(push("/control-panel/kkm"));
	} catch (e) {
		yield put(action.sendCpKkmAdd.error());

		if (e?.response?.status === 400) {
			notification.error("Ошибка", `Касса с указанным хостом и портом уже существует`);
		} else {
			notification.httpError();
		}
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpKkmAdd.request, initCpKkmAddSaga),
		takeEvery(action.sendCpKkmAdd.request, sendCpKkmAddSaga)
	]);
}
