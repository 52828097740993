import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { WrapperLayer, NoItems, TableListComponent, Loader } from "components/ui";
import { companyIdSelector } from "modules/account/selectors/accountSelector";

import ItemComponent from "../components/registriesList/ItemComponent";
import ImportComponent from "../components/registriesList/ImportComponent";

import * as action from "../actions/registriesListAction";
import * as selector from "../selectors/registriesListSelector";

@connect(
	state => ({
		companyId: companyIdSelector(state),
		isLoading: selector.isLoadingSelector(state),
		isNoItems: selector.isNoItemsSelector(state),
		isLoadingUpload: selector.isLoadingUploadSelector(state),
		search: selector.searchSelector(state),
		scroll: selector.scrollSelector(state),
		items: selector.itemsSelector(state)
	}),
	{
		initRegistriesList: action.initRegistriesList.request,
		scrollRegistriesList: action.scrollRegistriesList.request,
		searchRegistriesList: action.searchRegistriesList.request
	}
)
export default class RegistriesListContainer extends Component {
	static propTypes = {
		companyId: PropTypes.string,

		isLoading: PropTypes.bool,
		isNoItems: PropTypes.bool,
		isLoadingUpload: PropTypes.bool,
		scroll: PropTypes.shape({
			isLoading: PropTypes.bool,
			totalElements: PropTypes.number
		}),
		search: PropTypes.shape({
			isLoading: PropTypes.bool,
			isNoItems: PropTypes.bool,
			value: PropTypes.string
		}),
		items: PropTypes.array,

		initRegistriesList: PropTypes.func,
		scrollRegistriesList: PropTypes.func,
		searchRegistriesList: PropTypes.func
	};

	constructor(props) {
		super(props);
		props.initRegistriesList();
	}

	get headerTable() {
		return [
			{ key: "fileName", name: "Название файла" },
			{ key: "inn", name: "ИНН" },
			{ key: "fileDt", name: "Дата реестра", style: { width: "180px" } },
			{ key: "currentStatus", name: "Статус", style: { width: "100px" } },
			{ key: "kkm", name: "Касса" },
			{
				key: "transactionCount",
				name: (
					<>
						Транзакций
						<br />
						всего
					</>
				),
				title: "Транзакций всего",
				style: { width: "120px" }
			},
			{
				key: "transactionQueued",
				name: (
					<>
						Транзакций
						<br />в очереди
					</>
				),
				title: "Транзакций в очереди",
				style: { width: "120px" }
			},
			{
				key: "uploadedAt",
				name: "Дата загрузки",
				style: { width: "180px" }
			}
		];
	}

	get searchTable() {
		const { search } = this.props;
		return {
			value: search.value,
			isNoItems: search.isNoItems,
			isLoading: search.isLoading,
			placeholder: "Поиск",
			onChange: this.handleSearch
		};
	}

	handleSearch = value => {
		const { searchRegistriesList } = this.props;
		searchRegistriesList({ value });
	};

	handleScroll = () => {
		const { scroll, items, scrollRegistriesList } = this.props;

		if (scroll.totalElements > items.length) {
			scrollRegistriesList();
		}
	};

	render() {
		const { isLoading, isNoItems, isLoadingUpload, scroll, items, companyId, initRegistriesList } = this.props;

		const title = "Все реестры";
		const breadcrumb = [{ to: "/registries", label: title }];

		return (
			<WrapperLayer title={title} breadcrumb={breadcrumb} isLoading={isLoading}>
				{isLoadingUpload && <Loader />}

				<ImportComponent isShow companyId={companyId} onUpdate={initRegistriesList} />
				<NoItems isShow={isNoItems} description="Недостаточно данных для отображения" />

				<TableListComponent
					isShow={!isNoItems}
					isLoading={scroll.isLoading}
					header={this.headerTable}
					search={this.searchTable}
					onScroll={this.handleScroll}
				>
					{items.map(item => (
						<ItemComponent key={item.id} item={item} />
					))}
				</TableListComponent>
			</WrapperLayer>
		);
	}
}
