import { all, takeEvery, put, select, call, debounce } from "redux-saga/effects";
import { searchParams, sortParams } from "common/helpers/query";
import { notification } from "components/ui";
import * as companyDataContext from "common/dataProvider/company";

import { companyIdSelector } from "../../account/selectors/accountSelector";
import * as action from "../actions/kkmGroupTransactionListAction";
import * as selector from "../selectors/kkmGroupTransactionListSelector";

export function* initKkmGroupTransactionListSaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);
		const { kkmGroupId } = payload;

		const { group, transaction } = yield all({
			group: call(companyDataContext.getCompanyKkmGroupId, { companyId, kkmGroupId }),
			transaction: call(companyDataContext.getCompanyGroupTransaction, { companyId, kkmGroupId })
		});

		yield put(
			action.initKkmGroupTransactionList.success({
				group: group,
				items: transaction.content,
				totalElements: transaction.totalElements
			})
		);
	} catch (e) {
		yield put(action.initKkmGroupTransactionList.error());
		notification.httpError();
	}
}

export function* scrollKkmGroupTransactionListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const kkmGroupId = yield select(selector.kkmGroupIdSelector);
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			page: page + 1,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const transaction = yield call(companyDataContext.getCompanyGroupTransaction, {
			companyId,
			kkmGroupId,
			query
		});

		yield put(
			action.scrollKkmGroupTransactionList.success({
				items: transaction.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollKkmGroupTransactionList.error());
		notification.httpError();
	}
}

export function* searchKkmGroupTransactionListSaga({ payload }) {
	try {
		const { value } = payload;

		const companyId = yield select(companyIdSelector);
		const kkmGroupId = yield select(selector.kkmGroupIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const transaction = yield call(companyDataContext.getCompanyGroupTransaction, {
			companyId,
			kkmGroupId,
			query
		});

		yield put(
			action.searchKkmGroupTransactionList.success({
				items: transaction.content,
				totalElements: transaction.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchKkmGroupTransactionList.error());
		notification.httpError();
	}
}

export function* filterKkmGroupTransactionListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const kkmGroupId = yield select(selector.kkmGroupIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const transaction = yield call(companyDataContext.getCompanyGroupTransaction, {
			companyId,
			kkmGroupId,
			query
		});

		yield put(
			action.filterKkmGroupTransactionList.success({
				items: transaction.content,
				totalElements: transaction.totalElements
			})
		);
	} catch (e) {
		yield put(action.filterKkmGroupTransactionList.error());
		notification.httpError();
	}
}

export function* sortKkmGroupTransactionListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const kkmGroupId = yield select(selector.kkmGroupIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const transaction = yield call(companyDataContext.getCompanyGroupTransaction, {
			companyId,
			kkmGroupId,
			query
		});

		yield put(
			action.sortKkmGroupTransactionList.success({
				items: transaction.content,
				totalElements: transaction.totalElements
			})
		);
	} catch (e) {
		yield put(action.sortKkmGroupTransactionList.error());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initKkmGroupTransactionList.request, initKkmGroupTransactionListSaga),
		takeEvery(action.scrollKkmGroupTransactionList.request, scrollKkmGroupTransactionListSaga),
		debounce(400, action.searchKkmGroupTransactionList.request, searchKkmGroupTransactionListSaga),
		takeEvery(action.filterKkmGroupTransactionList.request, filterKkmGroupTransactionListSaga),
		takeEvery(action.sortKkmGroupTransactionList.request, sortKkmGroupTransactionListSaga)
	]);
}
