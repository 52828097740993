import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { AmountFormat, Button, Loading, message, TransactionStatus } from "components/ui";
import { STATUS, STATUS_HISTORY_LABEL, TYPE_LABEL } from "../../../../common/enums/kkm";
import { getString, getDateFormat, copyToBuffer } from "../../../../common/helpers/string";

export default class ItemComponent extends Component {
	static propTypes = {
		action: PropTypes.object,
		item: PropTypes.object,
		onClickItem: PropTypes.func
	};

	getType = type => {
		const _type = ("" + type).toUpperCase();

		switch (_type) {
			case "INCOME":
				return <span className="badge badge-success">{TYPE_LABEL.SALE}</span>;
			case "INCOME_RETURN":
				return <span className="badge badge-danger">{TYPE_LABEL.SALE_RETURN}</span>;
			case "OUTCOME":
				return <span className="badge badge-primary">{TYPE_LABEL.BUY}</span>;
			case "OUTCOME_RETURN":
				return <span className="badge badge-warning">{TYPE_LABEL.BUY_RETURN}</span>;
			default:
				return getString("");
		}
	};

	getHistory = status => {
		switch (status) {
			case STATUS.QUEUED:
				return STATUS_HISTORY_LABEL.QUEUED;
			case STATUS.PENDING:
				return STATUS_HISTORY_LABEL.PENDING;
			case STATUS.COMPLETED:
				return STATUS_HISTORY_LABEL.COMPLETED;
			case STATUS.FAILED:
				return STATUS_HISTORY_LABEL.FAILED;
			default:
				return getString();
		}
	};

	onClick = () => {
		const { item, action, onClickItem } = this.props;
		onClickItem && onClickItem(item, action);
	};

	onCheque = () => {
		const { item } = this.props;
		window.open(item.ofdLink);
	};

	onCopy = (e, data) => {
		e?.stopPropagation();
		e?.preventDefault();

		if (copyToBuffer(data, e.target)) {
			message.success("Скопированно в буфер обмена");
		}
	};

	renderPositions = data => {
		const { requestId, positions } = data;

		return (
			<div className="col-8">
				<div className="p-b-15">
					<span className="f-14 p-r-10">
						TransactionId <i className="p-l-5 feather icon-copy" />
					</span>
					<a href="" className="f-14 cursor-pointer" onClick={e => this.onCopy(e, requestId)}>
						{getString(requestId)}
					</a>
				</div>

				<table className="table table-hover">
					<thead>
						<tr>
							<th>Наименование позиции</th>
							<th>Кол-во</th>
							<th>Цена</th>
						</tr>
					</thead>
					<tbody>
						{(positions || []).map(position => (
							<tr key={position.name + position.qty + position.price}>
								<td>{getString(position.name)}</td>
								<td>
									<AmountFormat value={position.qty} precision={3} currency={null} />
								</td>
								<td>
									<AmountFormat value={position.price} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	renderStatuses = data => {
		const { statuses } = data;

		return (
			<div className="col-4">
				<div className="p-b-15">
					<span className="f-14 p-r-10">Статусы:</span>
				</div>
				<ul className="task-list" style={{ paddingTop: 0 }}>
					{(statuses || []).map(status => (
						<li key={status.status + status.statusDateTime}>
							<i className="task-icon bg-c-green" />
							<span>{this.getHistory(status.status)}</span>
							<p className="text-muted">{getDateFormat(status.statusDateTime, true)}</p>
						</li>
					))}
				</ul>
			</div>
		);
	};

	renderDetailRegistries = () => {
		const { action } = this.props;

		return (
			<tr className="table_item_open">
				<td className="full" colSpan="12">
					{action.isLoading && (
						<div className="row">
							<Loading />
						</div>
					)}

					{!action.isLoading && !action.data && <div className="text-center">{getString()}</div>}

					{!action.isLoading && action.data && (
						<div className="row">
							{this.renderPositions(action.data)}
							{this.renderStatuses(action.data)}
						</div>
					)}
				</td>
			</tr>
		);
	};

	render() {
		const { action, item } = this.props;

		return (
			<Fragment>
				<tr className={cn("cursor-pointer", { _open: action.isOpen })} onClick={this.onClick}>
					<td>{item.requestId}</td>
					<td>{item.personaAccount}</td>
					<td>{getString(item.bic)}</td>
					<td>{getString(item.personaName)}</td>
					<td>{this.getType(item.method)}</td>
					<td className="text-right">
						<AmountFormat value={item.sum} />
					</td>
					<td>
						<TransactionStatus value={item.status} />
					</td>
					<td>{getString(item.fdNumber)}</td>
					<td>
						<Button
							className="p-r-10 p-l-10"
							style={{ margin: "-19px 0" }}
							onClick={this.onCheque}
							disabled={!item.ofdLink}
						>
							<i className="feather icon-external-link" />
						</Button>
					</td>
					<td>{getDateFormat(item.checkOutDate)}</td>
					<td>{getDateFormat(item.documentCreatedAt, true)}</td>
					<td>{getDateFormat(item.documentCompletedAt, true)}</td>
				</tr>

				{action.isOpen && this.renderDetailRegistries()}
			</Fragment>
		);
	}
}
