import { all, takeEvery, call, put } from "redux-saga/effects";
import { message, notification } from "components/ui";
import * as userDataContext from "common/dataProvider/user";
import * as action from "../actions/settingsAction";

export function* sendProfileSettingsSaga({ payload }) {
	try {
		const query = {
			firstName: payload.firstName,
			lastName: payload.lastName,
			middleName: payload.middleName,
			email: payload.email,
			phone: payload.phone
		};
		yield call(userDataContext.putUserProfile, { query });

		yield put(action.sendProfileSettings.success());
		message.success("Профиль успешно изменен");
	} catch (e) {
		yield put(action.sendProfileSettings.error());
		notification.httpError();
	}
}

export function* sendPasswordSettingsSaga({ payload }) {
	try {
		if (payload.passwordRepeat !== payload.passwordNew) {
			message.error("Павтор пароля не совпадает с новым паролем");
		}

		const query = {
			currentPassword: payload.password,
			newPassword: payload.passwordNew
		};
		yield call(userDataContext.postUserPassword, { query });

		yield put(action.sendPasswordSettings.success());
		message.success("Пароль успешно изменен");
	} catch (e) {
		yield put(action.sendPasswordSettings.error());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.sendProfileSettings.request, sendProfileSettingsSaga),
		takeEvery(action.sendPasswordSettings.request, sendPasswordSettingsSaga)
	]);
}
