import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Loader, WrapperLayer } from "components/ui";

import KkmGroupAddForm from "../components/KkmGroupAddForm";
import * as action from "../actions/kkmGroupAddAction";
import * as selector from "../selectors/kkmGroupAddSelector";

@connect(
	state => ({
		isLoading: selector.isLoadingSelector(state),
		isLoadingSend: selector.isLoadingSendSelector(state),
		formState: selector.formStateSelector(state)
	}),
	{
		initKkmGroupAdd: action.initKkmGroupAdd.request,
		sendKkmGroupAdd: action.sendKkmGroupAdd.request
	}
)
export default class KkmGroupAddContainer extends Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		isLoadingSend: PropTypes.bool,
		formState: PropTypes.object,
		initKkmGroupAdd: PropTypes.func,
		sendKkmGroupAdd: PropTypes.func
	};

	constructor(props) {
		super(props);
		props.initKkmGroupAdd();
	}

	render() {
		const { isLoading, isLoadingSend, formState, sendKkmGroupAdd } = this.props;

		return (
			<WrapperLayer
				title="Создание группы касс"
				breadcrumb={[
					{ to: "/kkm-group", label: "Группы касс" },
					{ to: "/kkm-group/add", label: "Создание группы касс" }
				]}
				isLoading={isLoading}
			>
				{isLoadingSend && <Loader />}

				<div className="row">
					<div className="col-12">
						<KkmGroupAddForm
							initialValues={formState}
							isLoading={isLoadingSend}
							onSubmitForm={sendKkmGroupAdd}
						/>
					</div>
				</div>
			</WrapperLayer>
		);
	}
}
