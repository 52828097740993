import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/kkmGroupListAction";

const initialState = {
	isLoading: true,
	isNoItems: false,
	scroll: {
		isLoading: false,

		size: 20,
		page: 0,
		totalElements: 0
	},
	items: []
};
const actionHandlers = {
	[action.initKkmGroupList.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.isNoItems = false;
		draft.items = [];

		draft.scroll.page = 0;
	}),
	[action.initKkmGroupList.success]: createMutateReducer((draft, { content, totalElements }) => {
		draft.isLoading = false;
		draft.isNoItems = content.length === 0;
		draft.items = content;

		draft.scroll.totalElements = totalElements;
	}),
	[action.initKkmGroupList.error]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),

	// Infinite scroll
	[action.scrollKkmGroupList.request]: createMutateReducer(draft => {
		draft.scroll.isLoading = true;
	}),
	[action.scrollKkmGroupList.success]: createMutateReducer((draft, { items, page }, state) => {
		draft.scroll.isLoading = false;
		draft.scroll.page = page;
		draft.items = [...state.items, ...items];
	}),
	[action.scrollKkmGroupList.error]: createMutateReducer(draft => {
		draft.scroll.isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
