import { lazy } from "react";

// Reducers...
import cpRegistriesListReducer from "./reducers/cpRegistriesListReducer";
import cpRegistriesPreviewReducer from "./reducers/cpRegistriesPreviewReducer";

// Sagas...
import cpRegistriesListSaga from "./sagas/cpRegistriesListSaga";
import cpRegistriesPreviewSaga from "./sagas/cpRegistriesPreviewSaga";

export const getReducers = createReducer => ({
	registriesList: cpRegistriesListReducer(createReducer),
	registriesPreview: cpRegistriesPreviewReducer(createReducer)
});
export const getSagas = () => [cpRegistriesListSaga(), cpRegistriesPreviewSaga()];
export const getRoutes = () => ({
	cpRegistriesList: {
		exact: true,
		path: "/control-panel/registries",
		component: lazy(() => import("./containers/CpRegistriesListContainer"))
	},
	cpRegistriesPreview: {
		exact: true,
		path: "/control-panel/registries/:registryId",
		component: lazy(() => import("./containers/CpRegistriesPreviewContainer"))
	}
});
