import { createSelector } from "reselect";

const cpKkmPreviewSelector = state => state.cp.kkmPreview;

export const isLoadingSelector = createSelector(cpKkmPreviewSelector, state => state.isLoading);
export const isErrorSelector = createSelector(cpKkmPreviewSelector, state => state.isError);
export const isLoadingActionSelector = createSelector(cpKkmPreviewSelector, state => state.isLoadingAction);

export const kkmInfoSelector = createSelector(cpKkmPreviewSelector, state => state.kkmInfo);

// Transaction
export const isNoItemsSelector = createSelector(cpKkmPreviewSelector, state => state.isNoItems);
export const scrollSelector = createSelector(cpKkmPreviewSelector, state => state.scroll);
export const searchSelector = createSelector(cpKkmPreviewSelector, state => state.search);
export const filterSelector = createSelector(cpKkmPreviewSelector, state => state.filter);
export const sortSelector = createSelector(cpKkmPreviewSelector, state => state.sort);
export const itemsSelector = createSelector(cpKkmPreviewSelector, state => state.items);
export const itemsActionsSelector = createSelector(cpKkmPreviewSelector, state => state.itemsActions);
