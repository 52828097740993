import { all, takeEvery, call, put, select, debounce } from "redux-saga/effects";
import { escapeRegExp } from "common/helpers/string";
import * as controlDataContext from "common/dataProvider/control";

import * as action from "../actions/cpKkmListAction";
import * as selector from "../selectors/cpKkmListSelector";

export function* initCpKkmListSaga({ payload }) {
	try {
		const { size } = yield select(selector.scrollSelector);
		const { tab } = payload;

		const q = [];
		tab && q.push(`state==${tab.toUpperCase()}`);
		const query = { size, q: q.join(";") };

		const response = yield call(controlDataContext.getControlKkm, { query });

		yield put(
			action.initCpKkmList.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.initCpKkmList.error());
	}
}

export function* scrollCpKkmListSaga({ payload }) {
	try {
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { tab } = payload;

		const q = [];
		value && q.push(`quickSearch=='${escapeRegExp(value)}'`);
		tab && q.push(`state==${tab.toUpperCase()}`);
		const query = { size, q: q.join(";"), page: page + 1 };

		const response = yield call(controlDataContext.getControlKkm, { query });

		yield put(
			action.scrollCpKkmList.success({
				items: response.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollCpKkmList.error());
	}
}

export function* searchCpKkmListSaga({ payload }) {
	try {
		const { size } = yield select(selector.scrollSelector);
		const { tab } = yield select(selector.searchSelector);
		const { value } = payload;

		const q = [];
		value && q.push(`quickSearch=='${escapeRegExp(value)}'`);
		tab && q.push(`state==${tab.toUpperCase()}`);
		const query = { size, q: q.join(";") };

		const response = yield call(controlDataContext.getControlKkm, { query });

		yield put(
			action.searchCpKkmList.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchCpKkmList.error());
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpKkmList.request, initCpKkmListSaga),
		takeEvery(action.scrollCpKkmList.request, scrollCpKkmListSaga),
		debounce(400, action.searchCpKkmList.request, searchCpKkmListSaga)
	]);
}
