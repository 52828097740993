import moment from "moment";
import { METHOD } from "common/enums/kkm";
import { SEND_CHECK } from "../../../modules/kkm/enums";

const priceInPenny = price => {
	return Math.round(parseFloat("" + price || 0) * 100);
};

const getParamsForMethod = (method, data) => {
	switch (method) {
		case METHOD.STATUS:
			return undefined;
		case METHOD.CORRECTION:
			return {
				TaxType: data?.params?.TaxType,

				CalculationSign: data?.params?.CalculationSign,
				CorrectionDate:
					data?.params?.CorrectionDate && moment(data?.params?.CorrectionDate || "").format("DD.MM.YYYY"),
				CorrectionDocNum: data?.params?.CorrectionDocNum,
				CorrectionType: data?.params?.CorrectionType,
				CorrectionReason: data?.params?.CorrectionReason,

				Sum1: priceInPenny(data?.params?.Sum1),
				Sum2: priceInPenny(data?.params?.Sum2),
				Sum3: priceInPenny(data?.params?.Sum3),
				Sum4: priceInPenny(data?.params?.Sum4),
				Sum5: priceInPenny(data?.params?.Sum5),
				Sum6: priceInPenny(data?.params?.Sum6),
				Sum7: priceInPenny(data?.params?.Sum7),
				Sum8: priceInPenny(data?.params?.Sum8),
				Sum9: priceInPenny(data?.params?.Sum9),
				Sum10: priceInPenny(data?.params?.Sum10),
				Sum11: priceInPenny(data?.params?.Sum11),
				Sum12: priceInPenny(data?.params?.Sum12),

				SumTypePayment: data?.params?.SumTypePayment && parseInt(data.params.SumTypePayment),
				CallbackUrl: data?.params?.CallbackUrl
			};
		default:
			return {
				Cashier: {
					Name: data?.params?.Cashier?.Name,
					Inn: data?.params?.Cashier?.Inn || null
				},
				Persona: {
					Account: data?.params?.Persona?.Account,
					Name: data?.params?.Persona?.Name,
					Email:
						data?.params?.Persona?.Email && data?.params?.SendCheck === SEND_CHECK.EMAIL
							? data.params.Persona.Email
							: undefined,
					Phone:
						data?.params?.Persona?.Phone && data?.params?.SendCheck === SEND_CHECK.PHONE
							? "+7" + ("" + data.params.Persona.Phone).replace(/[^0-9]/gim, "")
							: undefined
				},
				DatePayment: moment(data?.params?.DatePayment || "").format("DD.MM.YYYY"),
				SendCheck: data?.params?.SendCheck,

				DocItems: data?.params?.DocItems?.map(item => ({
					Description: item?.Description,
					Price: priceInPenny(item.Price),
					Qty: parseFloat(item.Qty),
					PaymentItem: parseInt(item.PaymentItem),
					PaymentType: parseInt(item.PaymentType),
					Tax: parseInt(item.Tax)
				})),

				SumTypePayment: data?.params?.SumTypePayment && parseInt(data.params.SumTypePayment),
				CallbackUrl: data?.params?.CallbackUrl
			};
	}
};

export const transactionAPI = {
	toServer: data => ({
		method: data.method,
		requestId: data.transactionId,
		params: getParamsForMethod(data.method, data)
	})
};
