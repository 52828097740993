import { createAction, createRequestActions } from "common/helpers/action";

export const initCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.INIT");
export const updateCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.UPDATE");
export const removeCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.REMOVE");

// Setting kkm
export const setKkmModeCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.SET_KKM_MODE");
export const sendDeskDataCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.DESC_DATA");
export const sendApiKeyCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.API_KEY");
export const sendHandlerCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.HANDLER");

// Transaction
export const searchTransactCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.SEARCH_TRANSACT");
export const filterTransactCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.FILTER_TRANSACT");
export const sortTransactCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.SORT_TRANSACT");
export const scrollTransactCpKkmPreview = createRequestActions("CP.KKM.PREVIEW.SCROLL_TRANSACT");
export const showDetailTransactCpKkmPreview = createAction("CP.KKM.PREVIEW.SHOW_TRANSACT");
