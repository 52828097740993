import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "antd/lib/tabs/style";
import Tabs from "antd/lib/tabs";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Loader, WrapperLayer, NoItems, TableListComponent, Popup, Button } from "components/ui";

import SettingsForm from "../components/kkmGroupSettings/SettingsForm";
import ApiKeyForm from "../components/kkmGroupSettings/ApiKeyForm";
import AddKkmForm from "../components/kkmGroupSettings/AddKkmForm";

import * as action from "../actions/kkmGroupSettingsAction";
import * as selector from "../selectors/kkmGroupSettingsSelector";

@withRouter
@connect(
	(state, ownProps) => ({
		kkmGroupId: ownProps.match.params.kkmGroupId,
		isLoading: selector.isLoadingSelector(state),
		isLoadingSend: selector.isLoadingSendSelector(state),
		formState: selector.formStateSendSelector(state),
		groupState: selector.groupStateSendSelector(state),
		searchKkm: selector.searchKkmSelector(state)
	}),
	{
		initKkmGroupSettings: action.initKkmGroupSettings.request,
		sendKkmGroupSettings: action.sendKkmGroupSettings.request,
		removeKkmGroupSettings: action.removeKkmGroupSettings.request,
		generateApiKey: action.generateApiKey.request,

		addKkmByIdGroupSettings: action.addKkmByIdGroupSettings.request,
		removeKkmByIdGroupSettings: action.removeKkmByIdGroupSettings.request,
		searchKkmByIdGroupSettings: action.searchKkmByIdGroupSettings.request
	}
)
export default class KkmGroupSettingsContainer extends Component {
	static propTypes = {
		kkmGroupId: PropTypes.string,
		isLoading: PropTypes.bool,
		isLoadingSend: PropTypes.bool,
		formState: PropTypes.object,
		groupState: PropTypes.object,
		searchKkm: PropTypes.object,
		initKkmGroupSettings: PropTypes.func,
		sendKkmGroupSettings: PropTypes.func,
		generateApiKey: PropTypes.func,
		removeKkmGroupSettings: PropTypes.func,
		addKkmByIdGroupSettings: PropTypes.func,
		removeKkmByIdGroupSettings: PropTypes.func,
		searchKkmByIdGroupSettings: PropTypes.func
	};

	constructor(props) {
		super(props);
		props.initKkmGroupSettings({ kkmGroupId: props.kkmGroupId });
	}

	get headerTable() {
		return [{ key: "name", name: "Название кассы" }, { key: "action" }];
	}

	get searchKkm() {
		const { searchKkm } = this.props;

		return {
			isLoading: searchKkm.isLoading,
			options: (searchKkm.items || []).map(item => ({ value: item.id, label: item.name })),
			onSearch: this.handleSearchKkm
		};
	}

	handleSearchKkm = value => {
		const { searchKkmByIdGroupSettings } = this.props;
		searchKkmByIdGroupSettings({ value });
	};

	handleSubmitForm = query => {
		const { kkmGroupId, sendKkmGroupSettings } = this.props;
		sendKkmGroupSettings({ kkmGroupId, query });
	};

	handleSubmitApiKey = query => {
		const { kkmGroupId, generateApiKey } = this.props;

		this.popupApiKey.open().then(() => {
			generateApiKey({ kkmGroupId, query });
		});
	};

	handleRemoveGroup = () => {
		const { kkmGroupId, removeKkmGroupSettings } = this.props;

		this.popupRemoveGroup.open().then(() => {
			removeKkmGroupSettings({ kkmGroupId });
		});
	};

	handleRemoveKkm = kkmId => () => {
		const { kkmGroupId, removeKkmByIdGroupSettings } = this.props;
		removeKkmByIdGroupSettings({ kkmId, kkmGroupId });
	};

	handleAddKkm = ({ kkmId }) => {
		const { kkmGroupId, addKkmByIdGroupSettings } = this.props;
		addKkmByIdGroupSettings({ kkmGroupId, kkmId });
	};

	render() {
		const { kkmGroupId, isLoading, isLoadingSend, formState, groupState } = this.props;
		const title = `Настройки группы${groupState?.name ? ": " + groupState.name : ""}`;

		return (
			<WrapperLayer
				title={title}
				breadcrumb={[
					{ to: "/kkm-group", label: "Группы касс" },
					{ to: `/kkm-group/${kkmGroupId}/settings`, label: title }
				]}
				isLoading={isLoading}
				titleRight={
					<Button htmlType="button" type="danger" onClick={this.handleRemoveGroup}>
						Удалить
					</Button>
				}
			>
				{isLoadingSend && <Loader />}

				<Tabs defaultActiveKey="1">
					<Tabs.TabPane tab="Настройки" key="1">
						<SettingsForm
							initialValues={formState}
							isLoading={isLoadingSend}
							onSubmitForm={this.handleSubmitForm}
						/>
						<ApiKeyForm
							initialValues={formState}
							isLoading={isLoadingSend}
							onSubmitForm={this.handleSubmitApiKey}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Кассы" key="2">
						<div className="card">
							<div className="card-header">
								<h5>Добавить кассу</h5>
							</div>
							<div className="card-body">
								<AddKkmForm
									isDisabled={false}
									searchKkm={this.searchKkm}
									onSubmit={this.handleAddKkm}
								/>
							</div>
						</div>

						{groupState?.kkmsInGroup?.length > 0 ? (
							<TableListComponent isShow isLoading={false} header={this.headerTable}>
								{groupState.kkmsInGroup.map(item => (
									<tr key={item.id}>
										<td>
											<Link to={`/kkm/${item.id}`}>{item?.name}</Link>
										</td>
										<td className="text-right" style={{ padding: "10px 20px 10px 10px" }}>
											<Button
												type="primary"
												className="p-r-10 p-l-10"
												onClick={this.handleRemoveKkm(item.id)}
											>
												<i className="feather icon-trash-2" />
											</Button>
										</td>
									</tr>
								))}
							</TableListComponent>
						) : (
							<NoItems isShow description="Нет связанных касс" />
						)}
					</Tabs.TabPane>
				</Tabs>

				<Popup.Confirm
					ref={e => (this.popupRemoveGroup = e)}
					title="Удалить группу?"
					message="Вы уверены что хотите удалить группу? Её невозможно будет восстановить."
				/>
				<Popup.Confirm
					ref={e => (this.popupApiKey = e)}
					title="Вы уверены что хотите сгенерировать новый API-ключ?"
				/>
			</WrapperLayer>
		);
	}
}
