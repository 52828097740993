import React, { Component } from "react";
import PropTypes from "prop-types";
import "antd/lib/menu/style";
import Menu from "antd/lib/menu";
import "antd/lib/dropdown/style";
import Dropdown from "antd/lib/dropdown";
import { AmountFormat, Button } from "components/ui";

export default class KkmGroupListComponent extends Component {
	static propTypes = {
		item: PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			transactionsTotal: PropTypes.number,
			transactionsToday: PropTypes.number
		}),
		onClick: PropTypes.func,
		onClickMenuTransactionList: PropTypes.func,
		onClickMenuSettings: PropTypes.func
	};

	handleStopEvent = e => {
		e?.stopPropagation();
		e?.preventDefault();
	};

	handleClick = item => ({ domEvent }) => {
		this.handleStopEvent(domEvent);

		const { onClick } = this.props;
		onClick(item);
	};

	handleClickMenuTransactionList = item => ({ domEvent }) => {
		this.handleStopEvent(domEvent);

		const { onClickMenuTransactionList } = this.props;
		onClickMenuTransactionList(item);
	};

	handleClickMenuSettings = item => ({ domEvent }) => {
		this.handleStopEvent(domEvent);

		const { onClickMenuSettings } = this.props;
		onClickMenuSettings(item);
	};

	renderDropdownMenu = item => {
		return (
			<Menu>
				<Menu.Item onClick={this.handleClickMenuTransactionList(item)}>
					<i className="feather icon-list p-r-10" />
					<span>Список транзакций</span>
				</Menu.Item>
				<Menu.Item onClick={this.handleClickMenuSettings(item)}>
					<i className="feather icon-settings p-r-10" />
					<span>Настройки</span>
				</Menu.Item>
			</Menu>
		);
	};

	render() {
		const { item } = this.props;

		return (
			<tr key={item.id} className="cursor-pointer" onClick={this.handleClick(item)}>
				<td className="font-weight-bold">{item.name}</td>
				<td className="text-center">
					<AmountFormat value={item.transactionsTotal} precision={0} currency={null} />
				</td>
				<td className="text-center">
					<AmountFormat value={item.transactionsToday} precision={0} currency={null} />
				</td>
				<td className="text-right" style={{ padding: "10px 20px 10px 10px" }}>
					<Dropdown placement="bottomRight" overlay={this.renderDropdownMenu(item)}>
						<Button size="small" className="p-r-10 p-l-10" onClick={this.handleStopEvent}>
							<i className="feather icon-more-horizontal" />
						</Button>
					</Dropdown>
				</td>
			</tr>
		);
	}
}
