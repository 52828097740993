import * as App from "./app";
import * as Account from "./account";
import * as Dashboard from "./dashboard";
import * as Kkm from "./kkm";
import * as KkmGroup from "./kkmGroup";
import * as Users from "./users";
import * as Company from "./company";
import * as Registries from "./registries";

import * as ControlPanel from "./controlPanel";

// List modules for chunks "main.html"
export default [App, Account, Dashboard, Kkm, KkmGroup, Users, Company, Registries, ControlPanel];
