export const TAB = {
	OPERATING: "operating",
	HOLD: "hold",
	MAINTENANCE: "maintenance",
	OUT_OF_BUSINESS: "out_of_business"
};

export const TAB_VALUE = {
	[TAB.OPERATING]: "Работает",
	[TAB.HOLD]: "Заморожена",
	[TAB.MAINTENANCE]: "В сервисном режиме",
	[TAB.OUT_OF_BUSINESS]: "Отключена"
};

// Список одинаковых табов, отличаются только фильтром
export const TAB_KKM_LIST = [TAB.OPERATING, TAB.HOLD, TAB.MAINTENANCE, TAB.OUT_OF_BUSINESS];

export const TAB_PREVIEW = {
	INFO: "info",
	TRANSACTION: "transaction"
};

export const TAB_PREVIEW_VALUE = {
	[TAB_PREVIEW.INFO]: "Информация",
	[TAB_PREVIEW.TRANSACTION]: "Транзакции"
};

export const TAB_PREVIEW_DEFAULT = TAB_PREVIEW.INFO;
