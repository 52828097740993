import { all, takeEvery, call, put, select } from "redux-saga/effects";
import * as companyDataContext from "common/dataProvider/company";
import { companyIdSelector } from "modules/account/selectors/accountSelector";

import { setAppReady } from "modules/app/actions/appAction";
import * as action from "../actions/companyListAction";

export function* loadingCompanyListSaga({ payload }) {
	const { childCompany } = payload;
	const { map, items } = (childCompany?.content || []).reduce(
		(accum, item) => {
			accum.map[item.id] = item;
			accum.items.push(item);

			return accum;
		},
		{ map: {}, items: [] }
	);

	yield put(action.setChildCompany({ map, items }));
}

export function* initCompanyListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const query = { size: 100 };

		const response = yield call(companyDataContext.getCompanyChildCompany, { companyId, query });

		yield put(action.initCompanyList.success(response));
	} catch (e) {
		yield put(action.initCompanyList.error());
	}
}

export default function*() {
	yield all([
		takeEvery(setAppReady, loadingCompanyListSaga)
		// takeEvery(action.initCompanyList.request, initCompanyListSaga)
	]);
}
