import moment from "moment";
import { escapeRegExp } from "./string";

export const searchParams = ({ quickSearch, activeKkm, by, start, end }) => {
	const filter = [];

	if (quickSearch) {
		filter.push(`quickSearch=='${escapeRegExp(quickSearch)}'`);
	}

	if (by && (start || end)) {
		start && filter.push(`${by}>=${moment(start).format()}`);
		end && filter.push(`${by}<=${moment(end).format()}`);
	}

	if (activeKkm) {
		filter.push(`state!=OUT_OF_BUSINESS`);
	}

	return filter.join(";") || undefined;
};

export const sortParams = ({ field, order }) => {
	if (!field || !["asc", "desc"].includes(order)) {
		return undefined;
	}

	return `${field},${order}`;
};
