import { all, takeEvery, call, put, select, debounce } from "redux-saga/effects";
import { notification } from "components/ui";
import * as controlDataProvider from "common/dataProvider/control";

import * as action from "../actions/cpRegistriesPreviewAction";
import * as selector from "../selectors/cpRegistriesPreviewSelector";
import { escapeRegExp } from "../../../../common/helpers/string";

export function* initRegistriesKkmPreviewSaga({ payload }) {
	try {
		const { registryId } = payload;
		const { size } = yield select(selector.scrollSelector);
		const query = { size };

		const response = yield call(controlDataProvider.getControlRegistryByIdLines, { registryId, query });

		yield put(
			action.initRegistriesKkmPreview.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.initRegistriesKkmPreview.error());
		notification.httpError();
	}
}

export function* scrollRegistriesKkmPreviewSaga({ payload }) {
	try {
		const { registryId } = payload;
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const query = {
			page,
			size,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined,
			by: by ? by : undefined,
			start: start ? start : undefined,
			end: end ? end : undefined
		};

		const response = yield call(controlDataProvider.getControlRegistryByIdLines, { registryId, query });

		yield put(
			action.scrollRegistriesKkmPreview.success({
				items: response.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollRegistriesKkmPreview.error());
		notification.httpError();
	}
}

export function* searchRegistriesKkmPreviewSaga({ payload }) {
	try {
		const { registryId, value } = payload;
		const { size } = yield select(selector.scrollSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const query = {
			size,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined,
			by: by ? by : undefined,
			start: start ? start : undefined,
			end: end ? end : undefined
		};

		const response = yield call(controlDataProvider.getControlRegistryByIdLines, { registryId, query });

		yield put(
			action.searchRegistriesKkmPreview.success({
				value,
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchRegistriesKkmPreview.error());
		notification.httpError();
	}
}

export function* filterRegistriesKkmPreviewSaga({ payload }) {
	try {
		const { registryId } = payload;
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);

		const query = {
			size,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined,
			by: by ? by : undefined,
			start: start ? start : undefined,
			end: end ? end : undefined
		};

		const response = yield call(controlDataProvider.getControlRegistryByIdLines, { registryId, query });

		yield put(
			action.filterRegistriesKkmPreview.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.filterRegistriesKkmPreview.error());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initRegistriesKkmPreview.request, initRegistriesKkmPreviewSaga),
		takeEvery(action.scrollRegistriesKkmPreview.request, scrollRegistriesKkmPreviewSaga),

		debounce(400, action.searchRegistriesKkmPreview.request, searchRegistriesKkmPreviewSaga),
		debounce(400, action.filterRegistriesKkmPreview.request, filterRegistriesKkmPreviewSaga)
	]);
}
