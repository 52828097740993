import { all, takeEvery, call, put, select, debounce } from "redux-saga/effects";
import { notification } from "components/ui";
import { escapeRegExp } from "common/helpers/string";
import { searchParams } from "common/helpers/query";
import * as companyDataContext from "common/dataProvider/company";
import { companyIdSelector } from "modules/account/selectors/accountSelector";

import * as action from "../actions/kkmListAction";
import * as selector from "../selectors/kkmListSelector";

export function* initKkmListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { activeKkm } = yield select(selector.filterSelector);
		const query = {
			size,
			q: searchParams({ quickSearch: value, activeKkm })
		};

		const response = yield call(companyDataContext.getCompanyKkm, { companyId, query });

		yield put(
			action.initKkmList.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.initKkmList.error());
		notification.httpError();
	}
}

export function* scrollKkmListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const query = {
			size,
			page: page + 1,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined
		};

		const response = yield call(companyDataContext.getCompanyKkm, { companyId, query });

		yield put(
			action.scrollKkmList.success({
				items: response.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollKkmList.error());
		notification.httpError();
	}
}

export function* filterKkmListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { activeKkm } = yield select(selector.filterSelector);
		const query = {
			size,
			q: searchParams({ quickSearch: value, activeKkm })
		};

		const response = yield call(companyDataContext.getCompanyKkm, { companyId, query });

		yield put(
			action.filterKkmList.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.filterKkmList.error());
		notification.httpError();
	}
}

export function* searchKkmListSaga({ payload }) {
	try {
		const { value } = payload;
		const companyId = yield select(companyIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { activeKkm } = yield select(selector.filterSelector);
		const query = {
			size,
			q: searchParams({ quickSearch: value, activeKkm })
		};

		const response = yield call(companyDataContext.getCompanyKkm, { companyId, query });

		yield put(
			action.searchKkmList.success({
				value,
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchKkmList.error());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initKkmList.request, initKkmListSaga),
		takeEvery(action.scrollKkmList.request, scrollKkmListSaga),
		takeEvery(action.filterKkmList.request, filterKkmListSaga),
		debounce(400, action.searchKkmList.request, searchKkmListSaga)
	]);
}
