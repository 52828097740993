import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/registriesListAction";

const initialState = {
	isLoading: true,
	isNoItems: false,
	scroll: {
		isLoading: false,

		size: 20,
		page: 0,
		totalElements: 0
	},
	search: {
		value: "",
		isLoading: false,
		isNoItems: false
	},
	items: []
};
const actionHandlers = {
	// Initialize
	[action.initRegistriesList.request]: createMutateReducer((draft, { inBackground }) => {
		draft.isLoading = !inBackground;
		draft.isLoadingUpload = inBackground;
		draft.isNoItems = false;
		draft.items = [];

		draft.scroll.page = 0;
	}),
	[action.initRegistriesList.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.isLoading = false;
		draft.isLoadingUpload = false;
		draft.isNoItems = items.length === 0;
		draft.items = items;

		draft.scroll.totalElements = totalElements;
	}),
	[action.initRegistriesList.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isNoItems = true;
	}),

	// Infinite scroll
	[action.scrollRegistriesList.request]: createMutateReducer(draft => {
		draft.scroll.isLoading = true;
	}),
	[action.scrollRegistriesList.success]: createMutateReducer((draft, { items, page }, state) => {
		draft.scroll.isLoading = false;
		draft.scroll.page = page;
		draft.items = [...state.items, ...items];
	}),
	[action.scrollRegistriesList.error]: createMutateReducer(draft => {
		draft.scroll.isLoading = false;
	}),

	// Search
	[action.searchRegistriesList.request]: createMutateReducer((draft, { value }) => {
		draft.search.isLoading = true;
		draft.search.value = value;

		draft.scroll.page = 0;
	}),
	[action.searchRegistriesList.success]: createMutateReducer((draft, { value, items, totalElements }, state) => {
		if (value === state.search.value) {
			draft.search.isLoading = false;
			draft.search.isNoItems = items.length === 0;
			draft.scroll.totalElements = totalElements;
			draft.items = items;
		}
	}),
	[action.searchRegistriesList.error]: createMutateReducer(draft => {
		draft.search.isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
