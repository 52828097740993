import { createSelector } from "reselect";

const kkmGroupAddSelector = state => state.kkmGroupAdd;

export const isLoadingSelector = createSelector(
	kkmGroupAddSelector,
	state => state.isLoading
);
export const isLoadingSendSelector = createSelector(
	kkmGroupAddSelector,
	state => state.isLoadingSend
);
export const formStateSelector = createSelector(
	kkmGroupAddSelector,
	state => state.formState
);
