import { createMutateReducer } from "common/helpers/reducer";
import { uniqueElement } from "common/helpers/array";
import * as action from "../actions/companySelectAction";

const initialState = {
	// formName: {
	// 	fieldName: {
	// 		type: "owner", // ("owner" | "children")
	// 		isLoading: false,
	// 		options: []
	// 	}
	// }
};
const actionHandlers = {
	// Initialize
	[action.initCpCompanySelect.request]: createMutateReducer((draft, { form, name, type }, state) => {
		draft[form] = state[form] || {};
		draft[form][name] = (state[form] && state[form][name]) || {};

		draft[form][name] = {
			type,
			isLoading: true,
			options: []
		};
	}),
	[action.initCpCompanySelect.success]: createMutateReducer((draft, { form, name, options }) => {
		draft[form][name].isLoading = false;
		draft[form][name].options = options;
	}),
	[action.initCpCompanySelect.error]: createMutateReducer((draft, { form, name }) => {
		draft[form][name].isLoading = false;
		draft[form][name].options = [];
	}),

	// Search
	[action.searchCpCompanySelect.request]: createMutateReducer((draft, { form, name }) => {
		draft[form][name].isLoading = true;
	}),
	[action.searchCpCompanySelect.success]: createMutateReducer((draft, { form, name, options }, state) => {
		const _options = (state[form] && state[form][name] && state[form][name].options) || [];

		draft[form][name].isLoading = false;
		draft[form][name].options = uniqueElement([..._options, ...options], "value");
	}),
	[action.searchCpCompanySelect.error]: createMutateReducer((draft, { form, name }) => {
		draft[form][name].isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
