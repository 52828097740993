import { all, takeEvery, put, call, select } from "redux-saga/effects";

import { notification, message } from "components/ui";
import { companyIdSelector } from "modules/account/selectors/accountSelector";
import * as companyDataContext from "common/dataProvider/company";
import * as action from "../actions/kkmSettingsAction";

export function* initKkmTransactionSaga({ payload }) {
	try {
		const { kkmId } = payload;
		const companyId = yield select(companyIdSelector);

		const kkm = yield call(companyDataContext.getCompanyKkmId, { companyId, kkmId });

		yield put(action.initKkmSettings.success({ kkm }));
	} catch (e) {
		yield put(action.initKkmSettings.error());
		notification.httpError();
	}
}

export function* sendSettingKkmSettingsSaga({ payload }) {
	try {
		const { kkmId, form, type } = payload;
		const companyId = yield select(companyIdSelector);

		let query = {};
		let method = null;

		switch (type) {
			case "name":
				method = companyDataContext.postCompanyByIdKkmByIdName;
				query = { newName: form.name };
				break;
			case "company":
				method = companyDataContext.postCompanyByIdKkmByIdChildCompany;
				query = { newChildCompany: form.childCompanyId };
				break;
			case "timezone":
				method = companyDataContext.postCompanyByIdKkmByIdTimezone;
				query = { newTimezone: form.timezone };
				break;
			default:
				throw new Error(`Unknown request type: ${type}`);
		}

		const { name, childCompanyId } = yield call(method, { companyId, kkmId, query });

		yield put(action.successUpdateKkmSettings({ name, childCompanyId }));
		message.success("Настройки кассы успешно изменены");
	} catch (e) {
		yield put(action.failureUpdateKkmSettings());
		notification.httpError();
	}
}

export function* sendApiKeyKkmSettingsSaga({ payload }) {
	try {
		const { kkmId } = payload;
		const companyId = yield select(companyIdSelector);

		const { apiKey } = yield call(companyDataContext.postCompanyByIdKkmByIdApiKey, { companyId, kkmId });

		yield put(action.successUpdateKkmSettings({ apiKey }));
		message.success("Новый API-ключ успешно сгененрирован");
	} catch (e) {
		yield put(action.failureUpdateKkmSettings());
		notification.httpError();
	}
}

export function* sendHandlerKkmSettingsSaga({ payload }) {
	try {
		const { kkmId, form } = payload;
		const companyId = yield select(companyIdSelector);

		yield call(companyDataContext.postCompanyByIdKkmByIdCallback, { companyId, kkmId, form });

		yield put(action.successUpdateKkmSettings());
		message.success("Новый обработчик успешно установлен");
	} catch (e) {
		yield put(action.failureUpdateKkmSettings());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initKkmSettings.request, initKkmTransactionSaga),
		takeEvery(action.sendSettingKkmSettings.request, sendSettingKkmSettingsSaga),
		takeEvery(action.sendApiKeyKkmSettings.request, sendApiKeyKkmSettingsSaga),
		takeEvery(action.sendHandlerKkmSettings.request, sendHandlerKkmSettingsSaga)
	]);
}
