import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/cpOffersAction";

const initialState = {
	isLoading: true,
	isActLoading: false,

	docs: [],
	userTypes: []
};
const actionHandlers = {
	// initialize
	[action.initCpOffers.request]: createMutateReducer(draft => {
		draft.isLoading = true;

		draft.docs = [];
		draft.userTypes = [];
	}),
	[action.initCpOffers.success]: createMutateReducer((draft, { docs, userTypes }) => {
		draft.isLoading = false;

		draft.docs = docs;
		draft.userTypes = userTypes;
	}),
	[action.initCpOffers.error]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),

	[action.deleteCpUserType.request]: createMutateReducer(draft => {
		draft.isActLoading = true;
	}),
	[action.editCpUserType.request]: createMutateReducer(draft => {
		draft.isActLoading = true;
	}),
	[action.editCpUserType.success]: createMutateReducer((draft, { userTypes }) => {
		draft.isActLoading = false;
		draft.userTypes = userTypes;
	}),
	[action.editCpUserType.error]: createMutateReducer(draft => {
		draft.isActLoading = false;
	}),

	[action.deleteCpDoc.request]: createMutateReducer(draft => {
		draft.isActLoading = true;
	}),
	[action.editCpDoc.request]: createMutateReducer(draft => {
		draft.isActLoading = true;
	}),
	[action.editCpDoc.success]: createMutateReducer((draft, { docs }) => {
		draft.isActLoading = false;
		draft.docs = docs;
	}),
	[action.editCpDoc.error]: createMutateReducer(draft => {
		draft.isActLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
