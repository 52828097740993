import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/cpRegistriesPreviewAction";

const initialState = {
	isLoading: true,
	isNoItems: false,
	scroll: {
		isLoading: false,

		size: 20,
		page: 0,
		totalElements: 0
	},
	search: {
		value: "",
		isLoading: false,
		isNoItems: false
	},
	filter: {
		isLoading: false,
		by: "",
		start: "",
		end: ""
	},

	registries: {},
	items: [],
	action: {}
};
const actionHandlers = {
	// Initialize
	[action.initRegistriesKkmPreview.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.isNoItems = false;
		draft.items = [];
		draft.filter.isLoading = false;
		draft.scroll.page = 0;
	}),
	[action.initRegistriesKkmPreview.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.isLoading = false;
		draft.isNoItems = items.length === 0;
		draft.items = items;

		draft.scroll.totalElements = totalElements;
	}),
	[action.initRegistriesKkmPreview.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isNoItems = true;
	}),

	// Infinite scroll
	[action.scrollRegistriesKkmPreview.request]: createMutateReducer(draft => {
		draft.scroll.isLoading = true;
	}),
	[action.scrollRegistriesKkmPreview.success]: createMutateReducer((draft, { items, page }, state) => {
		draft.scroll.isLoading = false;
		draft.scroll.page = page;
		draft.items = [...state.items, ...items];
	}),
	[action.scrollRegistriesKkmPreview.error]: createMutateReducer(draft => {
		draft.scroll.isLoading = false;
	}),

	// Search
	[action.searchRegistriesKkmPreview.request]: createMutateReducer((draft, { value }) => {
		draft.search.isLoading = true;
		draft.search.value = value;

		draft.scroll.page = 0;
	}),
	[action.searchRegistriesKkmPreview.success]: createMutateReducer(
		(draft, { value, items, totalElements }, state) => {
			if (value === state.search.value) {
				draft.search.isLoading = false;
				draft.search.isNoItems = items.length === 0;
				draft.scroll.totalElements = totalElements;
				draft.items = items;
			}
		}
	),
	[action.searchRegistriesKkmPreview.error]: createMutateReducer(draft => {
		draft.search.isLoading = false;
	}),

	// Filter
	[action.filterRegistriesKkmPreview.request]: createMutateReducer((draft, params, state) => {
		draft.filter = {
			isLoading: true,
			...state.filter,
			...params
		};
	}),
	[action.filterRegistriesKkmPreview.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.scroll.page = 0;
		draft.scroll.totalElements = totalElements;

		draft.filter.isLoading = false;

		draft.items = items;
	}),
	[action.filterRegistriesKkmPreview.error]: createMutateReducer(draft => {
		draft.filter.isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
