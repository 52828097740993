import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/usersListAction";

const initialState = {
	isLoading: true,
	items: []
};
const actionHandlers = {
	[action.initUsersList.request]: createMutateReducer(draft => {
		draft.isLoading = true;
	}),
	[action.initUsersList.success]: createMutateReducer((draft, { content }) => {
		draft.isLoading = false;
		draft.items = content;
	}),
	[action.initUsersList.error]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),

	// Show user info
	[action.showInfoUsersList]: createMutateReducer((draft, { id }) => {
		draft.items.map(e => {
			e.isOpen = e.id === id ? !e.isOpen : false;
			return e;
		});
	}),

	// Update access
	[action.updateAccessUserList.request]: createMutateReducer((draft, { id, key }, state) => {
		const currentItem = draft.items.find(e => e.id === id);
		const _currentItem = state.items.find(e => e.id === id);

		currentItem.loadingAccess = _currentItem?.loadingAccess || {};
		currentItem.loadingAccess[key] = true;
	}),
	[action.updateAccessUserList.success]: createMutateReducer((draft, { id, key, value }) => {
		const currentItem = draft.items.find(e => e.id === id);
		currentItem.loadingAccess[key] = false;
		currentItem.currentCompanyAccess[key] = value;
	}),
	[action.updateAccessUserList.error]: createMutateReducer((draft, { id, key, value }) => {
		const currentItem = draft.items.find(e => e.id === id);
		currentItem.loadingAccess[key] = false;
		currentItem.currentCompanyAccess[key] = !value;
	}),

	// Delete user
	[action.deleteUserUserList.request]: createMutateReducer((draft, { id }) => {
		const currentItem = draft.items.find(e => e.id === id);
		currentItem.isLoadingDelete = true;
	}),
	[action.deleteUserUserList.success]: createMutateReducer((draft, { id }) => {
		const currentItem = draft.items.find(e => e.id === id);
		currentItem.isLoadingDelete = false;
	}),
	[action.deleteUserUserList.error]: createMutateReducer((draft, { id }) => {
		const currentItem = draft.items.find(e => e.id === id);
		currentItem.isLoadingDelete = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
