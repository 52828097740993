import { lazy } from "react";

// Reducers...
import companySelectReducer from "./reducers/companySelectReducer";
import cpCompanyListReducer from "./reducers/cpCompanyListReducer";
import cpCompanyAddReducer from "./reducers/cpCompanyAddReducer";
import cpCompanyPreviewReducer from "./reducers/cpCompanyPreviewReducer";

// Sagas...
import companySelectSaga from "./sagas/companySelectSaga";
import cpCompanyListSaga from "./sagas/cpCompanyListSaga";
import cpCompanyAddSaga from "./sagas/cpCompanyAddSaga";
import cpCompanyPreviewSaga from "./sagas/cpCompanyPreviewSaga";

export const getReducers = createReducer => ({
	companySelect: companySelectReducer(createReducer),

	companyList: cpCompanyListReducer(createReducer),
	companyAdd: cpCompanyAddReducer(createReducer),
	companyPreview: cpCompanyPreviewReducer(createReducer)
});
export const getSagas = () => [companySelectSaga(), cpCompanyListSaga(), cpCompanyAddSaga(), cpCompanyPreviewSaga()];
export const getRoutes = () => ({
	cpCompanyList: {
		exact: true,
		path: "/control-panel/company",
		component: lazy(() => import("./containers/CpCompanyListContainer"))
	},
	cpCompanyAdd: {
		exact: true,
		path: "/control-panel/company/add",
		component: lazy(() => import("./containers/CpCompanyAddContainer"))
	},
	cpCompanyPreview: {
		exact: true,
		path: "/control-panel/company/:companyId",
		component: lazy(() => import("./containers/CpCompanyPreviewContainer"))
	}
});
