import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NoItems, WrapperLayer, TableListComponent } from "components/ui";

import FilterComponent from "modules/kkm/components/KkmTransaction/FilterComponent";
import ItemComponent from "modules/kkm/components/KkmTransaction/ItemComponent";

import * as action from "../actions/kkmGroupTransactionListAction";
import * as selector from "../selectors/kkmGroupTransactionListSelector";

@withRouter
@connect(
	(state, ownProps) => ({
		kkmGroupId: ownProps.match.params.kkmGroupId,

		isLoading: selector.isLoadingSelector(state),
		isNoItems: selector.isNoItemsSelector(state),

		scroll: selector.scrollSelector(state),
		search: selector.searchSelector(state),
		filter: selector.filterSelector(state),
		sort: selector.sortSelector(state),
		itemsActions: selector.itemsActionsSelector(state),
		group: selector.groupSelector(state),
		items: selector.itemsSelector(state)
	}),
	{
		initKkmGroupTransactionList: action.initKkmGroupTransactionList.request,
		scrollKkmGroupTransactionList: action.scrollKkmGroupTransactionList.request,
		searchKkmGroupTransactionList: action.searchKkmGroupTransactionList.request,
		filterKkmGroupTransactionList: action.filterKkmGroupTransactionList.request,
		sortKkmGroupTransactionList: action.sortKkmGroupTransactionList.request,

		showDetailKkmGroupTransactionList: action.showDetailKkmGroupTransactionList
	}
)
export default class KkmGroupTransactionListContainer extends Component {
	static propTypes = {
		kkmGroupId: PropTypes.string,

		isLoading: PropTypes.bool,
		isNoItems: PropTypes.bool,
		scroll: PropTypes.object,
		search: PropTypes.object,
		filter: PropTypes.object,
		sort: PropTypes.object,
		itemsActions: PropTypes.object,
		group: PropTypes.object,
		items: PropTypes.array,

		initKkmGroupTransactionList: PropTypes.func,
		scrollKkmGroupTransactionList: PropTypes.func,
		searchKkmGroupTransactionList: PropTypes.func,
		filterKkmGroupTransactionList: PropTypes.func,
		sortKkmGroupTransactionList: PropTypes.func,

		showDetailKkmGroupTransactionList: PropTypes.func,

		history: PropTypes.shape({
			push: PropTypes.func
		})
	};

	constructor(props) {
		super(props);
		props.initKkmGroupTransactionList({ kkmGroupId: props.kkmGroupId });
	}

	get headerTable() {
		return [
			{ key: "requestId", name: "Request ID", sort: true, style: { width: "100px" } },
			{ key: "customerAccount", name: "Лицевой счет" },
			{ key: "bic", name: "БИК" },
			{ key: "customerName", name: "ФИО", sort: true },
			{ key: "type", name: "Тип чека", sort: true, style: { width: "120px" } },
			{ key: "totalSum", name: "Общая сумма", sort: true },
			{ key: "lastStatus", name: "Статус", sort: true, style: { width: "100px" } },
			{ key: "fiscalDocNum", name: "Номер ФД", sort: true },
			{ key: "ofdLink", name: "Чек в ОФД" },
			{ key: "checkOutDate", name: "Дата оплаты", sort: true },
			{
				key: "transactionCreated",
				name: (
					<>
						Транзакция
						<br />
						создана
					</>
				),
				title: "Транзакция создана",
				style: { width: "180px" }
			},
			{
				key: "transactionCompleted",
				name: (
					<>
						Транзакция
						<br />
						выполнена
					</>
				),
				title: "Транзакция выполнена",
				style: { width: "180px" }
			}
		];
	}

	get searchTable() {
		const { search, items } = this.props;

		return {
			placeholder: "Для поиска начните вводить номер лицевого счета или ФИО",
			value: search.value,
			isNoItems: !items?.length,
			isLoading: search.isLoading,
			onChange: this.handleSearch
		};
	}

	handleScroll = () => {
		const { scroll, items, scrollKkmGroupTransactionList } = this.props;

		if (scroll.totalElements > items.length) {
			scrollKkmGroupTransactionList();
		}
	};

	handleSearch = value => {
		const { searchKkmGroupTransactionList } = this.props;
		searchKkmGroupTransactionList({ value });
	};

	handleClickItem = item => {
		const { showDetailKkmGroupTransactionList } = this.props;
		showDetailKkmGroupTransactionList({ requestId: item.requestId });
	};

	handleFilter = props => {
		const { filterKkmGroupTransactionList } = this.props;
		filterKkmGroupTransactionList(props);
	};

	handleSort = sort => {
		const { sortKkmGroupTransactionList } = this.props;
		sortKkmGroupTransactionList(sort);
	};

	render() {
		const { kkmGroupId, isLoading, isNoItems, scroll, filter, sort, itemsActions, group, items } = this.props;

		const title = `Транзакции группы касс${group?.name ? ": " + group.name : ""}`;
		const breadcrumb = [
			{ to: "/kkm-group", label: "Группы касс" },
			{ to: `/kkm-group/${kkmGroupId}`, label: title }
		];

		return (
			<WrapperLayer title={title} breadcrumb={breadcrumb} isLoading={isLoading}>
				<NoItems isShow={isNoItems} description="У группы касс еще нет транзакций" />
				<FilterComponent isShow={!isNoItems} {...filter} onFilter={this.handleFilter} />

				<TableListComponent
					isShow={!isNoItems}
					isLoading={scroll.isLoading}
					sort={sort}
					header={this.headerTable}
					search={this.searchTable}
					onScroll={this.handleScroll}
					onSort={this.handleSort}
				>
					{items.map(item => (
						<ItemComponent
							key={item.requestId}
							actions={itemsActions[item.requestId] || {}}
							item={item}
							onClickItem={this.handleClickItem}
						/>
					))}
				</TableListComponent>
			</WrapperLayer>
		);
	}
}
