import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/cpUsersListAction";

const initialState = {
	isLoading: true,
	isNoItems: false,

	scroll: {
		isLoading: false,
		size: 100,
		page: 0,
		totalElements: 0
	},
	search: {
		isLoading: false,
		value: ""
	},

	items: []
};
const actionHandlers = {
	// initialize
	[action.initCpUsersList.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.scroll.page = 0;
	}),
	[action.initCpUsersList.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.isLoading = false;
		draft.isNoItems = !items.length;
		draft.scroll.totalElements = totalElements;
		draft.items = items;
	}),
	[action.initCpUsersList.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isNoItems = true;
	}),

	// infinite scroll
	[action.scrollCpUsersList.request]: createMutateReducer(draft => {
		draft.scroll.isLoading = true;
	}),
	[action.scrollCpUsersList.success]: (state, { items, page }) => {
		return {
			...state,
			scroll: {
				...state.scroll,
				isLoading: false,
				page
			},
			items: [...state.items, ...items]
		};
	},
	[action.scrollCpUsersList.error]: createMutateReducer(draft => {
		draft.scroll.isLoading = false;
	}),

	// search
	[action.searchCpUsersList.request]: createMutateReducer((draft, { value }) => {
		draft.search.isLoading = true;
		draft.search.isNoItems = false;
		draft.search.value = value;
	}),
	[action.searchCpUsersList.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.search.isLoading = false;
		draft.search.isNoItems = !items.length;
		draft.scroll.totalElements = totalElements;
		draft.scroll.page = 0;
		draft.items = items;
	}),
	[action.searchCpUsersList.error]: createMutateReducer(draft => {
		draft.search.isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
