import { createSelector } from "reselect";

export const correctionListSelector = state => state.correctionList;

export const isLoadingSelector = createSelector(correctionListSelector, state => state.isLoading);
export const isNoItemsSelector = createSelector(correctionListSelector, state => state.isNoItems);
export const scrollSelector = createSelector(correctionListSelector, state => state.scroll);
export const searchSelector = createSelector(correctionListSelector, state => state.search);
export const filterSelector = createSelector(correctionListSelector, state => state.filter);
export const sortSelector = createSelector(correctionListSelector, state => state.sort);
export const itemsActionsSelector = createSelector(correctionListSelector, state => state.itemsActions);
export const kkmIdSelector = createSelector(correctionListSelector, state => state.kkmId);
export const companySelector = createSelector(correctionListSelector, state => state.company);
export const itemsSelector = createSelector(correctionListSelector, state => state.items);
export const kkmSelector = createSelector(correctionListSelector, state => state.kkm);
