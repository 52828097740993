import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { WrapperLayer, Loader } from "components/ui";

import UsersAddFrom from "../components/UsersAddFrom";

import * as action from "../actions/usersAddAction";
import * as selector from "../selectors/usersAddSelector";
import { selectedCompanySelector } from "../../account/selectors/accountSelector";

@connect(
	state => ({
		isLoading: selector.isLoadingSelector(state),
		isLoadingSend: selector.isLoadingSendSelector(state),
		formState: selector.formStateSelector(state),
		company: selectedCompanySelector(state)
	}),
	{
		initUsersAdd: action.initUsersAdd.request,
		sendUsersAdd: action.sendUsersAdd.request
	}
)
export default class UsersAddContainer extends Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		isLoadingSend: PropTypes.bool,
		formState: PropTypes.object,
		company: PropTypes.object,
		initUsersAdd: PropTypes.func,
		sendUsersAdd: PropTypes.func
	};

	constructor(props) {
		super(props);
		props.initUsersAdd();
	}

	render() {
		const { isLoading, isLoadingSend, sendUsersAdd, company, formState } = this.props;
		const title = `Добавление пользователя${company?.name ? ` в компанию ${company.name}` : ""}`;

		return (
			<WrapperLayer
				title="Добавление пользователя"
				breadcrumb={[{ to: "/users", label: "Пользователи компании" }, { to: "/users/add", label: title }]}
				isLoading={isLoading}
			>
				{isLoadingSend && <Loader />}

				<div className="row">
					<div className="col-12">
						<UsersAddFrom isLoading={isLoadingSend} initialValues={formState} onSubmitForm={sendUsersAdd} />
					</div>
				</div>
			</WrapperLayer>
		);
	}
}
