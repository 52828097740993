import { all, takeEvery, call, put, debounce } from "redux-saga/effects";
import { push } from "connected-react-router";
import { escapeRegExp } from "common/helpers/string";
import { message, notification } from "components/ui";
import * as controlDataContext from "common/dataProvider/control";

import * as action from "../actions/cpUsersPreviewAction";

export function* initCpUsersPreviewSaga({ payload }) {
	try {
		const { userId } = payload;
		const { user, userTypes } = yield all({
			user: call(controlDataContext.getControlUserById, { userId }),
			userTypes: call(controlDataContext.getControlUserType),
			searchCompany: call(searchCompanyCpUsersPreviewSaga, { payload: { value: "" } }),
			companies: call(loadCompanyCpUsersPreviewSaga, { userId })
		});

		yield put(
			action.initCpUsersPreview.success({
				user,
				userTypes
			})
		);
	} catch (e) {
		yield put(action.initCpUsersPreview.error());
		notification.httpError();
	}
}

export function* updateCpUsersPreviewSaga({ payload }) {
	try {
		const { userId, form: query } = payload;

		yield call(controlDataContext.putControlUserById, { userId, query });
		yield put(action.updateCpUsersPreview.success());

		message.success("Профиль пользователя успешно изменен");
	} catch (e) {
		yield put(action.updateCpUsersPreview.error());
		notification.httpError();
	}
}

export function* updPassCpUsersPreviewSaga({ payload }) {
	try {
		const { userId, form } = payload;
		const query = {
			newPassword: form.newPassword
		};

		if (!form.password || form.password !== form.newPassword) {
			notification.error("Изменение пароля", "Пароли не совпадают, попробуйте еще");
			yield put(action.updPassCpUsersPreview.error());

			return null;
		}

		yield call(controlDataContext.postControlUserByIdPassword, { userId, query });
		yield put(action.updPassCpUsersPreview.success());

		message.success("Пароль пользователя успешно изменен");
	} catch (e) {
		yield put(action.updPassCpUsersPreview.error());
		notification.httpError();
	}
}

export function* removeCpUsersPreviewSaga({ payload }) {
	try {
		const { userId } = payload;

		yield call(controlDataContext.deleteControlUserById, { userId });

		yield put(action.removeCpUsersPreview.success());
		yield put(push("/control-panel/users"));

		message.success("Пользователь успешно удален");
	} catch (e) {
		yield put(action.removeCpUsersPreview.error());
		notification.httpError();
	}
}

export function* searchCompanyCpUsersPreviewSaga({ payload }) {
	try {
		const { value } = payload;
		const query = { size: 10, q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined };

		const response = yield call(controlDataContext.getControlCompany, { query });

		yield put(action.searchCompanyCpUsersPreview.success(response));
	} catch (e) {
		yield put(action.searchCompanyCpUsersPreview.error());
	}

	return true;
}

export function* loadCompanyCpUsersPreviewSaga({ userId }) {
	try {
		const content = yield call(controlDataContext.getControlUserByIdCompany, { userId });

		yield put(action.loadCompanyCpUsersPreview.success({ content }));
	} catch (e) {
		yield put(action.loadCompanyCpUsersPreview.error());
	}
}

export function* addCompanyCpUsersPreviewSaga({ payload }) {
	try {
		const { userId, companyId } = payload;
		const query = { userId, companyId };

		yield call(controlDataContext.postControlCompanyUserLink, { query });
		yield call(loadCompanyCpUsersPreviewSaga, query);

		yield put(action.addCompanyCpUsersPreview.success());

		message.success("Пользователь успешно добавлен в компанию");
	} catch (e) {
		yield put(action.addCompanyCpUsersPreview.error());

		if (e?.response?.status === 409) {
			notification.error("Ошибка", "Пользователь уже состоит в данной компании");
			return null;
		}

		notification.httpError();
	}
}

export function* removeCompanyCpUsersPreviewSaga({ payload }) {
	try {
		const { userId, companyId } = payload;
		const query = { userId, companyId };

		yield call(controlDataContext.postControlCompanyUserUnlink, { query });
		yield call(loadCompanyCpUsersPreviewSaga, query);

		yield put(action.removeCompanyCpUsersPreview.success());

		message.success("Пользователь успешно удален из компании");
	} catch (e) {
		yield put(action.removeCompanyCpUsersPreview.error());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpUsersPreview.request, initCpUsersPreviewSaga),
		takeEvery(action.updateCpUsersPreview.request, updateCpUsersPreviewSaga),
		takeEvery(action.updPassCpUsersPreview.request, updPassCpUsersPreviewSaga),
		takeEvery(action.removeCpUsersPreview.request, removeCpUsersPreviewSaga),

		// User and company action
		debounce(400, action.searchCompanyCpUsersPreview.request, searchCompanyCpUsersPreviewSaga),
		takeEvery(action.addCompanyCpUsersPreview.request, addCompanyCpUsersPreviewSaga),
		takeEvery(action.removeCompanyCpUsersPreview.request, removeCompanyCpUsersPreviewSaga)
	]);
}
