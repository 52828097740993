export const COMPANY_ACCESS = {
	showApiTokenKkm: "showApiTokenKkm",
	changeApiTokenKkm: "changeApiTokenKkm",
	showChildrenCompany: "showChildrenCompany",
	editChildrenCompany: "editChildrenCompany",
	showUserCompany: "showUserCompany",
	editUserCompany: "editUserCompany",
	showCallbackKkm: "showCallbackKkm",
	changeCallbackKkm: "changeCallbackKkm",
	sendTransactionKkm: "sendTransactionKkm"
};

export const COMPANY_ACCESS_LABEL = {
	showApiTokenKkm: "Может видеть API-Ключ",
	changeApiTokenKkm: "Может изменять API-Ключ",
	showChildrenCompany: "Может видеть дочерние компании",
	editChildrenCompany: "Может изменять дочернию компанию",
	showUserCompany: "Может видеть пользователей компании",
	editUserCompany: "Может добавлять пользователей в компанию",
	showCallbackKkm: "Может видеть настройки обработчика",
	changeCallbackKkm: "Может изменять настройки обработчика",
	sendTransactionKkm: "Может выполнять ручные транзакции"
};
