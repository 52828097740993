import { all, takeEvery, call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { message, notification } from "components/ui";
import { randString } from "common/helpers/crypt";
import * as controlDataContext from "common/dataProvider/control";

import * as action from "../actions/cpUsersAddAction";

export function* initCpUsersAddSaga() {
	try {
		const userTypes = yield call(controlDataContext.getControlUserType);

		const formState = {
			password: randString()
		};

		yield put(action.initCpUsersAdd.success({ formState, userTypes }));
	} catch (e) {
		yield put(action.initCpUsersAdd.error());
	}
}

export function* sendCpUsersAddSaga({ payload }) {
	try {
		yield call(controlDataContext.postControlUser, { query: payload });

		message.success("Пользователь успешно добавлен");

		yield put(action.sendCpUsersAdd.success());
		yield put(push("/control-panel/users"));
	} catch (e) {
		yield put(action.sendCpUsersAdd.error());

		if (e?.response?.status === 409) {
			notification.error("Ошибка", `Указанная электронная почта (${payload.email}) уже используется.`);
		} else {
			notification.error("Ошибка", "При добавлении пользователя произошла ошибка, попробуйте повторить позднее");
		}
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpUsersAdd.request, initCpUsersAddSaga),
		takeEvery(action.sendCpUsersAdd.request, sendCpUsersAddSaga)
	]);
}
