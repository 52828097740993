import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as action from "modules/app/actions/smartAction";
import * as selector from "modules/app/selectors/smartSelector";

import AutoCompleteField from "../form/AutoCompleteField";

@connect(
	state => ({
		companyState: selector.companyAutoCompleteSelector(state)
	}),
	{
		searchCompanyAutoComplete: action.searchCompanyAutoComplete.request
	}
)
export default class UserAutoComplete extends Component {
	static propTypes = {
		companyState: PropTypes.shape({
			isLoading: PropTypes.bool,
			value: PropTypes.string,
			items: PropTypes.array
		}),
		value: PropTypes.string,
		searchCompanyAutoComplete: PropTypes.func
	};

	constructor(props) {
		super(props);
		props.searchCompanyAutoComplete(props?.value || "");
	}

	render() {
		const { companyState, searchCompanyAutoComplete, ...props } = this.props;

		return (
			<AutoCompleteField
				{...props}
				pickOut
				noDataMess="Поиск не дал результатов"
				dataSource={companyState.items}
				isLoading={companyState.isLoading}
				value={companyState.value}
				valueKey={e => e?.name}
				valueStr={e => e?.name}
				valueDesc={e => `${e?.inn}`}
				onSearch={searchCompanyAutoComplete}
			/>
		);
	}
}
