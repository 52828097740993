import { all, takeEvery, call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { message, notification } from "components/ui";
import * as companyDataContext from "common/dataProvider/company";
import { randString } from "common/helpers/crypt";
import { companyIdSelector } from "../../account/selectors/accountSelector";
import * as action from "../actions/usersAddAction";

export function* initUsersAddSaga() {
	try {
		const formState = {
			searchUser: false,
			userData: {
				password: randString()
			}
		};
		yield put(action.initUsersAdd.success({ formState }));
	} catch (e) {
		yield put(action.initUsersAdd.error());
	}
}

export function* sendUsersAddSaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);

		yield call(companyDataContext.postCompanyByIdUser, { companyId, query: payload });

		yield put(action.sendUsersAdd.success());
		yield put(push("/users"));

		message.success("Пользователь успешно добавлен в компанию");
	} catch (e) {
		yield put(action.sendUsersAdd.error());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initUsersAdd.request, initUsersAddSaga),
		takeEvery(action.sendUsersAdd.request, sendUsersAddSaga)
	]);
}
