import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/cpUsersPreviewAction";

const initialState = {
	isLoading: true,
	isError: false,
	isLoadingAction: false,

	user: {},
	companies: [],

	searchCompany: {
		isLoading: false,
		options: []
	},

	userTypes: []
};
const actionHandlers = {
	// Initialize user profile
	[action.initCpUsersPreview.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.isError = false;
		draft.user = {};
	}),
	[action.initCpUsersPreview.success]: createMutateReducer((draft, { user, userTypes }) => {
		draft.isLoading = false;
		draft.user = user;
		draft.userTypes = userTypes;
	}),
	[action.initCpUsersPreview.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isError = true;
	}),

	// Update profile
	[action.updateCpUsersPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.updateCpUsersPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.updateCpUsersPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Update password
	[action.updPassCpUsersPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.updPassCpUsersPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.updPassCpUsersPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Remove password
	[action.removeCpUsersPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.removeCpUsersPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.removeCpUsersPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Search company
	[action.searchCompanyCpUsersPreview.request]: createMutateReducer(draft => {
		draft.searchCompany.isLoading = true;
	}),
	[action.searchCompanyCpUsersPreview.success]: createMutateReducer((draft, { content }) => {
		draft.searchCompany.isLoading = false;
		draft.searchCompany.options = content;
	}),
	[action.searchCompanyCpUsersPreview.error]: createMutateReducer(draft => {
		draft.searchCompany.isLoading = false;
	}),

	// Load user to company
	[action.loadCompanyCpUsersPreview.success]: createMutateReducer((draft, { content }) => {
		draft.companies = content;
	}),

	// Add user to company
	[action.addCompanyCpUsersPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.addCompanyCpUsersPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.addCompanyCpUsersPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Remove user to company
	[action.removeCompanyCpUsersPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.removeCompanyCpUsersPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.removeCompanyCpUsersPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
