import React, { Component } from "react";
import PropTypes from "prop-types";
import reduxForm, { Form, SelectField } from "components/form";
import { Button } from "components/ui";

@reduxForm({ form: "kkmSettingAdd" })
export default class AddKkmForm extends Component {
	static propTypes = {
		isDisabled: PropTypes.bool,
		searchKkm: PropTypes.shape({
			isLoading: PropTypes.bool,
			options: PropTypes.array,
			onSearch: PropTypes.func
		}).isRequired,
		handleSubmit: PropTypes.func,
		onSubmit: PropTypes.func
	};

	render() {
		const { isDisabled, searchKkm, handleSubmit, onSubmit } = this.props;

		return (
			<Form onSubmit={handleSubmit(onSubmit)}>
				<SelectField
					name="kkmId"
					desc="Начните вводить название для поиска кассы"
					notFoundContent=""
					showSearch
					loading={searchKkm.isLoading}
					options={searchKkm.options}
					onSearch={searchKkm.onSearch}
				/>
				<Button htmlType="submit" type="primary" className="m-t-20" disabled={isDisabled}>
					Сохранить изменения
				</Button>
			</Form>
		);
	}
}
