import React from "react";
import PropTypes from "prop-types";
import { Button, Select, DatePicker } from "components/ui";

const FilterComponent = ({ isShow, start, end, by, onFilter }) =>
	(isShow && (
		<div className="row filter-bar col-12">
			<nav className="navbar m-b-20 p-10">
				<ul className="nav w-100">
					<li className="nav-item">
						<span className="nav-link pl-0">Фильтровать по:</span>
					</li>
					<li className="nav-item p-r-20">
						<Select
							className="wid-250"
							placeholder="Выберите"
							value={by}
							onChange={value => onFilter({ by: value })}
							options={[
								{ value: "checkOutDate", label: "Дате оплаты" },
								{ value: "transactionCreated", label: "Дате создания" },
								{ value: "transactionCompleted", label: "Дате выполнения" }
							]}
						/>
					</li>

					<li className="nav-item">
						<span className="nav-link pl-0">Дата:</span>
					</li>
					<li className="nav-item p-r-10">
						<DatePicker placeholder="От" value={start} max={end} onChange={start => onFilter({ start })} />
					</li>
					<li className="nav-item p-r-20">
						<DatePicker placeholder="До" value={end} min={start} onChange={end => onFilter({ end })} />
					</li>

					<li className="nav-item">
						<Button size="small" onClick={() => onFilter({ by: "", start: null, end: null })}>
							Сбросить
						</Button>
					</li>
				</ul>
			</nav>
		</div>
	)) ||
	null;

FilterComponent.propTypes = {
	isShow: PropTypes.bool,
	by: PropTypes.string,
	start: PropTypes.string,
	end: PropTypes.string,
	onFilter: PropTypes.func
};

export default FilterComponent;
