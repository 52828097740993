import { all, takeEvery, put, select, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import uuidv4 from "uuid/v4";
import { message } from "components/ui";
import * as companyDataContext from "common/dataProvider/company";

import { companyIdSelector } from "../../account/selectors/accountSelector";
import * as action from "../actions/kkmGroupAddAction";

export function* initKkmGroupAddSaga() {
	try {
		const formState = { apiKey: uuidv4() };
		yield put(action.initKkmGroupAdd.success({ formState }));
	} catch (e) {
		yield put(action.initKkmGroupAdd.error());
	}
}

export function* sendKkmGroupAddSaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);
		const { name, apiKey } = payload;
		const query = { name, apiKey };

		yield call(companyDataContext.postCompanyByIdKkmGroup, { companyId, query });

		message.success("Группа успешно добавлена");
		yield put(action.sendKkmGroupAdd.success());
		yield put(push(`/kkm-group`));
	} catch (e) {
		yield put(action.sendKkmGroupAdd.error());
		message.success("При добавлении группы произошла ошибка, попробуйде еще раз.");
	}
}

export default function*() {
	yield all([
		takeEvery(action.initKkmGroupAdd.request, initKkmGroupAddSaga),
		takeEvery(action.sendKkmGroupAdd.request, sendKkmGroupAddSaga)
	]);
}
