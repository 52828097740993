import { all, takeEvery, call, put, select } from "redux-saga/effects";
import { notification } from "components/ui";
import { companyIdSelector } from "../../account/selectors/accountSelector";
import * as companyDataContext from "../../../common/dataProvider/company";
import * as action from "../actions/kkmGroupListAction";
import * as selector from "../selectors/kkmGroupListSelector";

export function* initKkmGroupListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const query = { size };

		const response = yield call(companyDataContext.getCompanyKkmGroup, { companyId, query });

		yield put(action.initKkmGroupList.success(response));
	} catch (e) {
		yield put(action.initKkmGroupList.error());
	}
}

export function* scrollKkmGroupListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const { size, page } = yield select(selector.scrollSelector);
		const query = {
			size,
			page: page + 1
		};

		const response = yield call(companyDataContext.getCompanyKkmGroup, { companyId, query });

		yield put(
			action.scrollKkmGroupList.success({
				items: response.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollKkmGroupList.error());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initKkmGroupList.request, initKkmGroupListSaga),
		takeEvery(action.scrollKkmGroupList.request, scrollKkmGroupListSaga)
	]);
}
