import { all, takeEvery, put, call, debounce } from "redux-saga/effects";
import { push } from "connected-react-router";
import { message, notification } from "components/ui";
import * as controlDataContext from "common/dataProvider/control";

import * as action from "../actions/cpCompanyPreviewAction";
import { escapeRegExp } from "../../../../common/helpers/string";

export function* initCpCompanyPreviewSaga({ payload }) {
	try {
		const { companyId } = payload;
		const { company, user, kkm } = yield all({
			company: call(controlDataContext.getControlCompanyById, { companyId }),
			user: call(controlDataContext.getControlCompanyByIdUsers, { companyId }),
			kkm: call(controlDataContext.getControlCompanyByIdKkm, { companyId })
		});

		let parent = {};
		try {
			if (company?.parentId) {
				parent = yield call(controlDataContext.getControlCompanyById, { companyId: company.parentId });
			}
		} catch (e) {
			// неудалось найти владельца компании
		}

		yield put(action.initCpCompanyPreview.success({ company, user, kkm, owner: {}, parent }));
	} catch (e) {
		yield put(action.initCpCompanyPreview.error());
		notification.httpError();
	}
}

export function* updateCpCompanyPreviewSaga({ payload }) {
	try {
		const { companyId, form: query } = payload;

		yield call(controlDataContext.putControlCompanyById, { companyId, query });

		yield put(action.updateCpCompanyPreview.success());

		message.success("Компания успешно обновлена");
	} catch (e) {
		yield put(action.updateCpCompanyPreview.error());
		notification.httpError();
	}
}

export function* updateOwnerCpCompanyPreviewSaga({ payload }) {
	try {
		const { companyId, form } = payload;
		const query = { newOwnerId: form.email };

		if (!form.email) {
			notification.error("Ошибка", "Этот пользователь и так уже является владельцем компании");
			yield put(action.updateOwnerCpCompanyPreview.success());

			return null;
		}

		yield call(controlDataContext.putControlCompanyByIdOwner, { companyId, query });

		yield put(action.updateOwnerCpCompanyPreview.success());

		message.success("Владелец компании успешно изменен");
	} catch (e) {
		yield put(action.updateOwnerCpCompanyPreview.error());
		notification.httpError();
	}
}

export function* removeCpCompanyPreviewSaga({ payload }) {
	try {
		const { companyId } = payload;

		yield call(controlDataContext.deleteControlCompanyById, { companyId });
		yield put(push("/control-panel/company"));
		yield put(action.removeCpCompanyPreview.success());

		message.success("Компания успешно удалена");
	} catch (e) {
		yield put(action.removeCpCompanyPreview.error());
		notification.httpError();
	}
}

export function* searchOwnerCpCompanyPreviewSaga({ payload }) {
	try {
		const { value } = payload;
		const query = { size: 10, q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined };

		const response = yield call(controlDataContext.getControlUser, { query });

		yield put(action.searchOwnerCpCompanyPreview.success(response));
	} catch (e) {
		yield put(action.searchOwnerCpCompanyPreview.error());
	}
}

export function* searchCompanyCpCompanyPreviewSaga({ payload }) {
	try {
		const { value } = payload;
		const query = { size: 10, q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined, "top-only": true };
		const response = yield call(controlDataContext.getControlCompany, { query });

		yield put(action.searchCompanyCpCompanyPreview.success(response));
	} catch (e) {
		yield put(action.searchCompanyCpCompanyPreview.error());
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpCompanyPreview.request, initCpCompanyPreviewSaga),
		takeEvery(action.updateCpCompanyPreview.request, updateCpCompanyPreviewSaga),
		takeEvery(action.updateOwnerCpCompanyPreview.request, updateOwnerCpCompanyPreviewSaga),
		takeEvery(action.removeCpCompanyPreview.request, removeCpCompanyPreviewSaga),

		debounce(400, action.searchOwnerCpCompanyPreview.request, searchOwnerCpCompanyPreviewSaga),
		debounce(400, action.searchCompanyCpCompanyPreview.request, searchCompanyCpCompanyPreviewSaga)
	]);
}
