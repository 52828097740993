import { all, takeEvery, put, call, select, debounce } from "redux-saga/effects";
import { searchParams, sortParams } from "common/helpers/query";
import { notification } from "components/ui";
import { selectedCompanySelector, companyIdSelector } from "modules/account/selectors/accountSelector";
import * as companyDataContext from "common/dataProvider/company";

import * as action from "../actions/correctionListAction";
import * as selector from "../selectors/correctionListSelector";

export function* initCorrectionListSaga() {
	try {
		const company = yield select(selectedCompanySelector);
		const companyId = yield select(companyIdSelector);

		const kkmId = yield select(selector.kkmIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = { size, sort: sortParams({ field, order }) };

		const { kkm, correction } = yield all({
			kkm: call(companyDataContext.getCompanyKkmId, { companyId, kkmId }),
			correction: call(companyDataContext.getCompanyKkmCorrection, { companyId, kkmId, query })
		});

		yield put(
			action.initCorrectionList.success({
				company,
				kkm,
				items: correction.content,
				totalElements: correction.totalElements
			})
		);
	} catch (e) {
		yield put(action.initCorrectionList.error());
		notification.httpError();
	}
}

export function* scrollCorrectionListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const kkmId = yield select(selector.kkmIdSelector);
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			page: page + 1,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const transaction = yield call(companyDataContext.getCompanyKkmCorrection, {
			companyId,
			kkmId,
			query
		});

		yield put(
			action.scrollCorrectionList.success({
				items: transaction.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollCorrectionList.error());
		notification.httpError();
	}
}

export function* searchCorrectionListSaga({ payload }) {
	try {
		const { value } = payload;

		const companyId = yield select(companyIdSelector);
		const kkmId = yield select(selector.kkmIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const transaction = yield call(companyDataContext.getCompanyKkmCorrection, {
			companyId,
			kkmId,
			query
		});

		yield put(
			action.searchCorrectionList.success({
				items: transaction.content,
				totalElements: transaction.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchCorrectionList.error());
		notification.httpError();
	}
}

export function* filterCorrectionListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const kkmId = yield select(selector.kkmIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const transaction = yield call(companyDataContext.getCompanyKkmCorrection, {
			companyId,
			kkmId,
			query
		});

		yield put(
			action.filterCorrectionList.success({
				items: transaction.content,
				totalElements: transaction.totalElements
			})
		);
	} catch (e) {
		yield put(action.filterCorrectionList.error());
		notification.httpError();
	}
}

export function* sortCorrectionListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const kkmId = yield select(selector.kkmIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const transaction = yield call(companyDataContext.getCompanyKkmCorrection, {
			companyId,
			kkmId,
			query
		});

		yield put(
			action.sortCorrectionList.success({
				items: transaction.content,
				totalElements: transaction.totalElements
			})
		);
	} catch (e) {
		yield put(action.sortCorrectionList.error());
		notification.httpError();
	}
}

export function* showDetailCorrectionListSaga({ payload }) {
	const { requestId } = payload;

	try {
		const companyId = yield select(companyIdSelector);
		const kkmId = yield select(selector.kkmIdSelector);
		const itemsActions = yield select(selector.itemsActionsSelector);

		const actions = itemsActions[requestId];

		if (!actions.isOpen) {
			return;
		}

		const data = yield call(companyDataContext.getCompanyKkmCorrectionDetail, {
			companyId,
			kkmId,
			requestId
		});

		yield put(action.showDetailCorrectionList.success({ requestId, data }));
	} catch (e) {
		yield put(action.showDetailCorrectionList.error({ requestId }));
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCorrectionList.request, initCorrectionListSaga),
		takeEvery(action.scrollCorrectionList.request, scrollCorrectionListSaga),
		debounce(400, action.searchCorrectionList.request, searchCorrectionListSaga),
		takeEvery(action.filterCorrectionList.request, filterCorrectionListSaga),
		takeEvery(action.sortCorrectionList.request, sortCorrectionListSaga),
		takeEvery(action.showDetailCorrectionList.request, showDetailCorrectionListSaga)
	]);
}
