import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/kkmSettingsAction";

const initialState = {
	isLoading: true,
	isError: false,
	isLoadingAction: false,

	kkm: {}
};
const actionHandlers = {
	// Initialize transaction
	[action.initKkmSettings.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.isError = false;
		draft.isLoadingAction = false;

		draft.kkm = {};
	}),
	[action.initKkmSettings.success]: createMutateReducer((draft, { kkm }) => {
		draft.isLoading = false;
		draft.kkm = kkm;
	}),
	[action.initKkmSettings.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isError = true;
	}),

	// Send setting form
	[action.sendSettingKkmSettings.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),

	// Send api-key form
	[action.sendApiKeyKkmSettings.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),

	// Send handler form
	[action.sendHandlerKkmSettings.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),

	// ... result sending form
	[action.successUpdateKkmSettings]: createMutateReducer((draft, props = {}, state) => {
		draft.isLoadingAction = false;
		draft.kkm = {
			...state.kkm,
			...props
		};
	}),
	[action.failureUpdateKkmSettings]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
