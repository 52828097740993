import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Button, Switch } from "components/ui";
import { COMPANY_ACCESS, COMPANY_ACCESS_LABEL } from "../../enums";

export class UsersListItemComponent extends Component {
	static propTypes = {
		item: PropTypes.shape({
			isOpen: PropTypes.bool,
			isLoadingDelete: PropTypes.bool,
			loadingAccess: PropTypes.object,

			id: PropTypes.string,
			userFullName: PropTypes.string,
			phone: PropTypes.string,
			email: PropTypes.string,
			currentCompanyAccess: PropTypes.object
		}),
		onClick: PropTypes.func,
		onUpdateAccess: PropTypes.func,
		onDeleteUser: PropTypes.func
	};

	render() {
		const { item, onClick, onUpdateAccess, onDeleteUser } = this.props;

		return (
			<Fragment>
				<tr
					key={item.id}
					className={cn("cursor-pointer", {
						_open: item.isOpen
					})}
					onClick={onClick}
				>
					<td>{item.userFullName}</td>
					<td>{item.phone}</td>
					<td>{item.email}</td>
				</tr>
				{item.isOpen && (
					<tr className="table_item_open">
						<td className="full">
							<div className="p-b-10">
								<i className="feather icon-phone p-r-10" />
								{item.phone || <mark>не указан</mark>}
							</div>
							<div className="p-b-25">
								<i className="feather icon-mail p-r-10" />
								{item.email || <mark>не указан</mark>}
							</div>

							<Button size="small" onClick={onDeleteUser} loading={item.isLoadingDelete}>
								<i className="feather icon-user-x p-r-15" />
								Удалить пользователя
							</Button>
						</td>
						<td className="full" colSpan="2">
							{item.currentCompanyAccess &&
								Object.values(COMPANY_ACCESS).map(e => (
									<Switch
										key={e}
										loading={item.loadingAccess && item.loadingAccess[e]}
										checked={item.currentCompanyAccess[e]}
										label={COMPANY_ACCESS_LABEL[e]}
										onClick={i =>
											onUpdateAccess({
												id: item.id,
												key: e,
												value: i,
												allAccess: item.currentCompanyAccess
											})
										}
									/>
								))}
						</td>
					</tr>
				)}
			</Fragment>
		);
	}
}
