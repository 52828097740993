import { lazy } from "react";

// Reducers...
import cpUsersListReducer from "./reducers/cpUsersListReducer";
import cpUsersAddReducer from "./reducers/cpUsersAddReducer";
import cpUsersPreviewReducer from "./reducers/cpUsersPreviewReducer";

// Sagas...
import cpUsersListSaga from "./sagas/cpUsersListSaga";
import cpUsersAddSaga from "./sagas/cpUsersAddSaga";
import cpUsersPreviewSaga from "./sagas/cpUsersPreviewSaga";

export const getReducers = createReducer => ({
	usersList: cpUsersListReducer(createReducer),
	usersAdd: cpUsersAddReducer(createReducer),
	usersPreview: cpUsersPreviewReducer(createReducer)
});
export const getSagas = () => [cpUsersListSaga(), cpUsersAddSaga(), cpUsersPreviewSaga()];
export const getRoutes = () => ({
	cpUsersList: {
		exact: true,
		path: "/control-panel/users",
		component: lazy(() => import("./containers/CpUsersListContainer"))
	},
	cpUsersAdd: {
		exact: true,
		path: "/control-panel/users/add",
		component: lazy(() => import("./containers/CpUsersAddContainer"))
	},
	cpUsersPreview: {
		exact: true,
		path: "/control-panel/users/:userId",
		component: lazy(() => import("./containers/CpUsersPreviewContainer"))
	}
});
