import { all, takeEvery, call, put, select, debounce } from "redux-saga/effects";
import { notification } from "components/ui";
import { companyIdSelector } from "modules/account/selectors/accountSelector";
import * as companyDataContext from "common/dataProvider/company";

import * as action from "../actions/registriesPreviewAction";
import * as selector from "../selectors/registriesPreviewSelector";
import { escapeRegExp } from "../../../common/helpers/string";

export function* initRegistriesPreviewSaga({ payload }) {
	try {
		const { registryId } = payload;
		const companyId = yield select(companyIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const query = { size };

		const response = yield call(companyDataContext.getCompanyByIdRegistryByIdLines, {
			companyId,
			registryId,
			query
		});

		yield put(
			action.initRegistriesPreview.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.initRegistriesPreview.error());
		notification.httpError();
	}
}

export function* scrollRegistriesPreviewSaga({ payload }) {
	try {
		const { registryId } = payload;
		const companyId = yield select(companyIdSelector);
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const query = {
			size,
			page: page + 1,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined,
			by: by ? by : undefined,
			start: start ? start : undefined,
			end: end ? end : undefined
		};

		const response = yield call(companyDataContext.getCompanyByIdRegistryByIdLines, {
			companyId,
			registryId,
			query
		});

		yield put(
			action.scrollRegistriesPreview.success({
				items: response.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollRegistriesPreview.error());
		notification.httpError();
	}
}

export function* searchRegistriesPreviewSaga({ payload }) {
	try {
		const { registryId, value } = payload;
		const companyId = yield select(companyIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const query = {
			size,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined,
			by: by ? by : undefined,
			start: start ? start : undefined,
			end: end ? end : undefined
		};

		const response = yield call(companyDataContext.getCompanyByIdRegistryByIdLines, {
			companyId,
			registryId,
			query
		});

		yield put(
			action.searchRegistriesPreview.success({
				value,
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchRegistriesPreview.error());
		notification.httpError();
	}
}

export function* filterRegistriesPreviewSaga({ payload }) {
	try {
		const { registryId } = payload;
		const companyId = yield select(companyIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const query = {
			size,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined,
			by: by ? by : undefined,
			start: start ? start : undefined,
			end: end ? end : undefined
		};

		const response = yield call(companyDataContext.getCompanyByIdRegistryByIdLines, {
			companyId,
			registryId,
			query
		});

		yield put(
			action.filterRegistriesPreview.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.filterRegistriesPreview.error());
		notification.httpError();
	}
}

export function* showDetailRegistriesPreviewSaga({ payload }) {
	const { kkmId, fiscalDocumentId, isOpen } = payload;

	if (!isOpen) {
		return null;
	}

	try {
		const companyId = yield select(companyIdSelector);

		const response = yield call(companyDataContext.getCompanyKkmTransactionId, {
			companyId,
			kkmId,
			transactionId: fiscalDocumentId,
			global: true
		});

		yield put(
			action.showDetailRegistriesPreview.success({
				fiscalDocumentId,
				data: response
			})
		);
	} catch (err) {
		yield put(action.showDetailRegistriesPreview.error({ fiscalDocumentId }));
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initRegistriesPreview.request, initRegistriesPreviewSaga),
		takeEvery(action.scrollRegistriesPreview.request, scrollRegistriesPreviewSaga),
		debounce(400, action.searchRegistriesPreview.request, searchRegistriesPreviewSaga),
		debounce(400, action.filterRegistriesPreview.request, filterRegistriesPreviewSaga),
		takeEvery(action.showDetailRegistriesPreview.request, showDetailRegistriesPreviewSaga)
	]);
}
