// Reducers...
import accountReducer from "./reducers/accountReducer";
import settingsReducer from "./reducers/settingsReducer";

// Sagas...
import accountSaga from "./sagas/accountSaga";
import settingsSaga from "./sagas/settingsSaga";

// Containers...
import SettingsContainer from "./containers/SettingsContainer";

export const getReducers = createReducer => ({
	account: accountReducer(createReducer),
	settings: settingsReducer(createReducer)
});
export const getSagas = () => [accountSaga(), settingsSaga()];
export const getRoutes = () => ({
	accountSettings: {
		exact: true,
		path: "/settings",
		component: SettingsContainer
	}
});
