import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, NoItems, WrapperLayer, Popup } from "components/ui";

import UsersListComponent from "../components/UsersListComponent";

import * as action from "../actions/usersListAction";
import * as selectors from "../selectors/usersListSelector";

@withRouter
@connect(
	state => ({
		isLoading: selectors.isLoadingSelector(state),
		items: selectors.itemsSelector(state)
	}),
	{
		initUsersList: action.initUsersList.request,
		showInfoUsersList: action.showInfoUsersList,
		updateAccessUserList: action.updateAccessUserList.request,
		deleteUserUserList: action.deleteUserUserList.request
	}
)
export default class UsersListContainer extends Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		items: PropTypes.array,

		initUsersList: PropTypes.func,
		showInfoUsersList: PropTypes.func,
		updateAccessUserList: PropTypes.func,
		deleteUserUserList: PropTypes.func,
		history: PropTypes.shape({
			push: PropTypes.func
		})
	};

	constructor(props) {
		super(props);
		props.initUsersList();
	}

	handleDeleteUser = item => {
		const { deleteUserUserList } = this.props;
		this.popupDeleteUser.open().then(() => deleteUserUserList(item));
	};

	handleAdd = () => {
		const { history } = this.props;
		history.push("/users/add");
	};

	render() {
		const { isLoading, items, showInfoUsersList, updateAccessUserList } = this.props;

		return (
			<WrapperLayer
				title="Пользователи компании"
				breadcrumb={[{ to: "/users", label: "Пользователи компании" }]}
				isLoading={isLoading}
				titleRight={
					<Button size="small" onClick={this.handleAdd}>
						<i className="feather icon-plus p-r-10" />
						Добавить пользователя
					</Button>
				}
			>
				<NoItems isShow={!items.length} description="Нет пользователей" />

				{items.length > 0 && (
					<div className="row">
						<UsersListComponent
							items={items}
							onClick={showInfoUsersList}
							onUpdateAccess={updateAccessUserList}
							onDeleteUser={this.handleDeleteUser}
						/>
					</div>
				)}

				<Popup.Confirm
					ref={e => (this.popupDeleteUser = e)}
					title="Вы уверены что хотите удалить пользователя?"
				/>
			</WrapperLayer>
		);
	}
}
