import React, { Component } from "react";
import PropTypes from "prop-types";

import { UsersListItemComponent } from "./UsersListItemComponent";


export default class UsersListComponent extends Component {
	static propTypes = {
		items: PropTypes.array,
		onClick: PropTypes.func,
		onUpdateAccess: PropTypes.func,
		onDeleteUser: PropTypes.func,
	};

	render() {
		const { items, onClick, onUpdateAccess, onDeleteUser } = this.props;

		return (
			<div className="col-12">
				<div className="card">
					<div className="card-block p-0">
						<div className="table-responsive">
							<table className="table table-hover">
								<thead>
									<tr>
										<th>Фамилия Имя Отчество</th>
										<th>Телефон</th>
										<th>E-mail</th>
									</tr>
								</thead>
								<tbody>
									{items.map(item => (
										<UsersListItemComponent
											key={item.id}
											item={item}
											onClick={() => onClick(item)}
											onDeleteUser={() => onDeleteUser(item)}
											onUpdateAccess={onUpdateAccess}
										/>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
}