import { createSelector } from "reselect";

const cpRegistriesListSelector = state => state.cp.registriesList;

export const isLoadingSelector = createSelector(
	cpRegistriesListSelector,
	state => state.isLoading
);
export const isNoItemsSelector = createSelector(
	cpRegistriesListSelector,
	state => state.isNoItems
);
export const scrollSelector = createSelector(
	cpRegistriesListSelector,
	state => state.scroll
);
export const searchSelector = createSelector(
	cpRegistriesListSelector,
	state => state.search
);
export const itemsSelector = createSelector(
	cpRegistriesListSelector,
	state => state.items
);
