import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { WrapperLayer, NoItems, TableListComponent } from "components/ui";
import { childCompanySelector } from "modules/account/selectors/accountSelector";

import ItemComponent from "../components/companyList/ItemComponent";

@withRouter
@connect(state => ({
	items: childCompanySelector(state)
}))
export default class CompanyListContainer extends Component {
	static propTypes = {
		items: PropTypes.array
	};

	get headerTable() {
		return [
			{ key: "name", name: "Наименование" },
			{ key: "manager", name: "Руководитель" },
			{ key: "phone", name: "Телефон компании" },
			{ key: "email", name: "E-mail" }
		];
	}

	render() {
		const { items } = this.props;
		const isNoItems = items && items.length > 0;

		return (
			<WrapperLayer title="Дочерние компании" breadcrumb={[{ to: "/company", label: "Дочерние компании" }]}>
				<NoItems isShow={!isNoItems} description="У вас ещё нет ни одной компании" />
				<TableListComponent isShow={isNoItems} header={this.headerTable}>
					{items.map(item => (
						<ItemComponent key={item.id} item={item} />
					))}
				</TableListComponent>
			</WrapperLayer>
		);
	}
}
