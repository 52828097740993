import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as action from "modules/app/actions/smartAction";
import * as selector from "modules/app/selectors/smartSelector";

import AutoCompleteField from "../form/AutoCompleteField";

@connect(
	state => ({
		usersState: selector.userAutoCompleteSelector(state)
	}),
	{
		searchUserAutoComplete: action.searchUserAutoComplete.request
	}
)
export default class UserAutoComplete extends Component {
	static propTypes = {
		isNoAdmin: PropTypes.bool,
		usersState: PropTypes.shape({
			isLoading: PropTypes.bool,
			value: PropTypes.string,
			items: PropTypes.array
		}),
		value: PropTypes.string,
		searchUserAutoComplete: PropTypes.func
	};

	render() {
		const { usersState, searchUserAutoComplete, isNoAdmin, ...props } = this.props;

		return (
			<AutoCompleteField
				{...props}
				pickOut
				noDataMess="Поиск не дал результатов"
				dataSource={usersState.items}
				isLoading={usersState.isLoading}
				value={usersState.value}
				valueKey={e => e?.id}
				valueStr={e => e?.email}
				valueDesc={e => `${e?.phone}, ${e?.name}`}
				onSearch={value => searchUserAutoComplete({ value, isNoAdmin })}
			/>
		);
	}
}
