import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { WrapperLayer, Tabs } from "components/ui";

import SettingProfileForm from "../components/SettingProfileForm";
import SettingPasswordForm from "../components/SettingPasswordForm";

import * as action from "../actions/settingsAction";
import * as selector from "../selectors/settingsSelector";
import { userInfoSelector } from "../selectors/accountSelector";

@connect(
	state => ({
		isLoading: selector.isLoadingSelector(state),
		user: userInfoSelector(state)
	}),
	{
		sendProfileSettings: action.sendProfileSettings.request,
		sendPasswordSettings: action.sendPasswordSettings.request
	}
)
export default class SettingsContainer extends Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		sendProfileSettings: PropTypes.func,
		sendPasswordSettings: PropTypes.func,
		user: PropTypes.object
	};

	render() {
		const { isLoading, sendProfileSettings, sendPasswordSettings, user } = this.props;

		return (
			<WrapperLayer title="Настройки профиля" breadcrumb={[{ to: "/settings", label: "Настройки профиля" }]}>
				<Tabs defaultActiveKey="1">
					<Tabs.TabPane tab="Личные данные" key="1">
						<SettingProfileForm initialValues={user} onSubmit={sendProfileSettings} isLoading={isLoading} />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Сменить пароль" key="2">
						<SettingPasswordForm onSubmit={sendPasswordSettings} isLoading={isLoading} />
					</Tabs.TabPane>
				</Tabs>
			</WrapperLayer>
		);
	}
}
