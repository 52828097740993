import { createSelector } from "reselect";

const kkmGroupTransactionListSelector = state => state.kkmGroupTransactionList;

export const isLoadingSelector = createSelector(kkmGroupTransactionListSelector, state => state.isLoading);
export const isNoItemsSelector = createSelector(kkmGroupTransactionListSelector, state => state.isNoItems);
export const scrollSelector = createSelector(kkmGroupTransactionListSelector, state => state.scroll);
export const searchSelector = createSelector(kkmGroupTransactionListSelector, state => state.search);
export const filterSelector = createSelector(kkmGroupTransactionListSelector, state => state.filter);
export const sortSelector = createSelector(kkmGroupTransactionListSelector, state => state.sort);
export const itemsActionsSelector = createSelector(kkmGroupTransactionListSelector, state => state.itemsActions);
export const kkmGroupIdSelector = createSelector(kkmGroupTransactionListSelector, state => state.kkmGroupId);
export const groupSelector = createSelector(kkmGroupTransactionListSelector, state => state.group);
export const itemsSelector = createSelector(kkmGroupTransactionListSelector, state => state.items);
