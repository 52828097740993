import { createSelector } from "reselect";

const kkmGroupSettingSelector = state => state.kkmGroupSettings;

export const isLoadingSelector = createSelector(
	kkmGroupSettingSelector,
	state => state.isLoading
);
export const isLoadingSendSelector = createSelector(
	kkmGroupSettingSelector,
	state => state.isLoadingSend
);
export const formStateSendSelector = createSelector(
	kkmGroupSettingSelector,
	state => state.formState
);
export const groupStateSendSelector = createSelector(
	kkmGroupSettingSelector,
	state => state.groupState
);
export const searchKkmSelector = createSelector(
	kkmGroupSettingSelector,
	state => state.searchKkm
);
