// Reducers...
import kkmGroupListReducer from "./reducers/kkmGroupListReducer";
import kkmGroupAddReducer from "./reducers/kkmGroupAddReducer";
import kkmGroupSettingsReducer from "./reducers/kkmGroupSettingsReducer";
import kkmGroupTransactionListReducer from "./reducers/kkmGroupTransactionListReducer";

// Sagas...
import kkmGroupListSaga from "./sagas/kkmGroupListSaga";
import kkmGroupAddSaga from "./sagas/kkmGroupAddSaga";
import kkmGroupSettingsSaga from "./sagas/kkmGroupSettingsSaga";
import kkmGroupTransactionListSaga from "./sagas/kkmGroupTransactionListSaga";

// Containers...
import KkmGroupListContainer from "./containers/KkmGroupListContainer";
import KkmGroupAddContainer from "./containers/KkmGroupAddContainer";
import KkmGroupSettingsContainer from "./containers/KkmGroupSettingsContainer";
import KkmGroupTransactionListContainer from "./containers/KkmGroupTransactionListContainer";

export const getReducers = createReducer => ({
	kkmGroupList: kkmGroupListReducer(createReducer),
	kkmGroupAdd: kkmGroupAddReducer(createReducer),
	kkmGroupSettings: kkmGroupSettingsReducer(createReducer),
	kkmGroupTransactionList: kkmGroupTransactionListReducer(createReducer)
});
export const getSagas = () => [
	kkmGroupListSaga(),
	kkmGroupAddSaga(),
	kkmGroupSettingsSaga(),
	kkmGroupTransactionListSaga()
];
export const getRoutes = () => ({
	kkmGroup: {
		exact: true,
		path: "/kkm-group",
		component: KkmGroupListContainer
	},
	kkmGroupAdd: {
		exact: true,
		path: "/kkm-group/add",
		component: KkmGroupAddContainer
	},
	transactionListGroup: {
		exact: true,
		path: "/kkm-group/:kkmGroupId",
		component: KkmGroupTransactionListContainer
	},
	kkmGroupSetting: {
		exact: true,
		path: "/kkm-group/:kkmGroupId/settings",
		component: KkmGroupSettingsContainer
	}
});
