import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/cpUsersAddAction";

const initialState = {
	isLoading: true,
	isLoadingSend: false,

	formState: {},

	userTypes: []
};
const actionHandlers = {
	// initialize
	[action.initCpUsersAdd.request]: createMutateReducer(draft => {
		draft.isLoading = true;
	}),
	[action.initCpUsersAdd.success]: createMutateReducer((draft, { formState, userTypes }) => {
		draft.isLoading = false;
		draft.formState = formState;
		draft.userTypes = userTypes;
	}),
	[action.initCpUsersAdd.error]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),

	// send form
	[action.sendCpUsersAdd.request]: createMutateReducer(draft => {
		draft.isLoadingSend = true;
	}),
	[action.sendCpUsersAdd.success]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),
	[action.sendCpUsersAdd.error]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
