import { createSelector } from "reselect";

export const transactionListSelector = state => state.transactionList;

export const isLoadingSelector = createSelector(transactionListSelector, state => state.isLoading);
export const isNoItemsSelector = createSelector(transactionListSelector, state => state.isNoItems);
export const scrollSelector = createSelector(transactionListSelector, state => state.scroll);
export const searchSelector = createSelector(transactionListSelector, state => state.search);
export const filterSelector = createSelector(transactionListSelector, state => state.filter);
export const sortSelector = createSelector(transactionListSelector, state => state.sort);
export const itemsActionsSelector = createSelector(transactionListSelector, state => state.itemsActions);
export const kkmIdSelector = createSelector(transactionListSelector, state => state.kkmId);
export const companySelector = createSelector(transactionListSelector, state => state.company);
export const itemsSelector = createSelector(transactionListSelector, state => state.items);
export const kkmSelector = createSelector(transactionListSelector, state => state.kkm);
