import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import reduxForm, { CheckboxField, Form, InputField, PhoneField, SwitchField } from "components/form";
import { UserAutoComplete } from "components/smart";
import { Button } from "components/ui";
import { randString } from "common/helpers/crypt";
import { validateEmail as vEmail, validateRequired } from "common/validators";
import { COMPANY_ACCESS, COMPANY_ACCESS_LABEL } from "../enums";

const formName = "userAdd";
const selectorForm = formValueSelector(formName);
const validateEmail = value => (vEmail(value) ? undefined : "Электронная почта не верна");

@reduxForm({ form: formName })
@connect(state => ({
	isSearchUser: !selectorForm(state, "searchUser")
}))
export default class UsersAddFrom extends Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		isSearchUser: PropTypes.bool,

		handleSubmit: PropTypes.func,
		onSubmitForm: PropTypes.func,
		change: PropTypes.func
	};

	handleGeneratePassword = () => {
		const { change } = this.props;
		change("userData.password", randString());
	};

	render() {
		const { isLoading, handleSubmit, onSubmitForm, isSearchUser } = this.props;

		return (
			<Form onSubmit={handleSubmit(onSubmitForm)}>
				<div className="card">
					<div className="card-body">
						<CheckboxField name="searchUser">Пользователь существует</CheckboxField>

						{isSearchUser ? (
							<div className="p-t-15 p-b-15">
								<div className="row">
									<div className="col-md-4">
										<InputField
											name="userData.firstName"
											label="Фамилия"
											validate={[validateRequired]}
										/>
									</div>
									<div className="col-md-4">
										<InputField
											name="userData.lastName"
											label="Имя"
											validate={[validateRequired]}
										/>
									</div>
									<div className="col-md-4">
										<InputField name="userData.middleName" label="Отчество" />
									</div>
								</div>
								<div className="row">
									<div className="col-md-4">
										<InputField
											name="userData.email"
											label="Электронная почта"
											validate={[validateRequired, validateEmail]}
										/>
									</div>
									<div className="col-md-4">
										<PhoneField
											name="userData.phone"
											label="Мобильный телефон"
											validate={[validateRequired]}
										/>
									</div>
									<div className="col-md-4">
										<InputField
											name="userData.password"
											label="Пароль"
											validate={[validateRequired]}
											addonAfter={
												<i
													className="feather icon-refresh-cw cursor-pointer"
													onClick={this.handleGeneratePassword}
												/>
											}
										/>
									</div>
								</div>
							</div>
						) : (
							<div className="p-t-15 p-b-15">
								<UserAutoComplete
									isNoAdmin
									name="userId"
									label="Электронная почта"
									validate={[validateRequired]}
								/>
							</div>
						)}

						<div className="p-b-15">
							{Object.values(COMPANY_ACCESS).map(e => (
								<SwitchField key={e} name={`accessRights.${e}`} swLabel={COMPANY_ACCESS_LABEL[e]} />
							))}
						</div>

						<Button htmlType="submit" type="primary" loading={isLoading}>
							Добавить пользователя
						</Button>
					</div>
				</div>
			</Form>
		);
	}
}
