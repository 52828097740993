import { createSelector } from "reselect";

const cpUsersListSelector = state => state.cp.usersList;

export const isLoadingSelector = createSelector(
	cpUsersListSelector,
	state => state.isLoading
);
export const isNoItemsSelector = createSelector(
	cpUsersListSelector,
	state => state.isNoItems
);
export const scrollSelector = createSelector(
	cpUsersListSelector,
	state => state.scroll
);
export const searchSelector = createSelector(
	cpUsersListSelector,
	state => state.search
);
export const itemsSelector = createSelector(
	cpUsersListSelector,
	state => state.items
);
