import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/transactionAddAction";

const initialState = {
	isLoading: true,
	isError: false,

	isUpdKeyLoading: false,
	isSendFormLoading: false,

	kkmId: null,
	company: null,
	formState: {}
};
const actionHandlers = {
	// Initialize transaction add
	[action.initTransactionAdd.request]: createMutateReducer((draft, { kkmId }) => {
		draft.isLoading = true;
		draft.isError = false;
		draft.kkmId = kkmId;
	}),
	[action.initTransactionAdd.success]: createMutateReducer((draft, { company, formState }) => {
		draft.isLoading = false;
		draft.company = company;
		draft.formState = formState;
	}),
	[action.initTransactionAdd.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isError = true;
	}),

	// Send form
	[action.sendFormTransactionAdd.request]: createMutateReducer(draft => {
		draft.isLoadingSend = true;
	}),
	[action.sendFormTransactionAdd.success]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),
	[action.sendFormTransactionAdd.error]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
