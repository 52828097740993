// Reducers...
import companyListReducer from "./reducers/companyListReducer";

// Sagas...
import companyListSaga from "./saga/companyListSaga";

// Containers...
import CompanyListContainer from "./containers/CompanyListContainer";

export const getReducers = createReducer => ({
	companyList: companyListReducer(createReducer)
});
export const getSagas = () => [companyListSaga()];
export const getRoutes = () => ({
	kkmGroup: {
		exact: true,
		path: "/company",
		component: CompanyListContainer
	}
});
