import { createMutateReducer } from "common/helpers/reducer";
import { setAppReady, selectCompany } from "modules/app/actions/appAction";

import * as action from "../actions/accountAction";

const initialState = {
	currentCompanyId: "",
	userRoles: null,

	userInfo: {},
	company: [],
	childCompany: []
};
const actionHandlers = {
	[setAppReady]: createMutateReducer((draft, { account, childCompany }, state) => {
		draft.currentCompanyId = account?.currentCompanyId || state.currentCompanyId;
		draft.userRoles = account?.userRoles || state.userRoles;
		draft.userInfo = account;
		draft.childCompany = childCompany;
	}),

	// Получаем инфу о компании пользователя
	[action.setUserCompany]: createMutateReducer((draft, { company }) => {
		draft.company = company;
	}),

	// Изменить текущую компанию
	[action.changeCompany.request]: createMutateReducer(() => {
		// ничего не делаем... вдруг кто то подписан на это событие
	}),
	[action.changeCompany.success]: createMutateReducer((draft, { companyId }) => {
		// компания успешно сменилась
		draft.currentCompanyId = companyId;
	}),

	// Выбрана новая компания
	[selectCompany.success]: createMutateReducer((draft, { currentCompanyId }) => {
		draft.currentCompanyId = currentCompanyId;
		draft.userInfo.currentCompanyId = currentCompanyId;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
