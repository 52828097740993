import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { AmountFormat, Button, TransactionStatus, message } from "components/ui";
import { STATUS, STATUS_HISTORY_LABEL } from "../../../../common/enums/kkm";
import { TYPE, TYPE_LABEL } from "../../enums";
import { copyToBuffer, getDateFormat, getString } from "../../../../common/helpers/string";

export default class ItemComponent extends Component {
	static propTypes = {
		actions: PropTypes.object,
		item: PropTypes.object,
		onClickItem: PropTypes.func,
		onCreateReturn: PropTypes.func
	};

	getType = type => {
		switch (type) {
			case TYPE.SALE:
				return <span className="badge badge-success">{TYPE_LABEL.SALE}</span>;
			case TYPE.SALE_RETURN:
				return <span className="badge badge-danger">{TYPE_LABEL.SALE_RETURN}</span>;
			case TYPE.BUY:
				return <span className="badge badge-primary">{TYPE_LABEL.BUY}</span>;
			case TYPE.BUY_RETURN:
				return <span className="badge badge-warning">{TYPE_LABEL.BUY_RETURN}</span>;
			default:
				return getString();
		}
	};

	getHistory = status => {
		switch (status) {
			case STATUS.QUEUED:
				return STATUS_HISTORY_LABEL.QUEUED;
			case STATUS.PENDING:
				return STATUS_HISTORY_LABEL.PENDING;
			case STATUS.COMPLETED:
				return STATUS_HISTORY_LABEL.COMPLETED;
			case STATUS.FAILED:
				return STATUS_HISTORY_LABEL.FAILED;
			default:
				return getString();
		}
	};

	onClick = () => {
		const { item, onClickItem } = this.props;
		onClickItem && onClickItem(item);
	};

	onCheque = e => {
		e?.stopPropagation();
		e?.preventDefault();

		const { item } = this.props;

		window.open(item.ofdLink);
	};

	onCreateReturn = e => {
		e?.stopPropagation();
		e?.preventDefault();

		const { item, onCreateReturn } = this.props;

		onCreateReturn(item.requestId);
	};

	onCopy = e => {
		e?.stopPropagation();
		e?.preventDefault();

		const { item } = this.props;

		if (copyToBuffer(item.requestId, e.target)) {
			message.success("Скопированно в буфер обмена");
		}
	};

	renderPositions = () => {
		const { item } = this.props;

		return (
			<div className="col-8">
				<div className="p-b-15">
					<span className="f-14 p-r-10">
						TransactionId <i className="p-l-5 feather icon-copy" />
					</span>
					<a href="" className="f-14 cursor-pointer" onClick={this.onCopy}>
						{getString(item.requestId)}
					</a>
				</div>

				<table className="table table-hover">
					<thead>
						<tr>
							<th>Наименование позиции</th>
							<th>Кол-во</th>
							<th>Цена</th>
						</tr>
					</thead>
					<tbody>
						{(item.positions || []).map(position => (
							<tr key={position.name + position.qty + position.price}>
								<td>{getString(position.name)}</td>
								<td>
									<AmountFormat value={position.qty} precision={3} currency={null} />
								</td>
								<td>
									<AmountFormat value={position.price} />
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<Button className="m-r-5" onClick={this.onCheque} disabled={!item.ofdLink}>
					<i className="feather icon-external-link p-r-10" />
					Перейти к просмотру чека
				</Button>
				{[TYPE.SALE, TYPE.BUY].includes(item.type) && (
					<Button onClick={this.onCreateReturn}>
						<i className="feather icon-plus p-r-10" />
						Создать чек возврата
					</Button>
				)}
			</div>
		);
	};

	renderStatuses = () => {
		const { item } = this.props;

		return (
			<div className="col-4">
				<div className="p-b-15">
					<span className="f-14 p-r-10">Статусы:</span>
				</div>
				<ul className="task-list" style={{ paddingTop: 0 }}>
					{(item.statuses || []).map(status => (
						<li key={status.status + status.statusDateTime}>
							<i className="task-icon bg-c-green" />
							<span>{this.getHistory(status.status)}</span>
							<p className="text-muted">{getDateFormat(status.statusDateTime, true)}</p>
						</li>
					))}
				</ul>
			</div>
		);
	};

	renderDetailTransaction = () => {
		return (
			<tr className="table_item_open">
				<td className="full" colSpan="12">
					<div className="row">
						{this.renderPositions()}
						{this.renderStatuses()}
					</div>
				</td>
			</tr>
		);
	};

	render() {
		const { actions, item } = this.props;

		return (
			<Fragment>
				<tr className={cn("cursor-pointer", { _open: actions.isOpen })} onClick={this.onClick}>
					<td>{item.requestId}</td>
					<td>{getString(item.customerAccount)}</td>
					<td>{getString(item.bic)}</td>
					<td>{getString(item.customerName)}</td>
					<td>{this.getType(item.type)}</td>
					<td className="text-right">
						<AmountFormat value={item.totalSum} />
					</td>
					<td>
						<TransactionStatus value={item.lastStatus} />
					</td>
					<td className="text-center">{getString(item.fiscalDocNum)}</td>
					<td className="text-center">
						<Button
							className="p-r-10 p-l-10"
							style={{ margin: "-19px 0" }}
							onClick={this.onCheque}
							disabled={!item.ofdLink}
						>
							<i className="feather icon-external-link" />
						</Button>
					</td>
					<td>{getDateFormat(item.checkOutDate)}</td>
					<td>{getDateFormat(item.transactionCreated, true)}</td>
					<td>{getDateFormat(item.transactionCompleted, true)}</td>
				</tr>

				{actions.isOpen && this.renderDetailTransaction()}
			</Fragment>
		);
	}
}
