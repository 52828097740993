import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/cpCompanyListAction";

const initialState = {
	isLoading: true,
	isNoItems: false,

	scroll: {
		isLoading: false,
		size: 20,
		page: 0,
		totalElements: 0
	},
	search: {
		isLoading: false,
		value: ""
	},

	items: []
};
const actionHandlers = {
	// initialize
	[action.initCpCompanyList.request]: createMutateReducer(draft => {
		draft.isLoading = true;
	}),
	[action.initCpCompanyList.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.isLoading = false;
		draft.isNoItems = !items.length;
		draft.scroll.page = 0;
		draft.scroll.totalElements = totalElements;
		draft.items = items;
	}),
	[action.initCpCompanyList.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isNoItems = true;
	}),

	// infinite scroll
	[action.scrollCpCompanyList.request]: createMutateReducer(draft => {
		draft.scroll.isLoading = true;
	}),
	[action.scrollCpCompanyList.success]: (state, { items, page }) => ({
		...state,
		scroll: {
			...state.scroll,
			isLoading: false,
			page
		},
		items: [...state.items, ...items]
	}),
	[action.scrollCpCompanyList.error]: createMutateReducer(draft => {
		draft.scroll.isLoading = false;
	}),

	// search
	[action.searchCpCompanyList.request]: createMutateReducer((draft, { value }) => {
		draft.search.isLoading = true;
		draft.search.isNoItems = false;
		draft.search.value = value;
	}),
	[action.searchCpCompanyList.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.search.isLoading = false;
		draft.search.isNoItems = !items.length;
		draft.scroll.totalElements = totalElements;
		draft.scroll.page = 0;
		draft.items = items;
	}),
	[action.searchCpCompanyList.error]: createMutateReducer(draft => {
		draft.search.isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
