import { createSelector } from "reselect";

const usersAddSelector = state => state.usersAdd;

export const isLoadingSelector = createSelector(
	usersAddSelector,
	state => state.isLoading
);
export const isLoadingSendSelector = createSelector(
	usersAddSelector,
	state => state.isLoading
);
export const companySelector = createSelector(
	usersAddSelector,
	state => state.company
);
export const formStateSelector = createSelector(
	usersAddSelector,
	state => state.formState
);