import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/cpCompanyAddAction";

const initialState = {
	isLoading: true,
	isLoadingSend: false,
	isNoItems: false,

	userTypes: [],

	scroll: {
		isLoading: false,
		size: 100,
		page: 0,
		totalElements: 0
	},
	search: {
		isLoading: false,
		value: ""
	},

	items: [],

	parentCompany: {
		isLoading: false,
		companies: []
	},

	// from DaData
	searchCompany: {
		isLoading: false,
		companies: []
	}
};
const actionHandlers = {
	// initialize
	[action.initCpCompanyAdd.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.searchCompany.companies = [];
	}),
	[action.initCpCompanyAdd.success]: createMutateReducer((draft, { userTypes }) => {
		draft.isLoading = false;
		draft.userTypes = userTypes;
	}),
	[action.initCpCompanyAdd.error]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),

	// send form
	[action.sendCpCompanyAdd.request]: createMutateReducer(draft => {
		draft.isLoadingSend = true;
	}),
	[action.sendCpCompanyAdd.success]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),
	[action.sendCpCompanyAdd.error]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),

	// search company
	[action.searchCompanyCpCompanyAdd.request]: createMutateReducer((draft, { value }) => {
		draft.searchCompany.isLoading = true;
		draft.searchCompany.value = value;
	}),
	[action.searchCompanyCpCompanyAdd.success]: createMutateReducer((draft, { companies }) => {
		draft.searchCompany.isLoading = false;
		draft.searchCompany.companies = companies;
	}),
	[action.searchCompanyCpCompanyAdd.error]: createMutateReducer(draft => {
		draft.searchCompany.isLoading = false;
		draft.searchCompany.companies = [];
	}),

	// Search parent company
	[action.searchPCompanyCpCompanyAdd.request]: createMutateReducer(draft => {
		draft.parentCompany.isLoading = true;
	}),
	[action.searchPCompanyCpCompanyAdd.success]: createMutateReducer((draft, { companies }) => {
		draft.parentCompany.isLoading = false;
		draft.parentCompany.companies = companies;
	}),
	[action.searchPCompanyCpCompanyAdd.error]: createMutateReducer(draft => {
		draft.parentCompany.isLoading = false;
		draft.parentCompany.companies = [];
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
