import axios from "axios";

export const daDataParty = ({ value, ...params }) => {
	// Docs: https://confluence.hflabs.ru/pages/viewpage.action?pageId=204669122
	return axios({
		method: "post",
		url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party",
		headers: {
			"Content-Type": "application/json;charset=UTF-8",
			Accept: "application/json",
			Authorization: `Token ${DADATA_KEY}`
		},
		data: {
			...params,
			query: value
		}
	}).then(res => res.data);
};
