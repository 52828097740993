/* eslint-disable react/jsx-filename-extension */

import React from "react";
import { Redirect } from "react-router";
import { combineReducers } from "redux";

// Modules...
import * as company from "./companies";
import * as kkm from "./kkm";
import * as registries from "./registries";
import * as users from "./users";
import * as offers from "./offers";

export const getReducers = createReducer => ({
	cp: combineReducers({
		...company.getReducers(createReducer),
		...kkm.getReducers(createReducer),
		...registries.getReducers(createReducer),
		...users.getReducers(createReducer),
		...offers.getReducers(createReducer)
	})
});
export const getSagas = () => [
	...company.getSagas(),
	...kkm.getSagas(),
	...registries.getSagas(),
	...users.getSagas(),
	...offers.getSagas()
];
export const getRoutes = () => ({
	cp: {
		path: "/control-panel",
		exact: true,
		component: () => <Redirect to="/control-panel/company" />
	},
	...company.getRoutes(),
	...kkm.getRoutes(),
	...registries.getRoutes(),
	...users.getRoutes(),
	...offers.getRoutes()
});
