import React, { Component } from "react";
import PropTypes from "prop-types";
import reduxForm, { Form, InputField, PhoneField } from "components/form";
import { Button } from "components/ui";
import { validateRequired } from "../../../common/validators";

@reduxForm({ form: "settingsProfile" })
export default class SettingProfileForm extends Component {
	static propTypes = {
		handleSubmit: PropTypes.func,
		onSubmit: PropTypes.func,
		isLoading: PropTypes.bool
	};

	render() {
		const { handleSubmit, onSubmit, isLoading } = this.props;

		return (
			<div className="card">
				<div className="card-body">
					<Form onSubmit={handleSubmit(onSubmit)}>
						<div className="justify-content-md-center row">
							<div className="col-md-9 col-sm-12">
								<div className="row">
									<div className="col-4">
										<InputField name="lastName" label="Фамилия" />
									</div>
									<div className="col-4">
										<InputField name="firstName" label="Имя" />
									</div>
									<div className="col-4">
										<InputField name="middleName" label="Отчество" />
									</div>
								</div>
								<div className="row">
									<div className="col-6">
										<InputField
											name="email"
											label="Электронная почта"
											validate={[validateRequired]}
										/>
									</div>
									<div className="col-6">
										<PhoneField name="phone" label="Мобильный телефон" />
									</div>
								</div>

								<Button htmlType="submit" type="primary" loading={isLoading}>
									Сохранить изменения
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</div>
		);
	}
}
