import moment from "moment";
import uuidv4 from "uuid/v4";
import { all, takeEvery, call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { isEmpty } from "common/validators";
import { message, notification } from "components/ui";
import { companyIdSelector } from "modules/account/selectors/accountSelector";
import * as companyDataContext from "common/dataProvider/company";
import * as kkmApiDataContext from "common/dataProvider/kkmApi";
import { STATUS_LABEL, METHOD } from "common/enums/kkm";

import { SEND_CHECK, TYPE } from "../enums";
import * as action from "../actions/transactionAddAction";

function* __getTransactionById({ companyId, kkmId, transactionId }) {
	if (!transactionId) {
		return undefined;
	}

	try {
		return yield call(companyDataContext.getCompanyKkmTransactionId, { companyId, kkmId, transactionId });
	} catch (e) {
		notification.error("Ошибка", "Не удалось найти транзакцию, попробуйте повторить позднее");
		return undefined;
	}
}

export function* initTransactionAddSaga({ payload }) {
	const { kkmId, return: searchReturn } = payload;

	try {
		const companyId = yield select(companyIdSelector);
		const params = {
			companyId: companyId,
			kkmId: kkmId,
			transactionId: searchReturn
		};
		const { company, transaction } = yield all({
			company: call(companyDataContext.getCompanyKkmId, params),
			transaction: call(__getTransactionById, params)
		});

		const formState = {
			kkmId,
			apiKey: company.apiKey,

			method: METHOD.INCOME,
			transactionId: uuidv4(),

			params: {
				Cashier: {
					Name: "",
					Inn: ""
				},
				Persona: {
					Account: "",
					Name: "",
					Email: "",
					Phone: ""
				},

				SendCheck: SEND_CHECK.NONE,
				DatePayment: moment(),
				DocItems: [
					{
						Price: 0,
						Qty: 1,
						PaymentItem: "1",
						PaymentType: "4",
						Tax: "1"
					}
				],
				SumTypePayment: "5",

				// чек коррекции
				CalculationSign: "",
				CorrectionDate: moment(),
				CorrectionDocNum: "",
				CorrectionType: "",
				TaxType: "",
				CorrectionReason: "",
				Sum1: 0,
				Sum2: 0,
				Sum3: 0,
				Sum4: 0,
				Sum5: 0,
				Sum6: 0,
				Sum7: 0,
				Sum8: 0,
				Sum9: 0,
				Sum10: 0,
				Sum11: 0,
				Sum12: 0
			}
		};

		// Создадим чек возврата
		if (transaction && [TYPE.SALE, TYPE.BUY].includes(transaction.type)) {
			switch (transaction.type) {
				case TYPE.SALE:
					formState.method = METHOD.INCOME_RETURN;
					break;
				case TYPE.BUY:
					formState.method = METHOD.OUTCOME_RETURN;
					break;
			}

			formState.params.DatePayment = moment(transaction.checkOutDate);

			formState.params.Cashier.Name = transaction.cashierName || "";
			formState.params.Cashier.Inn = transaction.cashierInn || "";

			formState.params.Persona.Account = transaction.customerAccount;
			formState.params.Persona.Name = transaction.customerName;
			formState.params.SendCheck =
				(isEmpty(transaction.customerEmail) ? "" : SEND_CHECK.EMAIL) ||
				(isEmpty(transaction.customerPhone) ? "" : SEND_CHECK.PHONE) ||
				SEND_CHECK.NONE;
			formState.params.Persona.Email = transaction.customerEmail || "";
			formState.params.Persona.Phone = ("" + transaction.customerPhone || "")
				.replace("+7", "")
				.replace(/[^0-9]+/g, "")
				.substring(0, 10);

			formState.params.SumTypePayment = String(transaction.paymentType);
			formState.params.DocItems = transaction.positions.map(item => ({
				Description: item.name,
				Price: item.price,
				Qty: item.qty,
				PaymentItem: "" + item.paymentObject,
				PaymentType: "" + item.paymentType,
				Tax: "" + item?.taxMode
			}));
		}

		yield put(
			action.initTransactionAdd.success({
				company,
				formState
			})
		);
	} catch (e) {
		notification.error("Ошибка", e?.response?.error || "Попробуйте повторить позднее");
		yield put(action.initTransactionAdd.error());
	}
}

export function* sendFormTransactionAddSaga({ payload }) {
	const { kkmId, apiKey, method, params, transactionId, ...data } = payload;
	try {
		// prettier-ignore
		const query =
			method === "status"
				? {
					method,
					transactionId
				}
				: {
					...data,
					transactionId,
					method,
					params
				};

		const response = yield call(kkmApiDataContext.postApi, {
			header: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
				Accept: "application/json"
			},
			query
		});

		yield put(action.sendFormTransactionAdd.success());

		if (method === "status") {
			notification.success("Стату транзакции", STATUS_LABEL[response.status] || response.status);

			return null;
		}

		yield put(push(`/kkm/${kkmId}`));

		message.success("Транзакция успешно добавлена");
	} catch (e) {
		yield put(action.sendFormTransactionAdd.error());

		if (e?.response?.status === 404 && method === "status") {
			message.error("Транзакция не найдена");
			return null;
		}

		message.error("При добавлении транзакции произошла ошибка");

		if (e?.response?.error) {
			notification.success("Ошибка", e?.response?.error);
		}
	}
}

export default function*() {
	yield all([
		takeEvery(action.initTransactionAdd.request, initTransactionAddSaga),
		takeEvery(action.sendFormTransactionAdd.request, sendFormTransactionAddSaga)
	]);
}
