// Reducers...
import usersListReducer from "./reducers/usersListReducer";
import usersAddReducer from "./reducers/usersAddReducer";

// Sagas...
import usersListSaga from "./sagas/usersListSaga";
import usersAddSaga from "./sagas/usersAddSaga";

// Containers...
import UsersListContainer from "./containers/UsersListContainer";
import UsersAddContainer from "./containers/UsersAddContainer";

export const getReducers = createReducer => ({
	usersList: usersListReducer(createReducer),
	usersAdd: usersAddReducer(createReducer)
});
export const getSagas = () => [usersListSaga(), usersAddSaga()];
export const getRoutes = () => ({
	usersList: {
		exact: true,
		path: "/users",
		component: UsersListContainer
	},
	usersAdd: {
		exact: true,
		path: "/users/add",
		component: UsersAddContainer
	}
});
