/* eslint-disable */

import React from "react";
import { Redirect } from "react-router";

export const getRoutes = () => ({
	dashboard: {
		exact: true,
		path: "/",
		component: () => <Redirect to="/kkm" />
	}
});
