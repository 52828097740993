import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/usersAddAction";

const initialState = {
	isLoading: true,
	isLoadingSend: false,
	formState: {}
};
const actionHandlers = {
	// Initialize
	[action.initUsersAdd.request]: createMutateReducer(draft => {
		draft.isLoading = true;
	}),
	[action.initUsersAdd.success]: createMutateReducer((draft, { formState }) => {
		draft.isLoading = false;
		draft.formState = formState;
	}),
	[action.initUsersAdd.error]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),

	// Send add user
	[action.sendUsersAdd.request]: createMutateReducer(draft => {
		draft.isLoadingSend = true;
	}),
	[action.sendUsersAdd.success]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),
	[action.sendUsersAdd.error]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
