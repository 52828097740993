import React, { Component } from "react";
import PropTypes from "prop-types";
import uuidv4 from "uuid/v4";
import reduxForm, { Form, InputField } from "components/form";
import { Button } from "components/ui";
import { validateRequired } from "common/validators";

@reduxForm({ form: "kkmGroupSetting" })
export default class SettingsForm extends Component {
	static propTypes = {
		handleSubmit: PropTypes.func,
		change: PropTypes.func,
		isLoading: PropTypes.bool,
		onSubmitForm: PropTypes.func.isRequired
	};

	handleGenerateApiKey = () => {
		const { change } = this.props;
		change("apiKey", uuidv4());
	};

	render() {
		const { handleSubmit, isLoading, onSubmitForm } = this.props;

		return (
			<div className="card">
				<div className="card-body">
					<Form onSubmit={handleSubmit(onSubmitForm)}>
						<div className="justify-content-md-center row">
							<div className="col-xl-8 col-lg-12">
								<InputField
									name="name"
									label="Название"
									placeholder="Введите название группы"
									validate={[validateRequired]}
									autoFocus
								/>

								<div className="p-t-25">
									<Button htmlType="submit" type="primary" className="m-r-15" loading={isLoading}>
										Отправить
									</Button>
								</div>
							</div>
						</div>
					</Form>
				</div>
			</div>
		);
	}
}
