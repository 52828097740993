import { all, takeEvery, call, put, select, debounce } from "redux-saga/effects";
import { notification } from "components/ui";
import * as controlDataProvider from "common/dataProvider/control";

import * as action from "../actions/cpRegistriesListAction";
import * as selector from "../selectors/cpRegistriesListSelector";
import { escapeRegExp } from "../../../../common/helpers/string";

export function* initRegistriesKkmListSaga() {
	try {
		const { size } = yield select(selector.scrollSelector);
		const query = { size };

		const response = yield call(controlDataProvider.getControlRegistry, { query });

		yield put(
			action.initRegistriesKkmList.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.initRegistriesKkmList.error());
		notification.httpError();
	}
}

export function* scrollRegistriesKkmListSaga() {
	try {
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const query = {
			size,
			page: page + 1,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined
		};

		const response = yield call(controlDataProvider.getControlRegistry, { query });

		yield put(
			action.scrollRegistriesKkmList.success({
				items: response.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollRegistriesKkmList.error());
		notification.httpError();
	}
}

export function* searchRegistriesKkmListSaga({ payload }) {
	try {
		const { value } = payload;
		const { size } = yield select(selector.scrollSelector);
		const query = {
			size,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined
		};

		const response = yield call(controlDataProvider.getControlRegistry, { query });

		yield put(
			action.searchRegistriesKkmList.success({
				value,
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchRegistriesKkmList.error());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initRegistriesKkmList.request, initRegistriesKkmListSaga),
		takeEvery(action.scrollRegistriesKkmList.request, scrollRegistriesKkmListSaga),

		debounce(400, action.searchRegistriesKkmList.request, searchRegistriesKkmListSaga)
	]);
}
