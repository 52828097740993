// Reducers...
import registriesListReducer from "./reducers/registriesListReducer";
import registriesPreviewReducer from "./reducers/registriesPreviewReducer";

// Sagas...
import registriesListSaga from "./sagas/registriesListSaga";
import registriesPreviewSaga from "./sagas/registriesPreviewSaga";

// Containers...
import RegistriesListContainer from "./containers/RegistriesListContainer";
import RegistriesPreviewContainer from "./containers/RegistriesPreviewContainer";

export const getReducers = createReducer => ({
	registriesList: registriesListReducer(createReducer),
	registriesPreview: registriesPreviewReducer(createReducer)
});
export const getSagas = () => [registriesListSaga(), registriesPreviewSaga()];
export const getRoutes = () => ({
	registriesList: {
		exact: true,
		path: "/registries",
		component: RegistriesListContainer
	},
	registriesPreview: {
		exact: true,
		path: "/registries/:registryId",
		component: RegistriesPreviewContainer
	}
});
