import { all, takeEvery, call, put, debounce } from "redux-saga/effects";
import { push } from "connected-react-router";
import { message, notification } from "components/ui";
import * as controlDataContext from "common/dataProvider/control";
import * as externalServicesDataContext from "common/dataProvider/externalServices";

import * as action from "../actions/cpCompanyAddAction";
import { escapeRegExp } from "../../../../common/helpers/string";

export function* initCpCompanyAddSaga() {
	try {
		const userTypes = yield call(controlDataContext.getControlUserType);
		yield put(action.initCpCompanyAdd.success({ userTypes }));
	} catch (e) {
		yield put(action.initCpCompanyAdd.error());
	}
}

export function* sendCpCompanyAddSaga({ payload }) {
	try {
		yield call(controlDataContext.postControlCompany, { query: payload });
		yield put(action.sendCpCompanyAdd.success());

		message.success("Компания успешно добавлена");
		yield put(push("/control-panel/company"));
	} catch (e) {
		yield put(action.sendCpCompanyAdd.error());

		if (e?.response?.status === 409) {
			notification.error("Ошибка", "Пользователь с указанным e-mail уже существует");
			return null;
		}

		notification.httpError();
	}
}

export function* searchCompanyCpCompanyAddSaga({ payload }) {
	try {
		const { value } = payload;
		const { suggestions } = yield call(externalServicesDataContext.daDataParty, { value });

		yield put(action.searchCompanyCpCompanyAdd.success({ companies: suggestions }));
	} catch (e) {
		yield put(action.searchCompanyCpCompanyAdd.error());
	}
}

export function* searchPCompanyCpCompanyAddSaga({ payload }) {
	try {
		const { value } = payload;
		const query = { size: 10, q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined };

		const { content } = yield call(controlDataContext.getControlCompany, { query });

		yield put(
			action.searchPCompanyCpCompanyAdd.success({
				companies: content
			})
		);
	} catch (e) {
		yield put(action.searchPCompanyCpCompanyAdd.error());
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpCompanyAdd.request, initCpCompanyAddSaga),
		takeEvery(action.sendCpCompanyAdd.request, sendCpCompanyAddSaga),
		debounce(400, action.searchCompanyCpCompanyAdd.request, searchCompanyCpCompanyAddSaga),
		debounce(400, action.searchPCompanyCpCompanyAdd.request, searchPCompanyCpCompanyAddSaga)
	]);
}
