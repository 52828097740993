import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/correctionListAction";

const initialState = {
	isLoading: true,
	isNoItems: false,

	scroll: {
		isLoading: false,
		size: 20,
		page: 0,
		totalElements: 0
	},
	search: {
		isLoading: false,
		value: ""
	},
	filter: {
		isLoading: false,
		by: "",
		start: "",
		end: ""
	},
	sort: {
		field: "correctionCreated",
		order: "desc"
	},
	itemsActions: {},

	kkmId: "",
	company: {},
	kkm: {},
	items: []
};
const actionHandlers = {
	// Initialize
	[action.initCorrectionList.request]: createMutateReducer((draft, { kkmId }) => {
		draft.isLoading = true;
		draft.isNoItems = false;

		draft.scroll.isLoading = false;
		draft.scroll.page = 0;
		draft.scroll.totalElements = 0;

		draft.search.isLoading = false;
		draft.search.value = "";

		draft.itemsActions = {};

		draft.filter.isLoading = false;

		draft.kkmId = kkmId;
		draft.company = {};
		draft.items = [];
	}),
	[action.initCorrectionList.success]: createMutateReducer((draft, { company, items, kkm, totalElements }) => {
		draft.isLoading = false;
		draft.isNoItems = !items.length;

		draft.scroll.totalElements = totalElements;

		draft.company = company;
		draft.kkm = kkm;
		draft.items = items;
	}),
	[action.initCorrectionList.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isNoItems = true;
	}),

	// Scroll
	[action.scrollCorrectionList.request]: createMutateReducer(draft => {
		draft.scroll.isLoading = true;
	}),
	[action.scrollCorrectionList.success]: (state, { items, page }) => ({
		...state,
		scroll: {
			...state.scroll,
			isLoading: false,
			page
		},
		items: [...state.items, ...items]
	}),
	[action.scrollCorrectionList.error]: createMutateReducer(draft => {
		draft.scroll.isLoading = false;
	}),

	// Search
	[action.searchCorrectionList.request]: createMutateReducer((draft, { value }) => {
		draft.search.isLoading = true;
		draft.search.value = value;
	}),
	[action.searchCorrectionList.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.scroll.page = 0;
		draft.scroll.totalElements = totalElements;

		draft.search.isLoading = false;

		draft.items = items;
	}),
	[action.searchCorrectionList.error]: createMutateReducer(draft => {
		draft.search.isLoading = false;
	}),

	// Filter
	[action.filterCorrectionList.request]: createMutateReducer((draft, params, state) => {
		draft.filter = {
			isLoading: true,
			...state.filter,
			...params
		};
	}),
	[action.filterCorrectionList.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.scroll.page = 0;
		draft.scroll.totalElements = totalElements;

		draft.filter.isLoading = false;

		draft.items = items;
	}),
	[action.filterCorrectionList.error]: createMutateReducer(draft => {
		draft.filter.isLoading = false;
	}),

	// Sort
	[action.sortCorrectionList.request]: createMutateReducer((draft, { field, order }) => {
		draft.filter.isLoading = true;
		draft.sort = { field, order };
	}),
	[action.sortCorrectionList.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.scroll.page = 0;
		draft.scroll.totalElements = totalElements;

		draft.filter.isLoading = false;

		draft.items = items;
	}),
	[action.sortCorrectionList.error]: createMutateReducer(draft => {
		draft.filter.isLoading = false;
	}),

	// Show detail transaction
	[action.showDetailCorrectionList.request]: createMutateReducer((draft, { requestId }, state) => {
		draft.itemsActions[requestId] = state.itemsActions[requestId] || {};
		draft.itemsActions[requestId].isOpen = !draft.itemsActions[requestId].isOpen;
		draft.itemsActions[requestId].isLoading = true;
		draft.itemsActions[requestId].isError = false;
	}),
	[action.showDetailCorrectionList.success]: createMutateReducer((draft, { requestId, data }, state) => {
		draft.itemsActions[requestId] = state.itemsActions[requestId] || {};
		draft.itemsActions[requestId].isLoading = false;
		draft.itemsActions[requestId].data = data;
	}),
	[action.showDetailCorrectionList.error]: createMutateReducer((draft, { requestId }, state) => {
		draft.itemsActions[requestId] = state.itemsActions[requestId] || {};
		draft.itemsActions[requestId].isLoading = false;
		draft.itemsActions[requestId].isError = true;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
