import { lazy } from "react";

// Reducers...
import kkmListReducer from "./reducers/kkmListReducer";
import kkmSettingsReducer from "./reducers/kkmSettingsReducer";
import transactionListReducer from "./reducers/transactionListReducer";
import correctionListReducer from "./reducers/correctionListReducer";
import transactionAddReducer from "./reducers/transactionAddReducer";

// Sagas...
import kkmListSaga from "./sagas/kkmListSaga";
import kkmSettingsSaga from "./sagas/kkmSettingsSaga";
import transactionListSaga from "./sagas/transactionListSaga";
import correctionListSaga from "./sagas/correctionListSaga";
import transactionAddSaga from "./sagas/transactionAddSaga";

export const getReducers = createReducer => ({
	kkmList: kkmListReducer(createReducer),
	kkmSettings: kkmSettingsReducer(createReducer),

	transactionList: transactionListReducer(createReducer),
	correctionList: correctionListReducer(createReducer),

	transactionAdd: transactionAddReducer(createReducer)
});
export const getSagas = () => [
	kkmListSaga(),
	kkmSettingsSaga(),
	transactionListSaga(),
	correctionListSaga(),
	transactionAddSaga()
];
export const getRoutes = () => ({
	// Список касс
	kkmList: {
		exact: true,
		path: "/kkm",
		component: lazy(() => import("./containers/KkmListContainer"))
	},
	correctionList: {
		exact: true,
		path: "/kkm/:kkmId/correction",
		component: lazy(() => import("./containers/CorrectionListContainer"))
	},
	kkmTransaction: {
		exact: true,
		path: "/kkm/:kkmId",
		component: lazy(() => import("./containers/TransactionListContainer"))
	},
	kkmTransactionAdd: {
		exact: true,
		path: "/kkm/:kkmId/add",
		component: lazy(() => import("./containers/TransactionAddContainer"))
	},
	kkmSettings: {
		exact: true,
		path: "/kkm/:kkmId/settings",
		component: lazy(() => import("./containers/KkmSettingsContainer"))
	}
});
