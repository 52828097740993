import { createSelector } from "reselect";

const kkmListSelector = state => state.kkmList;

export const isLoadingSelector = createSelector(
	kkmListSelector,
	state => state.isLoading
);
export const isNoItemsSelector = createSelector(
	kkmListSelector,
	state => state.isNoItems
);
export const scrollSelector = createSelector(
	kkmListSelector,
	state => state.scroll
);
export const searchSelector = createSelector(
	kkmListSelector,
	state => state.search
);
export const filterSelector = createSelector(
	kkmListSelector,
	state => state.filter
);
export const itemsSelector = createSelector(
	kkmListSelector,
	state => state.items
);
