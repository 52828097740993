import React, { Component } from "react";
import PropTypes from "prop-types";
import { copyToBuffer } from "common/helpers/string";
import reduxForm, { Form, InputField } from "components/form";
import { Button, message } from "components/ui";

@reduxForm({ form: "kkmGroupSetting", enableReinitialize: true })
export default class ApiKeyForm extends Component {
	static propTypes = {
		handleSubmit: PropTypes.func,
		isLoading: PropTypes.bool,
		initialValues: PropTypes.object,
		onSubmitForm: PropTypes.func.isRequired
	};

	handleGenerateApiKey = () => {
		const { initialValues } = this.props;

		if (copyToBuffer(initialValues.apiKey, this.apiKeyBox)) {
			message.success("Скопированно в буфер обмена");
		}
	};

	render() {
		const { handleSubmit, isLoading, onSubmitForm } = this.props;

		return (
			<div className="card">
				<div className="card-body">
					<Form onSubmit={handleSubmit(onSubmitForm)}>
						<div className="justify-content-md-center row">
							<div className="col-xl-8 col-lg-12">
								<InputField
									name="apiKey"
									label="API-ключ группы"
									placeholder="Введите API-ключ группы"
									addonAfter={
										<span
											className="text-primary cursor-pointer"
											onClick={this.handleGenerateApiKey}
										>
											<i className="feather icon-copy cursor-pointer m-r-5" />
											копировать
										</span>
									}
									disabled
								/>
								<div ref={e => (this.apiKeyBox = e)} />

								<div className="p-t-20">
									<Button htmlType="submit" type="primary" className="m-r-15" loading={isLoading}>
										Сгенерировать новый API-ключ
									</Button>
								</div>
							</div>
						</div>
					</Form>
				</div>
			</div>
		);
	}
}
