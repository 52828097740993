export const TYPE = {
	SALE: "SALE",
	SALE_RETURN: "SALE_RETURN",
	BUY: "BUY",
	BUY_RETURN: "BUY_RETURN"
};
export const TYPE_LABEL = {
	[TYPE.SALE]: "Приход",
	[TYPE.SALE_RETURN]: "Возврат прихода",
	[TYPE.BUY]: "Расход",
	[TYPE.BUY_RETURN]: "Возврат расхода"
};

export const SEND_CHECK = {
	NONE: "None",
	EMAIL: "Email",
	PHONE: "Phone"
};

export const TAB_SETTING = {
	SETTINGS: "settings",
	API_KEY: "api_key",
	HANDLER: "handler"
};

export const TAB_SETTING_DEFAULT = TAB_SETTING.API_KEY;

export const TAB_SETTING_VALUE = {
	[TAB_SETTING.SETTINGS]: "Настройки",
	[TAB_SETTING.API_KEY]: "API-Ключ",
	[TAB_SETTING.HANDLER]: "Обработчик"
};
