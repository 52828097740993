import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/settingsAction";

const initialState = {
	isLoading: false
};
const actionHandlers = {
	// Профиль пользователя
	[action.sendProfileSettings.request]: createMutateReducer(draft => {
		draft.isLoading = true;
	}),
	[action.sendProfileSettings.success]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),
	[action.sendProfileSettings.error]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),

	// Сменить пароль
	[action.sendPasswordSettings.request]: createMutateReducer(draft => {
		draft.isLoading = true;
	}),
	[action.sendPasswordSettings.success]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),
	[action.sendPasswordSettings.error]: createMutateReducer(draft => {
		draft.isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
