import "@babel/polyfill";

import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { withStoreProvider, withBrowserRouter } from "./components/baseHocs";
import AppComponent from "./components/AppComponent";
import createApp from "./core/createApp";
import modules from "./modules/mainModule";

import "antd/dist/antd.css";
import "../markup/assets/fonts/feather/icon-font.css";
import "../markup/main.scss";

@withStoreProvider()
@withBrowserRouter()
class Root extends Component {
	static propTypes = {
		routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
	};

	render() {
		const { routes } = this.props;
		return <AppComponent routes={routes} />;
	}
}

const props = createApp(modules);

ReactDOM.render(<Root {...props} />, document.getElementById("root"));
