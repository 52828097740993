import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/registriesPreviewAction";

const initialState = {
	isLoading: true,
	isNoItems: false,
	scroll: {
		isLoading: false,

		size: 20,
		page: 0,
		totalElements: 0
	},
	search: {
		value: "",
		isLoading: false,
		isNoItems: false
	},
	filter: {
		isLoading: false,
		by: "",
		start: "",
		end: ""
	},

	registries: {},
	items: [],
	action: {}
};
const actionHandlers = {
	// Initialize
	[action.initRegistriesPreview.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.isNoItems = false;
		draft.items = [];
		draft.filter.isLoading = false;
		draft.scroll.page = 0;
	}),
	[action.initRegistriesPreview.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.isLoading = false;
		draft.isNoItems = items.length === 0;
		draft.items = items;

		draft.scroll.totalElements = totalElements;
	}),
	[action.initRegistriesPreview.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isNoItems = true;
	}),

	// Infinite scroll
	[action.scrollRegistriesPreview.request]: createMutateReducer(draft => {
		draft.scroll.isLoading = true;
	}),
	[action.scrollRegistriesPreview.success]: createMutateReducer((draft, { items, page }, state) => {
		draft.scroll.isLoading = false;
		draft.scroll.page = page;
		draft.items = [...state.items, ...items];
	}),
	[action.scrollRegistriesPreview.error]: createMutateReducer(draft => {
		draft.scroll.isLoading = false;
	}),

	// Search
	[action.searchRegistriesPreview.request]: createMutateReducer((draft, { value }) => {
		draft.search.isLoading = true;
		draft.search.value = value;

		draft.scroll.page = 0;
	}),
	[action.searchRegistriesPreview.success]: createMutateReducer((draft, { value, items, totalElements }, state) => {
		if (value === state.search.value) {
			draft.search.isLoading = false;
			draft.search.isNoItems = items.length === 0;
			draft.scroll.totalElements = totalElements;
			draft.items = items;
		}
	}),
	[action.searchRegistriesPreview.error]: createMutateReducer(draft => {
		draft.search.isLoading = false;
	}),

	// Filter
	[action.filterRegistriesPreview.request]: createMutateReducer((draft, params, state) => {
		draft.filter = {
			isLoading: true,
			...state.filter,
			...params
		};
	}),
	[action.filterRegistriesPreview.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.scroll.page = 0;
		draft.scroll.totalElements = totalElements;

		draft.filter.isLoading = false;

		draft.items = items;
	}),
	[action.filterRegistriesPreview.error]: createMutateReducer(draft => {
		draft.filter.isLoading = false;
	}),

	[action.showDetailRegistriesPreview.request]: createMutateReducer((draft, { fiscalDocumentId, isOpen }) => {
		draft.action[fiscalDocumentId] = draft.action[fiscalDocumentId] || {};
		draft.action[fiscalDocumentId].isOpen = isOpen;
		draft.action[fiscalDocumentId].isLoading = true;
	}),
	[action.showDetailRegistriesPreview.success]: createMutateReducer((draft, { fiscalDocumentId, data }) => {
		draft.action[fiscalDocumentId] = draft.action[fiscalDocumentId] || {};
		draft.action[fiscalDocumentId].isLoading = false;
		draft.action[fiscalDocumentId].data = data;
	}),
	[action.showDetailRegistriesPreview.error]: createMutateReducer((draft, { fiscalDocumentId }) => {
		draft.action[fiscalDocumentId] = draft.action[fiscalDocumentId] || {};
		draft.action[fiscalDocumentId].isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
