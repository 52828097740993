import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/cpKkmPreviewAction";

const initialState = {
	isLoading: true,
	isLoadingAction: false,
	isError: false,

	kkmInfo: {},

	// Info

	// Transaction
	isNoItems: false,
	scroll: {
		isLoading: false,
		size: 20,
		page: 0,
		totalElements: 0
	},
	search: {
		isLoading: false,
		value: ""
	},
	filter: {
		isLoading: false,
		by: "",
		start: "",
		end: ""
	},
	sort: {
		field: "transactionCreated",
		order: "desc"
	},
	items: [],
	itemsActions: {}
};
const actionHandlers = {
	// Initialize
	[action.initCpKkmPreview.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.isLoadingAction = false;
		draft.isError = false;

		draft.kkmInfo = {};

		draft.isNoItems = false;
		draft.scroll = { ...initialState.scroll };
		draft.search = { ...initialState.search };
		draft.filter = { ...initialState.filter };
		draft.items = [];
	}),
	[action.initCpKkmPreview.success]: createMutateReducer((draft, { kkmInfo, items, totalElements }) => {
		draft.isLoading = false;

		draft.kkmInfo = kkmInfo;

		draft.isNoItems = !items.length;
		draft.scroll.totalElements = totalElements;
		draft.items = items;
	}),
	[action.initCpKkmPreview.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isError = true;
	}),

	// Update setting
	[action.updateCpKkmPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.updateCpKkmPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.updateCpKkmPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Remove kkm
	[action.removeCpKkmPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.removeCpKkmPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.removeCpKkmPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Set kkm mode
	[action.setKkmModeCpKkmPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.setKkmModeCpKkmPreview.success]: createMutateReducer((draft, { state }) => {
		draft.isLoadingAction = false;
		draft.kkmInfo.state = state;
	}),
	[action.setKkmModeCpKkmPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Загрузить данные кассы
	[action.sendDeskDataCpKkmPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.sendDeskDataCpKkmPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.sendDeskDataCpKkmPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Изменить API ключ
	[action.sendApiKeyCpKkmPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.sendApiKeyCpKkmPreview.success]: createMutateReducer((draft, { apiKey }) => {
		draft.isLoadingAction = false;
		draft.kkmInfo.apiKey = apiKey;
	}),
	[action.sendApiKeyCpKkmPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Изменить обработчик
	[action.sendHandlerCpKkmPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.sendHandlerCpKkmPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.sendHandlerCpKkmPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Scroll
	[action.scrollTransactCpKkmPreview.request]: createMutateReducer(draft => {
		draft.scroll.isLoading = true;
	}),
	[action.scrollTransactCpKkmPreview.success]: (state, { items, page }) => ({
		...state,
		scroll: {
			...state.scroll,
			isLoading: false,
			page
		},
		items: [...state.items, ...items]
	}),
	[action.scrollTransactCpKkmPreview.error]: createMutateReducer(draft => {
		draft.scroll.isLoading = false;
	}),

	// Search
	[action.searchTransactCpKkmPreview.request]: createMutateReducer((draft, { value }) => {
		draft.search.isLoading = true;
		draft.search.value = value;
	}),
	[action.searchTransactCpKkmPreview.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.scroll.page = 0;
		draft.scroll.totalElements = totalElements;

		draft.search.isLoading = false;

		draft.items = items;
	}),
	[action.searchTransactCpKkmPreview.error]: createMutateReducer(draft => {
		draft.search.isLoading = false;
	}),

	// Filter
	[action.filterTransactCpKkmPreview.request]: createMutateReducer((draft, params, state) => {
		draft.filter = {
			isLoading: true,
			...state.filter,
			...params
		};
	}),
	[action.filterTransactCpKkmPreview.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.scroll.page = 0;
		draft.scroll.totalElements = totalElements;

		draft.filter.isLoading = false;

		draft.items = items;
	}),
	[action.filterTransactCpKkmPreview.error]: createMutateReducer(draft => {
		draft.filter.isLoading = false;
	}),

	// Sort
	[action.sortTransactCpKkmPreview.request]: createMutateReducer((draft, { field, order }) => {
		draft.filter.isLoading = true;
		draft.sort = { field, order };
	}),
	[action.sortTransactCpKkmPreview.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.scroll.page = 0;
		draft.scroll.totalElements = totalElements;

		draft.filter.isLoading = false;

		draft.items = items;
	}),
	[action.sortTransactCpKkmPreview.error]: createMutateReducer(draft => {
		draft.filter.isLoading = false;
	}),

	// Show detail transaction
	[action.showDetailTransactCpKkmPreview]: createMutateReducer((draft, { requestId }, state) => {
		draft.itemsActions[requestId] = state.itemsActions[requestId] || {};
		draft.itemsActions[requestId].isOpen = !draft.itemsActions[requestId].isOpen;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
