import { all, takeEvery, call, put, delay, select, debounce } from "redux-saga/effects";
import { push } from "connected-react-router";
import { message, notification } from "components/ui";
import { escapeRegExp } from "common/helpers/string";
import { searchParams, sortParams } from "common/helpers/query";
import * as controlDataContext from "common/dataProvider/control";
import * as companyDataContext from "common/dataProvider/company";

import * as action from "../actions/cpKkmPreviewAction";
import * as selector from "../selectors/cpKkmPreviewSelector";

export function* initCpKkmPreviewSaga({ payload }) {
	try {
		const { kkmId } = payload;
		const { size } = yield select(selector.scrollSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = { size, sort: sortParams({ field, order }) };

		const { kkmInfo, transaction } = yield all({
			kkmInfo: call(controlDataContext.getControlKkmById, { kkmId }),
			transaction: call(controlDataContext.deleteControlKkmByIdTransaction, { kkmId, query })
		});

		yield put(
			action.initCpKkmPreview.success({
				kkmInfo,

				items: transaction.content,
				totalElements: transaction.totalElements
			})
		);
	} catch (e) {
		yield put(action.initCpKkmPreview.error());
		notification.httpError();
	}
}

export function* updateCpKkmPreviewSaga({ payload }) {
	const { kkmId, form } = payload;

	try {
		yield call(controlDataContext.putControlKkmById, { kkmId, query: form });
		yield put(action.updateCpKkmPreview.success());

		message.success("Касса успешно обновлена");
	} catch (e) {
		yield put(action.updateCpKkmPreview.error());

		if (e?.response?.status === 400) {
			notification.error("Ошибка", `Касса с указанным хостом и портом уже существует`);
		} else {
			notification.httpError();
		}
	}
}

export function* removeCpKkmPreviewSaga({ payload }) {
	try {
		const { kkmId } = payload;

		yield call(controlDataContext.deleteControlKkmById, { kkmId });
		yield put(action.removeCpKkmPreview.success());
		yield put(push("/control-panel/kkm"));

		message.success("Касса успешно удалена");
	} catch (e) {
		yield put(action.removeCpKkmPreview.error());
		notification.httpError();
	}
}

export function* setKkmModeCpKkmPreviewSaga({ payload }) {
	try {
		const { kkmId, state } = payload;
		const query = { newMode: state };

		yield call(controlDataContext.postControlKkmByIdMode, { kkmId, query });
		yield put(action.setKkmModeCpKkmPreview.success({ state }));

		message.success("Режим работы кассы успешно изменен");
	} catch (e) {
		yield put(action.setKkmModeCpKkmPreview.error());
		notification.httpError();
	}
}

export function* sendDeskDataCpKkmPreviewSaga({ payload }) {
	try {
		const { kkmId, form } = payload;

		yield delay(600);
		throw new Error(`${kkmId}_${form}`);

		// yield put(action.sendDeskDataCpKkmPreview.success());
	} catch (e) {
		yield put(action.sendDeskDataCpKkmPreview.error());
		notification.httpError();
	}
}

export function* sendApiKeyCpKkmPreviewSaga({ payload }) {
	try {
		const { kkmId, companyId } = payload;
		const { apiKey } = yield call(companyDataContext.postCompanyByIdKkmByIdApiKey, { companyId, kkmId });

		yield put(action.sendApiKeyCpKkmPreview.success({ apiKey }));
	} catch (e) {
		yield put(action.sendApiKeyCpKkmPreview.error());
		notification.httpError();
	}
}

export function* sendHandlerCpKkmPreviewSaga({ payload }) {
	try {
		const { kkmId, form } = payload;

		yield delay(600);
		throw new Error(`${kkmId}_${form}`);

		// yield put(action.sendHandlerCpKkmPreview.success());
	} catch (e) {
		yield put(action.sendHandlerCpKkmPreview.error());
		notification.httpError();
	}
}

export function* scrollTransactCpKkmPreviewSaga({ payload }) {
	try {
		const { kkmId } = payload;
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			page: page + 1,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const response = yield call(controlDataContext.deleteControlKkmByIdTransaction, { kkmId, query });

		yield put(
			action.scrollTransactCpKkmPreview.success({
				items: response.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollTransactCpKkmPreview.error());
		notification.httpError();
	}
}

export function* filterTransactCpKkmPreviewSaga({ payload }) {
	try {
		const { kkmId } = payload;
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const response = yield call(controlDataContext.deleteControlKkmByIdTransaction, { kkmId, query });

		yield put(
			action.filterTransactCpKkmPreview.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.filterTransactCpKkmPreview.error());
		notification.httpError();
	}
}

export function* sortTransactCpKkmPreviewSaga({ payload }) {
	try {
		const { kkmId } = payload;
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const { by, start, end } = yield select(selector.filterSelector);
		const { field, order } = yield select(selector.sortSelector);

		const query = {
			size,
			q: searchParams({ quickSearch: value, by, start, end }),
			sort: sortParams({ field, order })
		};

		const response = yield call(controlDataContext.deleteControlKkmByIdTransaction, { kkmId, query });

		yield put(
			action.sortTransactCpKkmPreview.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.sortTransactCpKkmPreview.error());
		notification.httpError();
	}
}

export function* searchTransactCpKkmPreviewSaga({ payload }) {
	try {
		const { kkmId, value } = payload;
		const { size } = yield select(selector.scrollSelector);

		const query = {
			size,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined
		};

		const response = yield call(controlDataContext.deleteControlKkmByIdTransaction, { kkmId, query });

		yield put(
			action.searchTransactCpKkmPreview.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchTransactCpKkmPreview.error());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpKkmPreview.request, initCpKkmPreviewSaga),

		takeEvery(action.updateCpKkmPreview.request, updateCpKkmPreviewSaga),
		takeEvery(action.removeCpKkmPreview.request, removeCpKkmPreviewSaga),

		takeEvery(action.setKkmModeCpKkmPreview.request, setKkmModeCpKkmPreviewSaga),
		takeEvery(action.sendDeskDataCpKkmPreview.request, sendDeskDataCpKkmPreviewSaga),
		takeEvery(action.sendApiKeyCpKkmPreview.request, sendApiKeyCpKkmPreviewSaga),
		takeEvery(action.sendHandlerCpKkmPreview.request, sendHandlerCpKkmPreviewSaga),

		// transaction
		takeEvery(action.scrollTransactCpKkmPreview.request, scrollTransactCpKkmPreviewSaga),
		takeEvery(action.filterTransactCpKkmPreview.request, filterTransactCpKkmPreviewSaga),
		takeEvery(action.sortTransactCpKkmPreview.request, sortTransactCpKkmPreviewSaga),
		debounce(400, action.searchTransactCpKkmPreview.request, searchTransactCpKkmPreviewSaga)
	]);
}
