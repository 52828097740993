import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/kkmGroupAddAction";

const initialState = {
	isLoading: true,
	isLoadingSend: false,
	formState: {}
};
const actionHandlers = {
	// Initialize
	[action.initKkmGroupAdd.request]: createMutateReducer(draft => {
		draft.isLoading = true;
	}),
	[action.initKkmGroupAdd.success]: createMutateReducer((draft, { formState }) => {
		draft.isLoading = false;
		draft.formState = formState;
	}),
	[action.initKkmGroupAdd.error]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),

	// Send form
	[action.sendKkmGroupAdd.request]: createMutateReducer(draft => {
		draft.isLoadingSend = true;
	}),
	[action.sendKkmGroupAdd.success]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),
	[action.sendKkmGroupAdd.error]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
