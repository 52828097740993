import { lazy } from "react";

// Reducers...
import ckOffersReducer from "./reducers/cpOffersReducer";

// Sagas...
import cpOffersSaga from "./sagas/cpOffersSaga";

export const getReducers = createReducer => ({
	offers: ckOffersReducer(createReducer)
});
export const getSagas = () => [cpOffersSaga()];
export const getRoutes = () => ({
	offers: {
		exact: true,
		path: `/control-panel/offers`,
		component: lazy(() => import("./containers/CpOffersContainer"))
	}
});
