import { createSelector } from "reselect";

const registriesPreviewSelector = state => state.registriesPreview;

export const isLoadingSelector = createSelector(
	registriesPreviewSelector,
	state => state.isLoading
);
export const isNoItemsSelector = createSelector(
	registriesPreviewSelector,
	state => state.isNoItems
);
export const scrollSelector = createSelector(
	registriesPreviewSelector,
	state => state.scroll
);
export const searchSelector = createSelector(
	registriesPreviewSelector,
	state => state.search
);
export const filterSelector = createSelector(
	registriesPreviewSelector,
	state => state.filter
);
export const registriesSelector = createSelector(
	registriesPreviewSelector,
	state => state.registries
);
export const itemsSelector = createSelector(
	registriesPreviewSelector,
	state => state.items
);
export const actionSelector = createSelector(
	registriesPreviewSelector,
	state => state.action
);
