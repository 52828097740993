import { all, takeEvery, call, put, select, debounce } from "redux-saga/effects";
import { message } from "components/ui";
import * as controlDataContext from "common/dataProvider/control";

import * as action from "../actions/cpCompanyListAction";
import * as selector from "../selectors/cpCompanyListSelector";
import { escapeRegExp } from "../../../../common/helpers/string";

export function* initCpCompanyListSaga() {
	try {
		const { size } = yield select(selector.scrollSelector);
		const query = { size };
		const response = yield call(controlDataContext.getControlCompany, { query });

		yield put(
			action.initCpCompanyList.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.initCpCompanyList.error());
		message.error("Не удалось загрузить список компаний");
	}
}

export function* scrollCpCompanyListSaga() {
	try {
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const query = { size, page: page + 1, q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined };
		const response = yield call(controlDataContext.getControlCompany, { query });

		yield put(
			action.scrollCpCompanyList.success({
				items: response.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollCpCompanyList.error());
	}
}

export function* searchCpCompanyListSaga({ payload }) {
	try {
		const { size } = yield select(selector.scrollSelector);
		const { value } = payload;
		const query = { size, q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined };

		const response = yield call(controlDataContext.getControlCompany, { query });

		yield put(
			action.searchCpCompanyList.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchCpCompanyList.error());
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpCompanyList.request, initCpCompanyListSaga),
		takeEvery(action.scrollCpCompanyList.request, scrollCpCompanyListSaga),
		debounce(400, action.searchCpCompanyList.request, searchCpCompanyListSaga)
	]);
}
