import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/kkmListAction";

const initialState = {
	isLoading: true,
	isNoItems: false,
	scroll: {
		isLoading: false,

		size: 20,
		page: 0,
		totalElements: 0
	},
	search: {
		value: "",
		isLoading: false
	},
	filter: {
		isLoading: false,
		activeKkm: true
	},
	items: []
};
const actionHandlers = {
	// Initialize
	[action.initKkmList.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.isNoItems = false;
		draft.items = [];

		draft.scroll.page = 0;
	}),
	[action.initKkmList.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.isLoading = false;
		draft.isNoItems = items.length === 0;
		draft.items = items;

		draft.scroll.totalElements = totalElements;
	}),
	[action.initKkmList.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isNoItems = true;
	}),

	// Infinite scroll
	[action.scrollKkmList.request]: createMutateReducer(draft => {
		draft.scroll.isLoading = true;
	}),
	[action.scrollKkmList.success]: createMutateReducer((draft, { items, page }, state) => {
		draft.scroll.isLoading = false;
		draft.scroll.page = page;
		draft.items = [...state.items, ...items];
	}),
	[action.scrollKkmList.error]: createMutateReducer(draft => {
		draft.scroll.isLoading = false;
	}),

	// Search
	[action.searchKkmList.request]: createMutateReducer((draft, { value }) => {
		draft.search.isLoading = true;
		draft.search.value = value;

		draft.scroll.page = 0;
	}),
	[action.searchKkmList.success]: createMutateReducer((draft, { value, items, totalElements }, state) => {
		if (value === state.search.value) {
			draft.search.isLoading = false;
			draft.scroll.totalElements = totalElements;
			draft.items = items;
		}
	}),
	[action.searchKkmList.error]: createMutateReducer(draft => {
		draft.search.isLoading = false;
	}),

	// Filter
	[action.filterKkmList.request]: createMutateReducer((draft, params, state) => {
		draft.filter = {
			...state.filter,
			...params,
			isLoading: true
		};
	}),
	[action.filterKkmList.success]: createMutateReducer((draft, { items, totalElements }) => {
		draft.scroll.page = 0;
		draft.scroll.totalElements = totalElements;

		draft.filter.isLoading = false;

		draft.items = items;
	}),
	[action.filterKkmList.error]: createMutateReducer(draft => {
		draft.filter.isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
