import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { WrapperLayer, NoItems, Button, TableListComponent } from "components/ui";

import KkmGroupItemComponent from "../components/KkmGroupItemComponent";
import * as action from "../actions/kkmGroupListAction";
import * as selector from "../selectors/kkmGroupListSelector";

@withRouter
@connect(
	state => ({
		isLoading: selector.isLoadingSelector(state),
		isNoItems: selector.isNoItemsSelector(state),
		scroll: selector.scrollSelector(state),
		items: selector.itemsSelector(state)
	}),
	{
		initKkmGroupList: action.initKkmGroupList.request,
		scrollKkmGroupList: action.scrollKkmGroupList.request
	}
)
export default class KkmGroupListContainer extends Component {
	static propTypes = {
		isLoading: PropTypes.bool,
		isNoItems: PropTypes.bool,
		scroll: PropTypes.shape({
			isLoading: PropTypes.bool,
			totalElements: PropTypes.number
		}),
		items: PropTypes.array,
		initKkmGroupList: PropTypes.func,
		scrollKkmGroupList: PropTypes.func,

		history: PropTypes.shape({
			push: PropTypes.func.isRequired
		})
	};

	constructor(props) {
		super(props);
		props.initKkmGroupList();
	}

	get headerTable() {
		return [
			{ key: "name", name: "Наименование" },
			{ key: "transactionsTotal", name: "Всего операций", style: { width: "120px" } },
			{ key: "transactionsToday", name: "Сегодня операций", style: { width: "120px" } },
			{ key: "action", className: "wid-70", style: { width: "70px" } }
		];
	}

	handleScroll = () => {
		const { scroll, items, scrollKkmGroupList } = this.props;

		if (scroll.totalElements > items.length) {
			scrollKkmGroupList();
		}
	};

	handleClickAdd = () => {
		const { history } = this.props;
		history.push(`/kkm-group/add`);
	};

	handleClickItem = item => {
		this.handleClickMenuTransactionList(item);
	};

	handleClickMenuTransactionList = item => {
		const { history } = this.props;
		history.push(`/kkm-group/${item.id}`);
	};

	handleClickMenuSettings = item => {
		const { history } = this.props;
		history.push(`/kkm-group/${item.id}/settings`);
	};

	render() {
		const { isLoading, isNoItems, scroll, items } = this.props;

		return (
			<WrapperLayer
				title="Группы касс"
				breadcrumb={[{ to: "/kkm-group", label: "Группы касс" }]}
				isLoading={isLoading}
				titleRight={
					<Button size="small" onClick={this.handleClickAdd}>
						<i className="feather icon-plus p-r-10" />
						Создать группу
					</Button>
				}
			>
				<NoItems isShow={isNoItems} description="У вас ещё нет ни одной группы" />

				<TableListComponent
					isShow={!isNoItems}
					isLoading={scroll.isLoading}
					header={this.headerTable}
					onScroll={this.handleScroll}
				>
					{items.map(item => (
						<KkmGroupItemComponent
							key={item.id}
							item={item}
							onClick={this.handleClickItem}
							onClickMenuTransactionList={this.handleClickMenuTransactionList}
							onClickMenuSettings={this.handleClickMenuSettings}
						/>
					))}
				</TableListComponent>
			</WrapperLayer>
		);
	}
}
