import api from "common/api/index";
import * as mapper from "./mapper";

// POST /api
export const postApi = ({ header, query }) => {
	return api
		.api()
		.post(mapper.transactionAPI.toServer(query), header)
		.then(res => res.data);
};

// POST /api/kkm-group
export const postApiKkmGroup = ({ header, data }) => {
	return api
		.api()
		.api()
		.kkmGroup()
		.post(data, header)
		.then(res => res.data);
};
