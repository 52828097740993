import { all, takeEvery, call, put, debounce } from "redux-saga/effects";
import { uniqueElement } from "common/helpers/array";
import * as controlDataContext from "common/dataProvider/control";
import * as action from "../actions/companySelectAction";
import { escapeRegExp } from "../../../../common/helpers/string";

function* __companyOwner({ search } = {}) {
	const query = {
		size: 20,
		q: search ? `quickSearch=='${escapeRegExp(search)}'` : undefined
		// "top-only": type === "owner"
	};
	const response = yield call(controlDataContext.getControlCompany, { query });

	return (response.content || []).map(item => formatItemCompany(item));
}

function* __companyChildren({ companyId, search } = {}) {
	const query = { size: 20, q: search ? `quickSearch=='${escapeRegExp(search)}'` : undefined };
	const response = yield call(controlDataContext.getControlCompanyChildCompany, { companyId, query });

	return (response || []).map(item => formatItemCompany(item));
}

function* __companyDetail({ companyId }) {
	try {
		const item = yield call(controlDataContext.getControlCompanyById, { companyId });
		return formatItemCompany(item);
	} catch (e) {
		return {};
	}
}

function formatItemCompany(item) {
	return { value: item.id, label: item.name };
}

export function* initCpCompanySelectSaga({ payload }) {
	const { form, name, type, companyId, defaultValue } = payload;

	try {
		if (type === "children" && !companyId) {
			yield put(action.initCpCompanySelect.success({ form, name, options: [] }));
			return null;
		}

		const methodQuery = ["all", "owner"].includes(type) ? __companyOwner : __companyChildren;
		let options = [];

		if (defaultValue) {
			const { one, two } = yield all({
				one: call(methodQuery, { type, companyId }),
				two: call(__companyDetail, { companyId: defaultValue })
			});

			options = uniqueElement([...one, two], "value");
		} else {
			options = yield call(methodQuery, { companyId });
		}

		yield put(action.initCpCompanySelect.success({ form, name, options }));
	} catch (e) {
		yield put(action.initCpCompanySelect.error({ form, name }));
	}
}

export function* searchCpCompanySelectSaga({ payload }) {
	const { form, name, type, companyId, value } = payload;

	try {
		const methodQuery = type === "owner" ? __companyOwner : __companyChildren;
		const options = yield call(methodQuery, { companyId, search: value, size: 20 });

		yield put(action.searchCpCompanySelect.success({ form, name, options }));
	} catch (e) {
		yield put(action.searchCpCompanySelect.error({ form, name }));
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpCompanySelect.request, initCpCompanySelectSaga),
		debounce(400, action.searchCpCompanySelect.request, searchCpCompanySelectSaga)
	]);
}
