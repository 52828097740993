import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ItemComponent extends Component {
	static propTypes = {
		item: PropTypes.shape({
			name: PropTypes.string,
			manager: PropTypes.object,
			phone: PropTypes.string,
			email: PropTypes.string
		})
	};
	render() {
		const { item } = this.props;

		return (
			<tr>
				<td className="font-weight-bold">{item.name}</td>
				<td>
					{item?.manager?.name && <div>{item.manager.name}</div>}
					{item?.manager?.post && <div>{item.manager.post}</div>}
					{item?.manager?.phone && <div>+7 {item.manager.phone}</div>}
				</td>
				<td>+7 {item.phone}</td>
				<td>{item.email}</td>
			</tr>
		);
	}
}
