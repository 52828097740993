import { all, takeEvery, call, put, select } from "redux-saga/effects";
import { message } from "components/ui";
import * as companyDataContext from "common/dataProvider/company";
import { companyIdSelector } from "modules/account/selectors/accountSelector";

import * as action from "../actions/usersListAction";

export function* initUsersListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const query = {
			size: 100
		};

		const response = yield call(companyDataContext.getCompanyUser, { companyId, query });
		yield put(action.initUsersList.success(response));
	} catch (e) {
		yield put(action.initUsersList.error());
	}
}

export function* updateAccessUserListSaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);
		const { id: userId, key, value, allAccess } = payload;
		const query = { ...allAccess, [key]: value };

		yield call(companyDataContext.putCompanyByIdUserByIdAccessRights, {
			companyId,
			userId,
			query
		});

		yield put(action.updateAccessUserList.success(payload));
		message.success("Права доступа успешно изменены");
	} catch (e) {
		yield put(action.updateAccessUserList.error(payload));
		message.error("Права доступа не были изменены");
	}
}

export function* deleteUserUserListSaga({ payload }) {
	try {
		const { id: userId } = payload;
		const companyId = yield select(companyIdSelector);

		yield call(companyDataContext.deleteCompanyByIdUserById, { companyId, userId });
		yield put(action.deleteUserUserList.success(payload));
		message.success("Пользователь успешно удален");

		yield call(initUsersListSaga);
	} catch (e) {
		yield put(action.deleteUserUserList.error(payload));
		message.error("Пользователь не был удален");
	}
}

export default function*() {
	yield all([
		takeEvery(action.initUsersList.request, initUsersListSaga),
		takeEvery(action.updateAccessUserList.request, updateAccessUserListSaga),
		takeEvery(action.deleteUserUserList.request, deleteUserUserListSaga)
	]);
}
