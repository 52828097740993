import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { AmountFormat } from "components/ui";
import { STATUS, STATUS_LABEL } from "common/enums/kkm";
import { getDateFormat } from "../../../../common/helpers/string";

export default class ItemComponent extends Component {
	static propTypes = {
		item: PropTypes.object
	};

	handleStopEvent = e => {
		e?.stopPropagation();
	};

	renderStatus = status => {
		switch (status) {
			case STATUS.QUEUED:
				return <span className="badge badge-secondary">{STATUS_LABEL.QUEUED}</span>;
			case STATUS.PENDING:
				return <span className="badge badge-warning">{STATUS_LABEL.PENDING}</span>;
			case STATUS.COMPLETED:
				return <span className="badge badge-success">{STATUS_LABEL.COMPLETED}</span>;
			case STATUS.FAILED:
				return <span className="badge badge-danger">{STATUS_LABEL.FAILED}</span>;
			default:
				return <span>&#8212;</span>;
		}
	};

	render() {
		const { item } = this.props;

		return (
			<tr>
				<td className="font-weight-bold">
					<a href={`/registries/${item.id}`} target="_blank">
						{item.fileName}
					</a>
				</td>
				<td>{item.inn}</td>
				<td>{getDateFormat(item.fileDt, true)}</td>
				<td>{this.renderStatus(item.currentStatus)}</td>
				<td>
					{item.kkm?.id ? (
						<Link to={`/kkm/${item.kkm.id}`} onClick={this.handleStopEvent}>
							{item.kkm.name}
						</Link>
					) : (
						<span className="f-12">не указан</span>
					)}
				</td>
				<td className="text-center">
					<AmountFormat value={item.transactionCount} precision={0} currency={null} />
				</td>
				<td className="text-center">
					<AmountFormat value={item.transactionQueued} precision={0} currency={null} />
				</td>
				<td>{getDateFormat(item.uploadedAt, true)}</td>
			</tr>
		);
	}
}
