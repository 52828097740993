import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/kkmGroupSettingsAction";

const initialState = {
	isLoading: true,
	isLoadingSend: false,
	formState: null,
	groupState: null,

	searchKkm: {
		isLoading: false,
		value: "",
		items: []
	}
};
const actionHandlers = {
	// Initialize
	[action.initKkmGroupSettings.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.isLoadingSend = false;
		draft.formState = null;
		draft.groupState = null;
		draft.searchKkm.items = [];
	}),
	[action.initKkmGroupSettings.success]: createMutateReducer((draft, { formState, groupState, itemsKkm }) => {
		draft.isLoading = false;
		draft.formState = formState;
		draft.groupState = groupState;
		draft.searchKkm.items = itemsKkm;
	}),
	[action.initKkmGroupSettings.error]: createMutateReducer(draft => {
		draft.isLoading = false;
	}),

	// Send form
	[action.sendKkmGroupSettings.request]: createMutateReducer(draft => {
		draft.isLoadingSend = true;
	}),
	[action.sendKkmGroupSettings.success]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),
	[action.sendKkmGroupSettings.error]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),

	// Generate API key
	[action.generateApiKey.request]: createMutateReducer(draft => {
		draft.isLoadingSend = true;
	}),
	[action.generateApiKey.success]: createMutateReducer((draft, { apiKey }) => {
		draft.isLoadingSend = false;
		draft.formState.apiKey = apiKey;
	}),
	[action.generateApiKey.error]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),

	// Remove group
	[action.removeKkmGroupSettings.request]: createMutateReducer(draft => {
		draft.isLoadingSend = true;
	}),
	[action.removeKkmGroupSettings.success]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),
	[action.removeKkmGroupSettings.error]: createMutateReducer(draft => {
		draft.isLoadingSend = false;
	}),

	[action.searchKkmByIdGroupSettings.request]: createMutateReducer((draft, { value }) => {
		draft.searchKkm.isLoading = true;
		draft.searchKkm.value = value;
	}),
	[action.searchKkmByIdGroupSettings.success]: createMutateReducer((draft, { items }) => {
		draft.searchKkm.isLoading = false;
		draft.searchKkm.items = items;
	}),
	[action.searchKkmByIdGroupSettings.error]: createMutateReducer(draft => {
		draft.searchKkm.isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
