import { createSelector } from "reselect";

const registriesListSelector = state => state.registriesList;

export const isLoadingSelector = createSelector(
	registriesListSelector,
	state => state.isLoading
);
export const isNoItemsSelector = createSelector(
	registriesListSelector,
	state => state.isNoItems
);
export const isLoadingUploadSelector = createSelector(
	registriesListSelector,
	state => state.isLoadingUpload
);
export const searchSelector = createSelector(
	registriesListSelector,
	state => state.search
);
export const scrollSelector = createSelector(
	registriesListSelector,
	state => state.scroll
);
export const itemsSelector = createSelector(
	registriesListSelector,
	state => state.items
);
