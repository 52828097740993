import React, { Component } from "react";
import PropTypes from "prop-types";
import Upload from "antd/lib/upload";
import { message, notification } from "components/ui";

export default class ImportComponent extends Component {
	static propTypes = {
		isShow: PropTypes.bool,
		companyId: PropTypes.string,
		onUpdate: PropTypes.func
	};

	handleChange = ({ file }) => {
		const { onUpdate } = this.props;

		if (file?.status === "error") {
			if (file?.response?.status === 400) {
				message.error(file.response?.message);
			} else {
				notification.httpError();
			}
		}

		if (file?.status === "done" && file?.response) {
			if (file.response?.errors?.length > 0) {
				const error = file.response.errors.reduce((accum, current) => {
					accum.push(
						<tr key={current.lineNo} className="err_line">
							<td className="err_line_number">{current.lineNo}</td>
							<td>{current.message}</td>
						</tr>
					);
					return accum;
				}, []);

				notification.error(
					"Ошибки в файле:",
					<table>
						<tbody>{error}</tbody>
					</table>,
					{
						className: "register_import_error"
					}
				);
			}

			if (file.response.transactionCount) {
				message.success(`Добавлено ${file.response.transactionCount} транзакц.`);
				onUpdate({ inBackground: true });
			}
		}
	};

	render() {
		const { isShow, companyId } = this.props;

		if (!isShow) {
			return null;
		}

		return (
			<div className="row">
				<div className="col-12">
					<Upload
						className="file_upload"
						openFileDialogOnClick
						action={`/site/company/${companyId}/registry/import`}
						onChange={this.handleChange}
					>
						<div className="ant-upload-text file_upload_btn cursor-pointer">
							<i className="feather icon-upload" /> Загрузить реестры
						</div>
					</Upload>
				</div>
			</div>
		);
	}
}
