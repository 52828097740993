import { createSelector } from "reselect";

const smartSelector = state => state.smart;

export const userAutoCompleteSelector = createSelector(
	smartSelector,
	state => state.userAutoComplete
);
export const companyAutoCompleteSelector = createSelector(
	smartSelector,
	state => state.companyAutoComplete
);
export const companySelectSelector = createSelector(
	smartSelector,
	state => state.companySelect
);
