import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/cpCompanyPreviewAction";

const initialState = {
	isLoading: true,
	isError: false,
	isLoadingAction: false,

	company: {},

	userState: {
		isLoading: false,
		items: []
	},
	kkmState: {
		isLoading: false,
		items: []
	},

	searchOwner: {
		isLoading: false,
		options: [],
		defaultOption: {}
	},
	searchCompany: {
		isLoading: false,
		options: [],
		defaultOption: {}
	}
};
const actionHandlers = {
	// Initialize company preview
	[action.initCpCompanyPreview.request]: createMutateReducer(draft => {
		draft.isLoading = true;
		draft.isError = false;
		draft.company = {};

		draft.userState.items = [];
		draft.kkmState.items = [];
		draft.searchOwner.defaultOption = {};
	}),
	[action.initCpCompanyPreview.success]: createMutateReducer((draft, { company, user, kkm, owner, parent }) => {
		draft.isLoading = false;
		draft.company = company;

		draft.userState.items = user;
		draft.kkmState.items = kkm;
		draft.searchOwner.defaultOption = owner;
		draft.searchCompany.defaultOption = parent;
	}),
	[action.initCpCompanyPreview.error]: createMutateReducer(draft => {
		draft.isLoading = false;
		draft.isError = true;
	}),

	// Update info company
	[action.updateCpCompanyPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.updateCpCompanyPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.updateCpCompanyPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Update owner company
	[action.updateOwnerCpCompanyPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.updateOwnerCpCompanyPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.updateOwnerCpCompanyPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Remove company
	[action.removeCpCompanyPreview.request]: createMutateReducer(draft => {
		draft.isLoadingAction = true;
	}),
	[action.removeCpCompanyPreview.success]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),
	[action.removeCpCompanyPreview.error]: createMutateReducer(draft => {
		draft.isLoadingAction = false;
	}),

	// Search owner company
	[action.searchOwnerCpCompanyPreview.request]: createMutateReducer(draft => {
		draft.searchOwner.isLoading = true;
	}),
	[action.searchOwnerCpCompanyPreview.success]: createMutateReducer((draft, { content }) => {
		draft.searchOwner.isLoading = false;
		draft.searchOwner.options = content;
	}),
	[action.searchOwnerCpCompanyPreview.error]: createMutateReducer(draft => {
		draft.searchOwner.isLoading = false;
	}),

	// Search parent company
	[action.searchCompanyCpCompanyPreview.request]: createMutateReducer(draft => {
		draft.searchCompany.isLoading = true;
	}),
	[action.searchCompanyCpCompanyPreview.success]: createMutateReducer((draft, { content }) => {
		draft.searchCompany.isLoading = false;
		draft.searchCompany.options = content;
	}),
	[action.searchCompanyCpCompanyPreview.error]: createMutateReducer(draft => {
		draft.searchCompany.isLoading = false;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
