import { createSelector } from "reselect";

const usersListSelector = state => state.usersList;

export const isLoadingSelector = createSelector(
	usersListSelector,
	state => state.isLoading
);
export const itemsSelector = createSelector(
	usersListSelector,
	state => state.items
);
