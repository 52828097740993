import { createRequestActions } from "common/helpers/action";

export const initCpUsersPreview = createRequestActions("CP.USERS.PREVIEW.INIT");
export const updateCpUsersPreview = createRequestActions("CP.USERS.PREVIEW.UPDATE");
export const updPassCpUsersPreview = createRequestActions("CP.USERS.PREVIEW.UPDATE_PASSWORD");
export const removeCpUsersPreview = createRequestActions("CP.USERS.PREVIEW.REMOVE");

export const searchCompanyCpUsersPreview = createRequestActions("CP.USERS.PREVIEW.SEARCH_COMPANY");
export const loadCompanyCpUsersPreview = createRequestActions("CP.USERS.PREVIEW.LOAD_COMPANY");
export const addCompanyCpUsersPreview = createRequestActions("CP.USERS.PREVIEW.ADD_COMPANY");
export const removeCompanyCpUsersPreview = createRequestActions("CP.USERS.PREVIEW.REMOVE_COMPANY");
