import { all, takeEvery, call, put, select, debounce } from "redux-saga/effects";
import { notification } from "components/ui";
import { companyIdSelector } from "modules/account/selectors/accountSelector";
import * as companyDataContext from "common/dataProvider/company";

import * as action from "../actions/registriesListAction";
import * as selector from "../selectors/registriesListSelector";
import { escapeRegExp } from "../../../common/helpers/string";

export function* initRegistriesListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const query = { size };

		const response = yield call(companyDataContext.getCompanyByIdRegistry, { companyId, query });

		yield put(
			action.initRegistriesList.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.initRegistriesList.error());
		notification.httpError();
	}
}

export function* scrollRegistriesListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const query = {
			size,
			page: page + 1,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined
		};

		const response = yield call(companyDataContext.getCompanyByIdRegistry, { companyId, query });

		yield put(
			action.scrollRegistriesList.success({
				items: response.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollRegistriesList.error());
		notification.httpError();
	}
}

export function* searchRegistriesListSaga() {
	try {
		const companyId = yield select(companyIdSelector);
		const { size } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const query = {
			size,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined
		};

		const response = yield call(companyDataContext.getCompanyByIdRegistry, { companyId, query });

		yield put(
			action.searchRegistriesList.success({
				value,
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchRegistriesList.error());
		notification.httpError();
	}
}

export default function*() {
	yield all([
		takeEvery(action.initRegistriesList.request, initRegistriesListSaga),
		takeEvery(action.scrollRegistriesList.request, scrollRegistriesListSaga),
		debounce(400, action.searchRegistriesList.request, searchRegistriesListSaga)
	]);
}
