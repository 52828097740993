import { all, takeEvery, put, select, call, debounce } from "redux-saga/effects";
import { push } from "connected-react-router";
import { message, notification } from "components/ui";
import { escapeRegExp } from "common/helpers/string";
import * as companyDataContext from "common/dataProvider/company";

import { companyIdSelector } from "../../account/selectors/accountSelector";
import * as action from "../actions/kkmGroupSettingsAction";

export function* initKkmGroupSettingsSaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);
		const { kkmGroupId } = payload;

		const { kkm, groupState } = yield all({
			kkm: call(companyDataContext.getCompanyKkm, { companyId, query: { size: 10 } }),
			groupState: call(companyDataContext.getCompanyKkmGroupId, { companyId, kkmGroupId })
		});

		const formState = {
			name: groupState?.name,
			apiKey: groupState?.apiKey,
			itemsKkm: kkm.content
		};

		yield put(action.initKkmGroupSettings.success({ groupState, formState, itemsKkm: kkm.content }));
	} catch (e) {
		yield put(action.initKkmGroupSettings.error());
		notification.httpError();
	}
}

export function* sendKkmGroupSettingsSaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);
		const { kkmGroupId } = payload;

		yield call(companyDataContext.putCompanyKkmGroupId, { companyId, kkmGroupId });

		message.success("Группа успешно обновлена");
		yield put(action.sendKkmGroupSettings.success());
		yield put(push(`/kkm-group`));
	} catch (e) {
		yield put(action.sendKkmGroupSettings.error());
		message.error("При изменении группы произошла ошибка, попробуйде еще раз.");
	}
}

export function* generateApiKeySaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);
		const { kkmGroupId, query } = payload;

		const { apiKey } = yield call(companyDataContext.postCompanyKkmGroupIdApiKey, { companyId, kkmGroupId, query });

		message.success("Группа успешно обновлена");
		yield put(action.generateApiKey.success({ apiKey }));
	} catch (e) {
		yield put(action.generateApiKey.error());
		message.error("При изменении группы произошла ошибка, попробуйде еще раз.");
	}
}

export function* removeKkmGroupSettingsSaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);
		const { kkmGroupId } = payload;

		yield call(companyDataContext.deleteCompanyKkmGroupId, { companyId, kkmGroupId });

		message.success("Группа успешно удалена");
		yield put(action.removeKkmGroupSettings.error());
		yield put(push(`/kkm-group`));
	} catch (e) {
		yield put(action.removeKkmGroupSettings.error());
		message.error("При удалении группы произошла ошибка, попробуйде еще раз.");
	}
}

export function* addKkmByIdGroupSettingsSaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);
		const { kkmId, kkmGroupId } = payload;
		const query = { kkmId, kkmGroupId };

		yield call(companyDataContext.postCompanyByIdLinkKkmGroup, { companyId, query });
		yield call(initKkmGroupSettingsSaga, { payload: { kkmGroupId } });

		yield put(action.addKkmByIdGroupSettings.success());

		message.success("Касса успешно добавлена в группу");
	} catch (e) {
		yield put(action.addKkmByIdGroupSettings.error());
		notification.httpError();
	}
}

export function* removeKkmByIdGroupSettingsSaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);
		const { kkmId, kkmGroupId } = payload;

		yield call(companyDataContext.deleteCompanyByIdKkmByIdGroup, { companyId, kkmId });
		yield call(initKkmGroupSettingsSaga, { payload: { kkmGroupId } });

		yield put(action.removeKkmByIdGroupSettings.success());

		message.success("Касса успешно удалена из группы");
	} catch (e) {
		yield put(action.removeKkmByIdGroupSettings.error());
		notification.httpError();
	}
}

export function* searchKkmByIdGroupSettingsSaga({ payload }) {
	try {
		const companyId = yield select(companyIdSelector);
		const { value } = payload;
		const query = {
			size: 10,
			q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined
		};

		const response = yield call(companyDataContext.getCompanyKkm, { companyId, query });

		yield put(
			action.searchKkmByIdGroupSettings.success({
				items: response.content
			})
		);
	} catch (e) {
		yield put(action.searchKkmByIdGroupSettings.error());
	}
}

export default function*() {
	yield all([
		takeEvery(action.initKkmGroupSettings.request, initKkmGroupSettingsSaga),
		takeEvery(action.sendKkmGroupSettings.request, sendKkmGroupSettingsSaga),
		takeEvery(action.generateApiKey.request, generateApiKeySaga),
		takeEvery(action.removeKkmGroupSettings.request, removeKkmGroupSettingsSaga),

		takeEvery(action.addKkmByIdGroupSettings.request, addKkmByIdGroupSettingsSaga),
		takeEvery(action.removeKkmByIdGroupSettings.request, removeKkmByIdGroupSettingsSaga),
		debounce(400, action.searchKkmByIdGroupSettings.request, searchKkmByIdGroupSettingsSaga)
	]);
}
