import { all, takeEvery, call, put, select, debounce } from "redux-saga/effects";
import { message } from "components/ui";
import * as controlDataContext from "common/dataProvider/control";

import * as action from "../actions/cpUsersListAction";
import * as selector from "../selectors/cpUsersListSelector";
import { escapeRegExp } from "../../../../common/helpers/string";

export function* initCpUsersListSaga() {
	try {
		const { size } = yield select(selector.scrollSelector);
		const query = { size };
		const response = yield call(controlDataContext.getControlUser, { query });

		yield put(
			action.initCpUsersList.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.initCpUsersList.error());
		message.error("Не удалось загрузить список пользователей");
	}
}

export function* scrollCpUsersListSaga() {
	try {
		const { size, page } = yield select(selector.scrollSelector);
		const { value } = yield select(selector.searchSelector);
		const query = { size, page: page + 1, q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined };
		const response = yield call(controlDataContext.getControlUser, { query });

		yield put(
			action.scrollCpUsersList.success({
				items: response.content,
				page: query.page
			})
		);
	} catch (e) {
		yield put(action.scrollCpUsersList.error());
	}
}

export function* searchCpUsersListSaga({ payload }) {
	try {
		const { value } = payload;
		const { size } = yield select(selector.scrollSelector);
		const query = { size, q: value ? `quickSearch=='${escapeRegExp(value)}'` : undefined };

		const response = yield call(controlDataContext.getControlUser, { query });

		yield put(
			action.searchCpUsersList.success({
				items: response.content,
				totalElements: response.totalElements
			})
		);
	} catch (e) {
		yield put(action.searchCpUsersList.error());
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpUsersList.request, initCpUsersListSaga),
		takeEvery(action.scrollCpUsersList.request, scrollCpUsersListSaga),

		debounce(300, action.searchCpUsersList.request, searchCpUsersListSaga)
	]);
}
