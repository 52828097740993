import { all, takeEvery, put, call } from "redux-saga/effects";
import { notification } from "components/ui";
import * as controlDataContext from "common/dataProvider/control";

import * as action from "../actions/cpOffersAction";

function* __loadOffers() {
	try {
		return yield call(controlDataContext.getControlOffer);
	} catch (e) {
		notification.error("Ошибка", "Не удалось загрузить список документов");
		return [];
	}
}

function* __loadUserType() {
	try {
		return yield call(controlDataContext.getControlUserType);
	} catch (e) {
		notification.error("Ошибка", "Не удалось загрузить список типов");
		return [];
	}
}

function* initCpOffersSaga() {
	try {
		const { docs, userTypes } = yield all({
			docs: call(__loadOffers),
			userTypes: call(__loadUserType)
		});

		yield put(action.initCpOffers.success({ docs, userTypes }));
	} catch (e) {
		yield put(action.initCpOffers.error());
	}
}

function* deleteCpUserTypeSaga({ payload: { id } }) {
	try {
		yield call(controlDataContext.deleteControlUserType, { id });
		const userTypes = yield call(__loadUserType);

		yield put(action.editCpUserType.success({ userTypes }));
	} catch (e) {
		notification.httpError();
		yield put(action.editCpUserType.error());
	}
}

function* editCpUserTypeSaga({ payload: { form } }) {
	try {
		yield call(controlDataContext.postControlUserType, { form });
		const userTypes = yield call(__loadUserType);

		yield put(action.editCpUserType.success({ userTypes }));
	} catch (e) {
		notification.httpError();
		yield put(action.editCpUserType.error());
	}
}

function* editCpDocSaga() {
	try {
		const docs = yield call(__loadOffers);

		yield put(action.editCpDoc.success({ docs }));
	} catch (e) {
		yield put(action.editCpDoc.error());
	}
}

function* deleteCpDocSaga({ payload: { userType } }) {
	try {
		yield call(controlDataContext.deleteControlOfferUserType, { userType });
		const docs = yield call(__loadOffers);

		yield put(action.editCpDoc.success({ docs }));
	} catch (e) {
		yield put(action.editCpDoc.error());
	}
}

export default function*() {
	yield all([
		takeEvery(action.initCpOffers.request, initCpOffersSaga),

		takeEvery(action.editCpUserType.request, editCpUserTypeSaga),
		takeEvery(action.deleteCpUserType.request, deleteCpUserTypeSaga),

		takeEvery(action.editCpDoc.request, editCpDocSaga),
		takeEvery(action.deleteCpDoc.request, deleteCpDocSaga)
	]);
}
