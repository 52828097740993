import { all, takeEvery, call, put } from "redux-saga/effects";
import { setAppReady } from "modules/app/actions/appAction";
import { redirect } from "common/helpers/location";
import * as companyDataContext from "common/dataProvider/company";
import * as loginDataContext from "common/dataProvider/login";
import { SIGN_IN_PAGE } from "common/enums/app";
import * as action from "../actions/accountAction";

export function* setAppReadyAccountSaga() {
	// const { company } = payload;
	// yield put(action.setUserCompany({ company }));
}

export function* changeCompanySaga({ payload }) {
	try {
		const { companyId } = payload;

		yield call(companyDataContext.putUser, { companyId });
		yield put(action.changeCompany.success({ companyId }));
	} catch (e) {
		yield put(action.changeCompany.error());
	}
}

export function* logoutSaga() {
	try {
		yield call(loginDataContext.postLogout);
		yield put(action.logout.success());

		redirect(SIGN_IN_PAGE);
	} catch (e) {
		yield put(action.logout.error());
	}
}

export default function*() {
	yield all([
		takeEvery(setAppReady, setAppReadyAccountSaga),
		takeEvery(action.changeCompany.request, changeCompanySaga),
		takeEvery(action.logout.request, logoutSaga)
	]);
}
