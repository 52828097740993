import { createMutateReducer } from "common/helpers/reducer";
import * as action from "../actions/companyListAction";

const initialState = {
	isLoading: false,
	map: {},
	items: []
};
const actionHandlers = {
	[action.setChildCompany]: createMutateReducer((draft, { map, items }) => {
		draft.map = map;
		draft.items = items;
	})
};

export { initialState, actionHandlers };
export default createReducer => createReducer(initialState, actionHandlers);
