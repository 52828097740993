import { createRequestActions } from "common/helpers/action";

export const initKkmGroupSettings = createRequestActions("KKM_GROUP.SETTINGS.INIT");
export const sendKkmGroupSettings = createRequestActions("KKM_GROUP.SETTINGS.SEND");
export const generateApiKey = createRequestActions("KKM_GROUP.SETTINGS.GENERATE_API_KEY");
export const removeKkmGroupSettings = createRequestActions("KKM_GROUP.SETTINGS.REMOVE");

export const addKkmByIdGroupSettings = createRequestActions("KKM_GROUP.SETTINGS.ADD_KKM_BY_ID");
export const removeKkmByIdGroupSettings = createRequestActions("KKM_GROUP.SETTINGS.REMOVE_KKM_BY_ID");
export const searchKkmByIdGroupSettings = createRequestActions("KKM_GROUP.SETTINGS.SEARCH_KKM_BY_ID");
