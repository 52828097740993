import React, { Component } from "react";
import PropTypes from "prop-types";
import reduxForm, { Form, InputField } from "components/form";
import { Button } from "components/ui";
import { validateRequired } from "common/validators";

@reduxForm({ form: "settingsPassword" })
export default class SettingPasswordForm extends Component {
	static propTypes = {
		handleSubmit: PropTypes.func,
		onSubmit: PropTypes.func,
		isLoading: PropTypes.bool
	};

	render() {
		const { handleSubmit, onSubmit, isLoading } = this.props;

		return (
			<div className="card">
				<div className="card-body">
					<Form onSubmit={handleSubmit(onSubmit)}>
						<div className="justify-content-md-center row">
							<div className="col-md-9 col-sm-12">
								<InputField
									name="password"
									password
									label="Текущий пароль"
									validate={[validateRequired]}
								/>
								<InputField name="passwordNew" label="Новый пароль" validate={[validateRequired]} />
								<InputField
									name="passwordRepeat"
									label="Повторите новый пароль"
									validate={[validateRequired]}
								/>

								<Button htmlType="submit" type="primary" loading={isLoading}>
									Сохранить изменения
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</div>
		);
	}
}
